import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../_config';
import { CallApi } from './api/callApi';

export function* getEventList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.GET_EVENT_LIST +`${data?.guest_user_id? `?guest_user_id=${data?.guest_user_id}` : ""}`);
        if (resp.status >= 200 && resp.status < 300 && resp?.data && resp?.data.data) {
            yield put({
                type: ACTIONS.EVENT.GET_EVENT_LIST,
                payload: resp.data.data?.list,
                gameId: resp.data.data? resp?.data?.data?.game_details?.id : ''
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getEventGameList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.GET_EVENT_GAME+`/${data?.rodeo_event_id}?game_id=${data?.game_id}${data?.guest_user_id? `&guest_user_id=${data?.guest_user_id}` : ""}`);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.GET_EVENT_GAME,
                payload: resp.data.data.event_dtls
            })
            yield put({
                type: ACTIONS.EVENT.RODEO_EVENT_SETTINGS,
                payload: resp.data.data.rodeo_event_settings
            })
            yield put({
                type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
                payload: resp.data.data.status
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getEventGameDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.GET_EVENT_GAME_DETAILS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.GET_EVENT_GAME_DETAILS,
                payload: resp.data.data
            })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getUpcomingEventList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.GET_UPCOMING_EVENT+`/${data?.rodeo_event_id}/${data?.performance_id}${data?.guest_user_id? `?guest_user_id=${data?.guest_user_id}` : ""}`);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.SET_EARNED_POINTS,
                payload: resp.data.data.earn_points
            })
            yield put({
                type: ACTIONS.EVENT.SET_PERFORMANCE_INFO,
                payload: resp.data.data.performance_info,
            })
            yield put({
                type: ACTIONS.EVENT.SET_PERFORMANCE_EVENTS_LIST,
                payload: resp.data.data.list,
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getPlayersList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.PLAYERS_LIST+`/${data?.rodeo_event_id}/${data?.performance_id}/${data?.inner_event_id}${data?.guest_user_id? `?guest_user_id=${data?.guest_user_id}` : ""}`);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.PLAYERS_LIST,
                payload: resp.data.data.list,
                total_data: resp.data.data
            })
            yield put({
                type: ACTIONS.EVENT.LAST_BETTED_PLAYERS_LIST,
                payload: resp.data.data.list,
                total_data: resp.data.data
            })
            yield put({
                type: ACTIONS.EVENT.SET_AVAILABLE_POINTS,
                payload: resp.data.data.available_poins.point,
            })
            yield put({
                type: ACTIONS.EVENT.SET_PERFORMANCE_INFO,
                payload: resp.data.data.performance_info,
            })
            yield put({
                type: ACTIONS.EVENT.SET_EVENT_DETAILS,
                payload: resp.data.data.rodeo_event_event_dtls,
            })
            yield put({
                type: ACTIONS.EVENT.SET_EARNED_POINTS,
                payload: resp.data.data.earn_points,
            })
            
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getPlayersDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.PLAYERS_DETAILS+`/${data?.player_id? data?.player_id: ''}${data?.guest_user_id? `?guest_user_id=${data?.guest_user_id}` : ""}`);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.LOADER.SET_FP_STATUS,
                payload: true
            })
            yield put({
                type: ACTIONS.EVENT.PLAYERS_DETAILS,
                payload: resp.data.data,
            })
            yield put({
                type: ACTIONS.EVENT.SET_EARNED_POINTS,
                payload: resp.data.data.earn_points,
            })
            yield put({
                type: ACTIONS.LOADER.SET_FP_STATUS,
                payload: false
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* joinEventGame(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.JOIN_EVENT_GAME, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* pickRider(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.PICK_RIDER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* viewLeaderBoard(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.LEADERBOARD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.LEADERBOARD,
                payload: resp.data.data
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* tvImageDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.TV_IMAGE.DETAILS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.TV_IMAGE.DETAILS,
                payload: resp.data.data
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* bettingPointsSubmit(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.BETTING_POINT_SUBMIT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.SET_BET_ID,
                payload: resp.data.data.user_wager.id
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* saveBet(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.SAVE_BET, data, true);
       // console.log(data)
        if (resp.status >= 200 && resp.status < 300 && resp?.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e?.data ? e?.data : e);
    }
}

export function* getPrizeDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.PRIZE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* resetBet(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.RESET_BET, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getRodeoEventGeoLocations(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.GET_RODEO_EVENT_GEO_LOCATIONS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            
            yield put({
                type: ACTIONS.EVENT.GET_RODEO_EVENT_GEO_LOCATIONS,
                payload: resp.data.data
            })
            
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getLeaderboardLocationList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.LEADERBOARD_LOCATION_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            
            // yield put({
            //     type: ACTIONS.EVENT.GET_RODEO_EVENT_GEO_LOCATIONS,
            //     payload: resp.data.data
            // })
            
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getGameStatusText(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.GET_GAME_STATUS_TEXT+(data?.game_id ? "/"+data?.game_id : ""));
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            console.log({resp})
            yield put({
                type: ACTIONS.EVENT.GET_IR_STATUS_MESSAGE,
                payload: resp.data.data
            })            
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getGameDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.GET_GAME_DETAILS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.GET_GAME_DETAILS,
                payload: resp.data.data
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e:any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}