import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormTextInput from 'src/_common/components/form-elements/textinput/formTextInput';
import { useUserApi } from 'src/_common/hooks/actions/user/appUserApiHook';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import ReactFlagsSelect from 'react-flags-select';
import FormMaskedInput from 'src/_common/components/form-elements/maskedInput/maskedInput';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';

interface NotifyMeModalProps {
    onClose: Function;
    shouldShow: boolean;
}

interface NotifyMeModalValues {
    first_name: string;
    last_name: string;
    email: string;
    mobileNumber: string;
}

const NotifyMeModalSchema = yup.object().shape({
    first_name: yup
        .string()
        .required('First Name is required'),
    last_name: yup
        .string()
        .required('Last Name is required'),
    email: yup
        .string()
        .email('Please enter a valid email')
        .required('Email is required'),
    mobileNumber: yup
        .string()
        .required('Mobile Number is required'),    
})


export default function NotifyMeModal({ onClose, shouldShow }: NotifyMeModalProps) {

    const { control, errors, setValue, handleSubmit, } = useForm<NotifyMeModalValues>({
        resolver: yupResolver(NotifyMeModalSchema),
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            mobileNumber: ''
        },
    })

    const userApi = useUserApi()
    const userAction = useAppUserAction()
    const toast = useToaster()
    const [countryCode, setCountryCode] = useState<string>("US");
    const [phonePrefix, setPhonePrefix] = useState<number>(1);

    // Set prefix code & country code into the State defined earlier
    const onSelectFlag = (cty: any) => {
      let phonePrefix = 1
      if (cty === 'US' || cty === 'CA')
        phonePrefix = 1
      else if (cty === 'AU')
        phonePrefix = 61
      setPhonePrefix(phonePrefix)
      setCountryCode(cty)
    }

    const onSubmit = (values: NotifyMeModalValues) => {   
        const mobileNoDigit = values.mobileNumber.replace(/\D/g, '').slice(-10);

        var parms = {
            phone: parseInt(mobileNoDigit),
            first_name: values?.first_name,
            last_name: values?.last_name,
            email: values?.email,
            // mobileNumber: values?.mobileNumber,
        }
        console.log({values,parms})
        userApi.callSaveNotification(
            parms,
            (message:any, resp:any) => {
                // console.log({resp})
                onClose()
                toast.success(message)
            },            
            (message:any) => {
                toast.error(message)
            }            
        )
    }

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='custom-modal'
            >
                <Modal.Header>
                    <h2 style={{color:"white"}}>Notify Me</h2>
                </Modal.Header>
                <Modal.Body bsPrefix={'main-container'}>
                    <div className="modal-body">
                        {/* <h4 className="rider-name-content">Please enter your display name</h4> */}
                        <div className="mb-3">
                            <label className="form-label">First Name *</label>
                            <Controller
                                control={control}
                                name="first_name"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.first_name}
                                        placeholder="Enter First Name"
                                    />
                                )}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Last Name *</label>
                            <Controller
                                control={control}
                                name="last_name"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.last_name}
                                        placeholder="Enter Last Name"
                                    />
                                )}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Email *</label>
                            <Controller
                                control={control}
                                name="email"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.email}
                                        placeholder="Enter Email"
                                    />
                                )}
                            />
                        </div>
                        {/* <div className="mb-3">
                            <label className="form-label">Phone *</label>
                            <Controller
                                control={control}
                                name="phone"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.phone}
                                        placeholder="Enter Phone"
                                    />
                                )}
                            />
                        </div> */}
                        <div className="mb-3">
                            <label className="form-label">Mobile Number *</label>
                            <div className="input-wrap-mobile">
                                <ReactFlagsSelect
                                selected={countryCode}
                                // countries={["US", "AU", "CA"]}
                                countries={["US", "AU", "CA"]}
                                customLabels={{ "US": "USA (+1)", "AU": "Australia (+61)", "CA": "Canada (+1)" }}
                                // customLabels={{ "US": "+1", "AU": "+61", "CA": "+1" }}
                                showSelectedLabel={false}
                                className="menu-flags"
                                onSelect={onSelectFlag} />

                                <Controller
                                control={control}
                                name="mobileNumber"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormMaskedInput
                                    name={name}
                                    mask={"+" + phonePrefix + "(999) 999 9999"}
                                    maskChar=" "
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="tel"
                                    // error={errorsMobileNo.mobileNumber}
                                    placeholder="Enter Mobile Number"
                                    />
                                )}
                                />
                            </div>
                            <div className="mobile-number-error-message">
                                {
                                errors && errors.mobileNumber && errors.mobileNumber.message ? <>
                                    <Form.Control.Feedback type="invalid" >
                                    {
                                        errors.mobileNumber.message
                                    }
                                    </Form.Control.Feedback>
                                </> : null
                                }
                            </div>
                            </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-wrap mb-3">
                        <button type="button"
                            className="rkmd-btn btn-lg btn-red ripple-effect w-100 mt-3"
                            onClick={() => onClose()}>
                            Close
                        </button>
                    </div>
                    <div className="btn-wrap mb-3">
                        <button type="button"
                            className="rkmd-btn btn-lg btn-black ripple-effect w-100 mt-3"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Submit
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )

}