import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PlayersPage from 'src/components/events/players/players';
import PlayersPage1 from 'src/components/events/players/players copy';
import PlayerVsBullPage from 'src/components/events/players/playerVsBull';
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { useAppEventDetailsSelector, useAppPerformanceEventsListSelector } from 'src/_common/hooks/selectors/eventSelector';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { RODEO_PAGE_NAME, APPLICATION_NAME, CRYPTO_SECRET_KEY, APP_USER_ID, GUEST_USER_ID } from 'src/_config';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function OverUnderEventLeaderBoardContainer() {

    const urlParams = useParams<any>()
    const userDetails:any = useAppUserDetailsSelector()
    const eventApi = useEventApi()
    const guest_user_id = localStorage.getItem(GUEST_USER_ID)
    // const eventDetails = useAppEventDetailsSelector()
    const perfEventsList = useAppPerformanceEventsListSelector()
    const { eventId } = useParams<any>()
    const [currentEvent, setCurrentEvent] = useState<any>()

    const getInnerEventList = () => {

      const params: any = {
        rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId),
        performance_id: cryptr.decrypt(urlParams.performanceId),
        inner_event_id: cryptr.decrypt(urlParams.eventId),
      };
  
      if(userDetails && !guest_user_id){
        localStorage.setItem(APP_USER_ID, userDetails?.id)
      }
  
      if (guest_user_id && !userDetails) {
        params["guest_user_id"] = guest_user_id;
      }
     // console.log(params)
      eventApi.callGetPlayersList(
        params,
        (message: string, resp: any) => {
            console.log({resp})
        },
        (message: string) => {
          // toast.error(message);
        }
      );
    };
  
    useEffect(() => {
      if(urlParams) {
        // getInnerEventList();
        // let event_id = cryptr.decrypt(urlParams.eventId)
      }
    }, [urlParams]);
  
    useEffect(() => {
      let eId = cryptr.decrypt(eventId)
      let event = perfEventsList?.find((e:any)=>e.id==eId)
      setCurrentEvent(event)
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : Results`
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>
            {/* <Sidebar page={RODEO_PAGE_NAME.LEADERBOARD} /> */}
            <section className="main-container">
            <div className="event-sec">
                <div className="container">
                {currentEvent?.pbr_enable==0 ? <PlayersPage /> : <PlayerVsBullPage />}
                {/* <PlayersPage1 /> */}
                </div>
            </div>
            </section>
        </React.Fragment>
    )
}
