export const CRYPTO_SECRET_KEY = 'rOdEo_ApP'

export const DOB_MONTHS = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
]

export const YES_NO_RADIO = [{
  label: 'No',
  value: 'no'
}, {
  label: 'Yes',
  value: 'yes'
}]

export const GENDER_RADIO = [{
  label: 'Male',
  value: 'male'
}, {
  label: 'Female',
  value: 'female'
}, {
  label: 'Other',
  value: 'other'
}]

//This varibale is using based on navigation bar is marque tag is show/hide
export const RODEO_PAGE_NAME = {
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  EVENT_LIST: 'event_list',
  RIDER_LIST: 'rider_list',
  EVENT_GAME_LIST: 'event_game_list',
  EVENT_GAME_DETAILS: 'event_game_details',
  MY_ACCOUNT: 'my_account',
  ABOUT_US: 'about_us',
  LEGAL: 'legal',
  LEADERBOARD: 'leaderboard',
  HOW_TO_PLAY: 'how-to-play',
  TERM_OF_USE: 'term-of-use',
  NOT_FOUND_PAGE: 'not-found',
  RODEOS: 'rodeos',
  PERFORMANCE: 'performance',
  CONTESTANT_DETAILS: 'contestant-details',
  CONTESTANT_LIST: 'contestant-list',
  PRIZES_LIST: 'prizes-list',
  PRIZE: 'prize',
  CMS: 'cms',
}


export const PROFILE_IMAGE_ASPECT_RATIO = 1

export const SOCKET_CHANNEL = {
  START_EVENT: 'ouStartEvent',
  STATUS_MESSAGE: 'rpStatusMsg',
  VOTE_RIDER: 'rpUserPick',
  WINING_SOCKET: 'rodeoRaceWinning',
  TV_BOARD_IMAGE: 'ouTvBoardEmit',
  OU_ADS_PROMOTION: 'adsPromotionOverUnder',
  OU_BET_ENABLE: 'ouBetEnable',
  OU_EVENT_DETAILS: 'ouEventsDetails',
  OU_RESET_CONTESTANT: 'ouResetContestant',
  OU_EMIT_SCORE: 'ouEmitScore',
  OU_STATUS_MSG: 'ouStatusMsg',
  IR_STATUS_MSG: 'irStatusMsg',
  APP_REDIRECT: 'appRedirect',
  LEADERBOARD_PUBLISHED: 'ouLeaderboardPublished',
  COUPON: 'couponInsideRodeo',
}

export const EVENT_STATUS = {
  NOT_STARTED: 0,
  ONGOING: 1,
  END: 2
}

export const CUSTOM_MESSAGE = {
  SEND_OTP: '6 digit OTP has been sent successfully',
  RESENT_OTP: 'OTP Resend successfully.'
}
