import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
// import ReactPlayer from 'react-player'

interface VideoModalProps {
    onClose: Function;
    shouldShow: boolean;
    url: any;
    type: any;
}


export default function VideoModal({ onClose, shouldShow, type, url }: VideoModalProps) {

    const [time,setTime] = useState<any>(5)

    // useEffect(()=>{
    //     if(shouldShow) {
    //         let x:any = document.getElementById('promoVid')
    //         if(x) {
    //             x.play()
    //         }
    //     }
    // },[shouldShow])

    useEffect(()=>{
        if(type=="image") {
            setTimeout(()=>onClose(),5000)
        }
    },[type])

    useEffect(()=>{
        let id=setInterval(()=>{
            setTime(time-1)
        },1000)

        return ()=>{
            clearInterval(id)
        }
    },[time])

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='custom-modal'
            >
                <Modal.Body bsPrefix={'main-container'}>
                    <div className="modal-body">
                        {type=="image" && <h2 style={{color:"#FFF"}}>Promo image closes in {time} seconds...</h2>}
                        {/* <img src={video} style={{width:"100%"}}/> */}
                        {/* <ReactPlayer
                            url={video}
                            width='100%'
                            height='100%'
                            controls={false}
                            playing={true}
                            onEnded={()=>console.log("finished")}
                            canPlay={true}
                        /> */}
                        {type=="video" ?
                            <video
                                id="promoVid"
                                src={url}
                                preload="auto"
                                autoPlay={true}
                                muted={true}
                                width='100%'
                                height='100%'
                                onEnded={()=>onClose()}
                            >
                            </video>
                            : 
                            <img src={url} style={{width:"100%"}}/>
                        }
                    </div>
                </Modal.Body>
                {type=="image" && <Modal.Footer>
                    <div className="btn-wrap mb-3 ">
                        <button type="button"
                            className="rkmd-btn btn-lg btn-black ripple-effect w-100 mt-3"
                            onClick={()=>onClose()}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>}
            </Modal>
        </React.Fragment>
    )

}