import { takeLatest, all } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../_config';

import {
  checkValidToken,
  createUpdateUserGame,
  forgotPassword,
  getUserProfile,
  loginUser,
  loginUserWithEmail,
  loginUserWithOtp,
  logoutUser,
  registration,
  resetPassword,
  saveNotification,
  updateDisplayName,
  updateUserProfile,
} from './userSaga';

import {

} from './commonSaga';

import {
  getEventList,
  getEventGameList,
  getEventGameDetails,
  getUpcomingEventList,
  pickRider,
  joinEventGame,
  viewLeaderBoard,
  tvImageDetails,
  getPlayersList,
  bettingPointsSubmit,
  getPlayersDetails,
  saveBet,
  getPrizeDetails,
  resetBet,
  getRodeoEventGeoLocations,
  getLeaderboardLocationList,
  getGameStatusText,
  getGameDetails
} from './eventSaga';

import {
  getCmsContent
} from './cmsSaga';


export function* sagas() {
  yield all([
    // user saga
    takeLatest(SAGA_ACTIONS.USER.DETAILS, getUserProfile),
    takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
    takeLatest(SAGA_ACTIONS.USER.LOGIN_WITH_OTP, loginUserWithOtp),
    takeLatest(SAGA_ACTIONS.USER.LOGIN_WITH_EMAIL, loginUserWithEmail),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateUserProfile),
    takeLatest(SAGA_ACTIONS.USER.REGISTRATION, registration),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, resetPassword),

    takeLatest(SAGA_ACTIONS.USER.LOGOUT, logoutUser),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_DISPLAY_NAME, updateDisplayName),
    takeLatest(SAGA_ACTIONS.USER.CHECK_VALID_TOKEN, checkValidToken),
    takeLatest(SAGA_ACTIONS.USER.CREATE_UPDATE_USER_GAME, createUpdateUserGame),
    takeLatest(SAGA_ACTIONS.USER.SAVE_NOTIFICATION, saveNotification),


    //Event Saga
    takeLatest(SAGA_ACTIONS.EVENT.GET_GAME_DETAILS,getGameDetails),
    takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_LIST, getEventList),
    takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_GAME, getEventGameList),
    takeLatest(SAGA_ACTIONS.EVENT.GET_EVENT_GAME_DETAILS, getEventGameDetails),
    takeLatest(SAGA_ACTIONS.EVENT.PICK_RIDER, pickRider),
    takeLatest(SAGA_ACTIONS.EVENT.LEADERBOARD, viewLeaderBoard),
    takeLatest(SAGA_ACTIONS.EVENT.GET_UPCOMING_EVENT, getUpcomingEventList),
    takeLatest(SAGA_ACTIONS.EVENT.JOIN_EVENT_GAME, joinEventGame),
    takeLatest(SAGA_ACTIONS.TV_IMAGE.DETAILS, tvImageDetails),
    takeLatest(SAGA_ACTIONS.EVENT.PLAYERS_LIST, getPlayersList),
    takeLatest(SAGA_ACTIONS.EVENT.BETTING_POINT_SUBMIT, bettingPointsSubmit),
    takeLatest(SAGA_ACTIONS.EVENT.PLAYERS_DETAILS, getPlayersDetails),
    takeLatest(SAGA_ACTIONS.EVENT.SAVE_BET, saveBet),
    takeLatest(SAGA_ACTIONS.EVENT.PRIZE_DETAILS, getPrizeDetails),
    takeLatest(SAGA_ACTIONS.EVENT.RESET_BET, resetBet),
    takeLatest(SAGA_ACTIONS.EVENT.GET_RODEO_EVENT_GEO_LOCATIONS, getRodeoEventGeoLocations),
    takeLatest(SAGA_ACTIONS.EVENT.LEADERBOARD_LOCATION_LIST, getLeaderboardLocationList),
    takeLatest(SAGA_ACTIONS.EVENT.GET_GAME_STATUS_TEXT, getGameStatusText),

    //CMS Saga
    takeLatest(SAGA_ACTIONS.CMS.GET_CMS, getCmsContent),




  ]);
}