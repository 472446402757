import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { useAppTvImageSelector } from "src/_common/hooks/selectors/eventSelector";
import { removeEmptyObjectKey, removeTrailingZero, SOCKET_CHANNEL, SOCKET_URL } from "src/_config";
import useSocket from 'use-socket.io-client';

export function RodeoTvImageComponent() {

    const eventApi = useEventApi()
    const tvImageSelector = useAppTvImageSelector()
    const { gameId } = useParams<any>();
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(false)
    const [recallApi, setRecallApi] = useState<boolean>(false)
    const [isShowingAdsPromotion, setIsShowingAdsPromotion] = useState<boolean>(false)
    const [addPromotionImage, setAddPromotionImage] = useState<any>(null)
    const [addPromotionText, setAddPromotionText] = useState<any>(null)
    const [tvData, setTvData] = useState<any>(null)
    const [previousApiParams, setPreviousApiParams] = useState<any>(null)
    const [defaultAdsPromotionTime, setDefaultAdsPromotionTime] = useState<number>(1000)


    const getTvImageDetails = (screen1: string, type: any) => {
        const params = {
            screen1, type
        }
        setPreviousApiParams(params)
        eventApi.callTvImageDetails(removeEmptyObjectKey(params),
            (message: string, resp: any) => {
            }, (message: string) => {
                console.error(`Error at API Access => ${message}`)
            })
    }

    const resetCssStyle = (type: any) => {
        var element = document.getElementById('rodeo_body');
        if (element) {
            if(["leaderboard","ad"].includes(type)) {
                element.style.padding = '0 30px'
                element.style.paddingTop = '2.5em'
            }
            else {
                element.style.padding = '0px'
            }
        }
    }

    //Socket Implementation start here
    const [socket] = useSocket(SOCKET_URL, {
        autoConnect: false,
    });

    useEffect(() => {
        socket.connect()
        socket.on(SOCKET_CHANNEL.TV_BOARD_IMAGE, (tvImageData: any) => {
            console.log(tvImageData)
            setTvData(tvImageData)
            if (tvImageData.type == "RodeoEvent") {
                getTvImageDetails(tvImageData.rodeo_event_id, "N")
                resetCssStyle("leaderboard")
                setIsShowingAdsPromotion(false)
                setIsInitialLoad(false)
            } else if (tvImageData.type == "Performance") {
                getTvImageDetails(`${tvImageData.rodeo_event_id}||${tvImageData.performance_id}`, "N")
                // resetCssStyle()
                resetCssStyle("leaderboard")
                setIsShowingAdsPromotion(false)
                setIsInitialLoad(false)
            } else if (tvImageData.type == "Event") {
                getTvImageDetails(`${tvImageData.rodeo_event_id}||${tvImageData.performance_id}||${tvImageData.event_id}`, "N")
                resetCssStyle("leaderboard")
                // resetCssStyle()
                setIsShowingAdsPromotion(false)
                setIsInitialLoad(false)
            } else if (tvImageData.type == "Score") {
                // getTvImageDetails(`${tvImageData.rodeo_event_id}||${tvImageData.performance_id}||${tvImageData.event_id}`, "N")
                setRecallApi(true)
                resetCssStyle("leaderboard")
                // resetCssStyle()
                setIsShowingAdsPromotion(false)
                setIsInitialLoad(false)
            } else {
                resetCssStyle("landing")
                getTvImageDetails(gameId, null)
                setIsInitialLoad(true)
            }
        });

        socket.on(SOCKET_CHANNEL.OU_ADS_PROMOTION, (adsPromotionData: any) => {
            console.log({adsPromotionData})
            if(adsPromotionData.text) {
                resetCssStyle("ad")
            } else {
                resetCssStyle("landing")
            }
            setIsInitialLoad(false)

            setIsShowingAdsPromotion(true)
            setAddPromotionImage(adsPromotionData.image)
            setAddPromotionText(adsPromotionData.text)
            setDefaultAdsPromotionTime(adsPromotionData.default_timeout)
        });

        socket.on(SOCKET_CHANNEL.LEADERBOARD_PUBLISHED, (data: any) => {
            console.log({data})
            setRecallApi(true)
        });

        return () => {
            socket.disconnect()
        }
    }, [])

    //Reset Reducer data after component unmount
    useEffect(() => {
        return () => {
        }
    }, [])
    
    useEffect(() => {
        // if(tvData && recallApi && previousApiParams) {
        if(recallApi && previousApiParams) {
            console.log({previousApiParams})
            // if (tvData.type == "RodeoEvent") {
            //     getTvImageDetails(tvData.rodeo_event_id, "N")
            //     resetCssStyle("leaderboard")
            //     setIsShowingAdsPromotion(false)
            //     setIsInitialLoad(false)
            // } else if (tvData.type == "Performance") {
            //     getTvImageDetails(`${tvData.rodeo_event_id}||${tvData.performance_id}`, "N")
            //     // resetCssStyle()
            //     resetCssStyle("leaderboard")
            //     setIsShowingAdsPromotion(false)
            //     setIsInitialLoad(false)
            // } else if (tvData.type == "Event") {
            //     getTvImageDetails(`${tvData.rodeo_event_id}||${tvData.performance_id}||${tvData.event_id}`, "N")
            //     resetCssStyle("leaderboard")
            //     // resetCssStyle()
            //     setIsShowingAdsPromotion(false)
            //     setIsInitialLoad(false)
            // } else if (tvData.type == "Score") {
            //     // getTvImageDetails(`${tvData.rodeo_event_id}||${tvData.performance_id}||${tvData.event_id}`, "N")
            //     resetCssStyle("leaderboard")
            //     setIsShowingAdsPromotion(false)
            //     setIsInitialLoad(false)
            // } else {
            //     resetCssStyle("landing")
            //     getTvImageDetails(gameId, null)
            //     setIsInitialLoad(true)
            // }
            getTvImageDetails(previousApiParams.screen1, previousApiParams.type)
            resetCssStyle("leaderboard")
            setIsShowingAdsPromotion(false)
            setIsInitialLoad(false)
            setRecallApi(false)
        }
    }, [recallApi, previousApiParams])

    useEffect(() => {
        getTvImageDetails(gameId, null)
        setIsInitialLoad(true)
        var element = document.getElementById('rodeo_body');
        if (element) {
            element.style.paddingTop = '0px'
            element.style.marginBottom = '0px'
            element.style.backgroundColor = '#fff'
            element.style.backgroundImage = 'none'
        }
    }, [])

    useEffect(()=> {
       // console.log(tvImageSelector)
    },[tvImageSelector])

    return (
        <React.Fragment>
            {
                isInitialLoad ?
                    <div className="main-bg"
                        style={{
                            backgroundImage: `url("${'/images/tv/tv-screen-blank.jpg'}")`,
                            position: 'fixed',
                            width: '100%',
                            height: '100%',
                            backgroundSize: '100% 100%',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >

                    </div> :
                    <>
                        <div className="main-bg"
                            style={{ backgroundColor: '#000', position: 'fixed', width: '100%', height: '100%', left: 0, top: 0, zIndex: -1, overflowX: "hidden", margin: 0 }}
                        ></div>
                        <section className="container" style={{ maxWidth: '100%' }}>
                            {((isShowingAdsPromotion && addPromotionText) || (!isShowingAdsPromotion && (isInitialLoad || tvImageSelector))) &&
                                <>
                                    <div className="logobar ">
                                        <div className="border-div" />
                                        <div className="border-div2" />
                                        <div className="logo_left_txt">
                                            {
                                                isShowingAdsPromotion && addPromotionText ? addPromotionText : `${tvImageSelector && tvImageSelector.rodeo_event_info ? tvImageSelector.rodeo_event_info.title : '--'}${tvImageSelector && tvImageSelector.performance_info ? " :: " + tvImageSelector.performance_info.title : ''}${tvImageSelector && tvImageSelector.event_info ? " :: " + tvImageSelector.event_info.event_title : ''}`
                                            }
                                        </div>
                                        <div className="logo_image"><img src="/images/tv/logo_.png" className="img-responsive" /></div>
                                    </div>

                                    <div className="heading_text">
                                        {/* {
                                            tvImageSelector && tvImageSelector.performance_info && tvImageSelector.performance_info.sponsor ?
                                                <>SPONSORED BY <img src={tvImageSelector.performance_info.sponsor.thumb}/></> : null
                                        } */}
                                    </div>
                                </>
                            }
                            <div className="clearfix">
                                {
                                    !isShowingAdsPromotion ?
                                        <ul className="tv-list">
                                            {
                                                tvImageSelector && tvImageSelector.list && tvImageSelector.list.length ?
                                                    tvImageSelector.list.map((item: any) => (
                                                        // item.sum ?
                                                        <li key={item.id}>
                                                            <div className="left_sec">
                                                                <div className="border-div"></div>
                                                                <div className="curve-bg">
                                                                    <div className="left_sec_l">
                                                                        {item.user_info?.display_name?item.user_info?.display_name:item.user_info?.full_name?item.user_info?.full_name:"--"}
                                                                    </div>
                                                                    <div className="left_sec_r">{item.sum || 0}</div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        // :null
                                                    )) : <div className="text-center" style={{ color: '#fff' }}>
                                                        <p> No Result publish yet!</p>
                                                    </div>
                                            }
                                        </ul>
                                        :
                                        null
                                }
                            </div>
                        </section>
                        {isShowingAdsPromotion && addPromotionText ?
                            <div className="tv-ads-promotion">
                                {/* <img className="w-100" alt="image" /> */}
                                {/* <img className={isShowingAdsPromotion && addPromotionText ? "ad-with-txt" : "ad-without-txt"} src={addPromotionImage} alt="image" /> */}
                                <img className={"ad-with-txt"} src={addPromotionImage} alt="image" />
                            </div>
                            :
                            <div className="main-bg"
                                style={{
                                    backgroundImage: `url("${addPromotionImage}")`,
                                    position: 'fixed',
                                    width: '100%',
                                    height: '100%',
                                    backgroundSize: '100% 100%',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            >
                            </div>
                        }
                    </>
            }


        </React.Fragment>
    )
}