import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { APPLICATION_GLOBAL_SLUG, URLS } from "src/_config";
import parse from 'html-react-parser'
import NotifyMeModal from "../events/modal/notifiyMeModal";

export default function LandingPage() {

    const eventApi = useEventApi()
    const history = useHistory()
    const [gameDetails, setGameDetails] = useState<any>()
    const [showNotifyMeModal, setShowNotifyMeModal] = useState<boolean>(false)

    const openNotifyMeModal = () => {
        setShowNotifyMeModal(true)
    }

    const closeNotifyMeModal = () => {
        setShowNotifyMeModal(false)
    }

    const getGameDetails = () => {
        let params = {
            slug: APPLICATION_GLOBAL_SLUG
        }
        eventApi.callGetGameDetails(params,
            (message:any, resp:any)=>{
                console.log({resp})
                if(resp?.is_show_landing_page==0) {
                    history.push(URLS.HOME)
                }
                setGameDetails(resp)
                if(resp?.is_show_landing_page==1 && resp?.landing_info) {
                    if(resp?.landing_info?.using_image==1) {
                        let bodyTag:any = document.getElementsByTagName("body")
                        if(bodyTag) {
                            console.log(resp?.landing_info?.background_image?.original)
                            bodyTag[0].style.backgroundImage = `url(${resp?.landing_info?.background_image?.original})`
                            bodyTag[0].style.backgroundSize = "contain"
                            bodyTag[0].style.backgroundRepeat = "no-repeat"
                            bodyTag[0].style.backgroundPosition = "50% 50%"
                            bodyTag[0].style.minHeight = "100vh"
                        }
                    } else {

                    }
                }
            },
            (message:any)=>{

            }
        )
    }

    useEffect(() => {
        getGameDetails()
    }, [])

    return (
        <React.Fragment>
            {gameDetails?.landing_info?.using_image==0 && 
                <>
                    {gameDetails?.landing_info?.show_logo==1 && 
                        <div className="logo-wrap" style={{minHeight: "200px"}}>
                            <img src="/images/logo.png" alt="Logo" className="medium-logo" />
                        </div>
                    }
                    {gameDetails?.landing_info?.description && 
                        <div style={{color:"white", padding:"25px"}}>
                            {parse(gameDetails?.landing_info?.description)}
                        </div>
                    }
                </>
            }
            {gameDetails?.landing_info?.is_pick_open_notify==1 &&
                <div className="notifiy-wrap">
                    <p className="notify-text text-white">
                        Get notified when bet is open!!
                    </p>
                    <button
                        className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3 notify-btn"
                        onClick={openNotifyMeModal}
                    >
                        Notify me
                    </button>
                </div>
            }
            <NotifyMeModal
                onClose={closeNotifyMeModal}
                shouldShow={showNotifyMeModal}
            />
        </React.Fragment>
    )
}