import { SAGA_ACTIONS } from 'src/_config'
import { LoginReq, LoginWithOtpReq, UpdateUserProfile } from 'src/_common/interfaces/ApiReqRes'
import { useApiCall } from '../common/appApiCallHook'

export function useUserApi() {

  const callApi = useApiCall()

  const login = (data: LoginReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  }

  const registration = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTRATION, data, onSuccess, onError);
  }
  const forgotPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
  }

  const resetPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
  }

  const loginWithOtp = (data: LoginWithOtpReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN_WITH_OTP, data, onSuccess, onError);
  }

  const loginWithEmail = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN_WITH_EMAIL, data, onSuccess, onError);
  }

  const updateUserProfile = (data: UpdateUserProfile, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_PROFILE, data, onSuccess, onError);
  }

  const getMe = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DETAILS, null, onSuccess, onError);
  }

  const logout = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, null, onSuccess, onError);
  }

  const updateDisplayName = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_DISPLAY_NAME, data, onSuccess, onError);
  }

  const checkValidToken = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CHECK_VALID_TOKEN, null, onSuccess, onError);
  }

  const createUpdateUserGame = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CREATE_UPDATE_USER_GAME, data, onSuccess, onError);
  }

  const saveNotification = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SAVE_NOTIFICATION, data, onSuccess, onError);
  }



  return {
    callLogin: login,
    callLoginWithOtp: loginWithOtp,
    callUpdateUserProfile: updateUserProfile,
    callLogout: logout,
    callGetMe: getMe,
    callRegistration: registration,
    callLoginWithEmail: loginWithEmail,
    callForgotPassword: forgotPassword,
    callResetPassword: resetPassword,
    callUpdateDisplayName: updateDisplayName,
    callCheckValidToken: checkValidToken,
    callCreateUpdateUserGame: createUpdateUserGame,
    callSaveNotification: saveNotification,
  }
}