import React, { useEffect } from 'react';
import Sidebar from 'src/components/common/Sidebar';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import CmsPage from 'src/components/cms/cmsPage';
import { APPLICATION_NAME, RODEO_PAGE_NAME } from 'src/_config';

export default function CmsContainer() {
    const userSelector = useAppUserDetailsSelector()

    // useEffect(() => {
    //     const prevTitle = document.title
    //     document.title = `${APPLICATION_NAME} : About Us`
    //     return () => {
    //         document.title = prevTitle
    //     }
    // }, [])

    return (
        <React.Fragment>
            {/* <Sidebar page={RODEO_PAGE_NAME.CMS} /> */}
            <section className="main-container">
                <CmsPage />
            </section>
        </React.Fragment>
    )
}