// @ts-nocheck
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { URLS } from "./_config";
import requireNoAuth from "./_common/hoc/reqNoAuth";
import requireAuth from "./_common/hoc/reqAuth";
import reqLayout from "./_common/hoc/layout";
import Login from "./containers/login";
import LoginUser from "./containers/loginUser";
import Otp from "./containers/otp";
import MyProfile from "./containers/myProfile";
import RodeoEventContainer from "./containers/event/event";
import RodeoEventLeaderBoardContainer from "./containers/players/players";
import AboutUsContainer from "./containers/cms/aboutUs/aboutUs";
import HowToPlayContainer from "./containers/cms/howToPlay/howToPlay";
import TermOfUseContainer from "./containers/cms/termOfUse/termOfUse";
import RodeoTvImageContainer from "./containers/tv-image/tv-image";
import Page404 from "./containers/page-404";
import CacheBuster from "./CacheBuster";
import OverUnderEvent from "./containers/event";
import OverUnderEventLeaderBoard from "./containers/players";
import PlayerDetailsContainer from "./containers/player-details/playerDetails";
import Register from "./containers/register/register";
import Rodeos from "./containers/rodeo-event";
import Performance from "./containers/performance";
import LeaderBoard from "./containers/leaderboard";
import ForgotPassword from "./containers/forgot-password";
import ResetPassword from "./containers/reset-password";
import PrizesListContainer from "./containers/prizes-list/prizes-list";
import PrizeDetailsContainer from "./containers/prize/prize-details";
import CmsContainer from "./containers/cms/cms";
import { registerLicense } from "@syncfusion/ej2-base";
import LandingPageContainer from "./containers/landing/landing";
import LoginWithEmail from "./containers/loginWithEmail";
import LoginLanding from "./components/login/loginLanding";

// Registering Syncfusion license key
// registerLicense("ORg4AjUWIQA/Gnt2VFhiQlJPcEBAQmFJfFBmQmlZfVRxdkU3HVdTRHRcQlhjSn5UckRmWHlYc3Q=")

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <React.Fragment>
            <Router>
              <Switch>
                {/* <Route exact path={URLS.HOME} component={(RodeoEventContainer)} /> */}
                <Route exact path={URLS.HOME} component={requireNoAuth(Login)} />
                <Route exact path={URLS.TV_IMAGE} component={RodeoTvImageContainer} />
                <Route exact path={URLS.USER.PROFILE} component={requireAuth(MyProfile)} />
                <Route exact path={URLS.CMS.CMS} component={CmsContainer} />
                {/* <Route exact path={URLS.CMS.ABOUT_US} component={(AboutUsContainer)} /> */}
                <Route exact path={URLS.EVENT.LEADER_BOARD.EVENT} component={RodeoEventLeaderBoardContainer} />
                <Route exact path={URLS.LOGIN} component={requireNoAuth(LoginLanding)} />
                <Route exact path={URLS.LOGIN_WITH_OTP} component={requireNoAuth(LoginUser)} />
                <Route exact path={URLS.LOGIN_WITH_EMAIL} component={requireNoAuth(LoginWithEmail)} />
                {/* <Route exact path={URLS.CMS.TERM_OF_USE} component={(TermOfUseContainer)} /> */}
                {/* <Route exact path={URLS.CMS.HOW_TO_PLAY} component={(HowToPlayContainer)} /> */}
                <Route exact path={URLS.LANDING} component={LandingPageContainer} />
                <Route exact path={URLS.EVENT.LIST} component={OverUnderEvent} />
                <Route exact path={URLS.EVENT.PLAYERS} component={OverUnderEventLeaderBoard} />
                <Route exact path={URLS.EVENT.PLAYER_DETAILS} component={PlayerDetailsContainer} />
                <Route exact path={URLS.EVENT.PRIZES_LIST} component={PrizesListContainer} />
                <Route exact path={URLS.EVENT.PRIZE} component={PrizeDetailsContainer} />
                <Route exact path={URLS.REGISTRATION} component={requireNoAuth(Register)} />
                <Route exact path={URLS.OTP} component={requireNoAuth(Otp)} />
                <Route exact path={URLS.RODEOS} component={Rodeos} />
                <Route exact path={URLS.EVENT.PERFORMANCE} component={Performance} />
                <Route exact path={URLS.EVENT.LEADERBOARD} component={LeaderBoard} />
                <Route exact path={URLS.USER.FORGOT_PASSWORD} component={ForgotPassword} />
                <Route exact path={URLS.USER.RESET_PASSWORD} component={ResetPassword} />
                <Route path="*" component={reqLayout(Page404)} />
              </Switch>
            </Router>
          </React.Fragment>
        );
      }}
    </CacheBuster>
  );
}

export default App;
