import { SAGA_ACTIONS } from 'src/_config'
import { GetEventList, GetEventGameList, GetEventGameDetails, GetUpcomingEventList, JoinEventGame, PickRider, LeaderBoard, TvImageDetails, SaveBet } from 'src/_common/interfaces/ApiReqRes'
import { useApiCall } from '../common/appApiCallHook'

export function useEventApi() {

    const callApi = useApiCall()

    const eventList = (data: GetEventList, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_EVENT_LIST, data, onSuccess, onError);
    }

    const eventGameList = (data: GetEventGameList, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_EVENT_GAME, data, onSuccess, onError);
    }

    const eventGameDetails = (data: GetEventGameDetails, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_EVENT_GAME_DETAILS, data, onSuccess, onError);
    }

    const upcomingEventList = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_UPCOMING_EVENT, data, onSuccess, onError);
    }

    const playersList = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.PLAYERS_LIST, data, onSuccess, onError);
    }

    const playersDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.PLAYERS_DETAILS, data, onSuccess, onError);
    }

    const bettingPointsSubmit = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.BETTING_POINT_SUBMIT, data, onSuccess, onError);
    }

    const pickRider = (data: PickRider, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.PICK_RIDER, data, onSuccess, onError);
    }

    const viewLeaderBoard = (data: LeaderBoard, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.LEADERBOARD, data, onSuccess, onError);
    }

    const joinEventGame = (data: JoinEventGame, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.JOIN_EVENT_GAME, data, onSuccess, onError);
    }

    const tvImageList = (data: TvImageDetails, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.TV_IMAGE.DETAILS, data, onSuccess, onError, false);
    }

    const saveBet = (data: SaveBet, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.SAVE_BET, data, onSuccess, onError);
    }

    const prizeDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.PRIZE_DETAILS, data, onSuccess, onError);
    }

    const resetBet = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.RESET_BET, data, onSuccess, onError);
    }

    const getRodeoEventGeoLocations = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_RODEO_EVENT_GEO_LOCATIONS, data, onSuccess, onError);
    }

    const getLeaderboardLocationList = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.LEADERBOARD_LOCATION_LIST, data, onSuccess, onError);
    }

    const getGameStatusText = (data: any, onSuccess: Function, onError: Function) => {
      callApi(SAGA_ACTIONS.EVENT.GET_GAME_STATUS_TEXT, data, onSuccess, onError);
    }

    const getGameDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_GAME_DETAILS, data, onSuccess, onError);
    }

    return {
        callGetEventList: eventList,
        callGetUpcomingEventList: upcomingEventList,
        callGetEventGameList: eventGameList,
        callGetEventGameDetails: eventGameDetails,
        callPickRider: pickRider,
        callViewLeaderBoard: viewLeaderBoard,
        callTvImageDetails: tvImageList,
        callJoinEventGame: joinEventGame,
        callGetPlayersList:playersList,
        callSubmitPlayersBettingPoints:bettingPointsSubmit,
        callGetPlayersDetails:playersDetails,
        callSaveBet:saveBet,
        callPrizeDetails: prizeDetails,
        callResetBet: resetBet,
        callGetRodeoEventGeoLocations: getRodeoEventGeoLocations,
        callGetLeaderboardLocationList: getLeaderboardLocationList,
        callGetGameStatusText: getGameStatusText,
        callGetGameDetails: getGameDetails,
    }
}