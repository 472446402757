import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { APPLICATION_GLOBAL_SLUG, URLS } from "src/_config";

function LoginLanding() {
  const [gameDetails, setGameDetails] = useState<any>();
  const eventApi = useEventApi();

  const getGameDetails = () => {
    let params = {
      slug: APPLICATION_GLOBAL_SLUG,
    };
    eventApi.callGetGameDetails(
      params,
      (message: any, resp: any) => {
        console.log({ resp });
        setGameDetails(resp);
      },
      (message: any) => {}
    );
  };

  useEffect(() => {
    getGameDetails();
  }, []);

  return (
    <>
      <div className="login-form" style={{ marginTop: "110px" }}>
        <div className="container loginpage-container">
          <p style={{ color: "#fff", fontWeight: "bold", fontSize: "20px" }}>Welcome to</p>
          <img src="images/logo.png" alt="#" />
          <div className="go-btn mt-5">
            <Link to={URLS.LOGIN_WITH_OTP} className="btn">
              LOGIN WITH OTP
            </Link>
          </div>
          {gameDetails?.login_with_email == 1 && (
            <div className="go-btn">
              <Link to={URLS.LOGIN_WITH_EMAIL} className="btn" style={{ color: "#000", backgroundColor: "#FFF" }}>
                LOGIN WITH EMAIL
              </Link>
            </div>
          )}
        </div>
      </div>
      {/* </section> */}
      {/* <Sponsor /> */}
    </>
  );
}

export default LoginLanding;
