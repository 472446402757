import React, { useEffect } from 'react';
import PrizeDetailsPage from 'src/components/events/prize/prizeDetails';
import { RODEO_PAGE_NAME, APPLICATION_NAME } from 'src/_config';

export default function PrizeDetailsContainer() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : Prize`
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>
            {/* <Sidebar page={RODEO_PAGE_NAME.LEADERBOARD} /> */}
            <section className="main-container">
            {/* <div className="event-sec">
                <div className="container"> */}
                    <PrizeDetailsPage />
                {/* </div>
            </div> */}
            </section>
        </React.Fragment>
    )
}
