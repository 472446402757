import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useAppUserDetailsSelector, useAppUserTokenSelector } from "src/_common/hooks/selectors/userSelector";
import { useAppEvenListSelector, useAppEventDetailsSelector, useAppEventStatusMessageSelector, useAppGameDetailsSelector, useAppIrStatusMessageSelector } from "src/_common/hooks/selectors/eventSelector";
import useSocket from "use-socket.io-client";
import {
  URLS,
  STORAGE,
  SELECTED_EVENT_STORAGE,
  INSIDERODEO_EVENT_STORAGE,
  detectDevice,
  IS_SHOWING_ADD_TO_HOME_SCREEN_IOS,
  GUEST_USER_ID,
  SOCKET_CHANNEL,
  SOCKET_URL,
  APP_USER_ID,
  CRYPTO_SECRET_KEY,
  APPLICATION_GLOBAL_SLUG,
} from "src/_config";
import { useUserApi } from "src/_common/hooks/actions/user/appUserApiHook";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import { RODEO_PAGE_NAME } from "src/_config";
import GuestSaveBetModal from "../events/modal/guestSaveBetModal";
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
import StatusModal from "../events/modal/statusModal";
import UpdateDisplayNameModal from "../events/modal/updateDisplayNameModal";
import CouponModal from "../events/modal/couponModal";
import VideoModal from "../events/modal/videoModal";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

interface SideBarProps {
  page: string;
}

function Sidebar({ page }: SideBarProps) {
  const history = useHistory();
  const userDetails = useAppUserDetailsSelector();
  const statusMessageSelector = useAppEventStatusMessageSelector();
  const irStatusMessageSelector = useAppIrStatusMessageSelector();
  const userAction = useAppUserAction();
  const userApi = useUserApi();
  const toast = useToaster();
  const [showGuestSaveBetModal, setShowGuestSaveBetModal] = useState<boolean>(false)
  const guestUserId = localStorage.getItem(GUEST_USER_ID);
  const location = useLocation();
  const eventAction = useAppEventAction()
  const [appRedirectData, setAppRedirectData] = useState<any>();
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [showDisplayNameModal, setShowDisplayNameModal] = useState<boolean>(false);
  const [showCouponModal, setShowCouponModal] = useState<boolean>(false);
  const [couponData, setCouponData] = useState<any>();
  const urlParams = useParams<any>()
  const userToken = useAppUserTokenSelector()
  const eventDetails = useAppEventDetailsSelector()
  const eventList = useAppEvenListSelector()
  const gameDetails = useAppGameDetailsSelector()
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false)
  const [url, setUrl] = useState<any>('')
  const [promoType, setPromoType] = useState<any>('')
  const eventApi = useEventApi()

  const getGameDetails = () => {
      let params = {
          slug: APPLICATION_GLOBAL_SLUG
      }
      eventApi.callGetGameDetails(params,
          (message:any, resp:any)=>{
              // console.log({resp})
              if(resp?.is_show_landing_page) {
                history.push(URLS.LANDING)
              }
          },
          (message:any)=>{

          }
      )
  }

  const isInsideRodeoTokenExists = localStorage.getItem(
    INSIDERODEO_EVENT_STORAGE
  );
  const [isShowIosPopup, setIsShowIosPopup] = useState<any>(
    localStorage.getItem(IS_SHOWING_ADD_TO_HOME_SCREEN_IOS)
      ? parseInt(localStorage.getItem(IS_SHOWING_ADD_TO_HOME_SCREEN_IOS) || "0")
      : 1
  );


  const toogleSideBarOnOff = (status: boolean) => {
    var sidebarCollapseElement = document.getElementById("sidebarCollapse");
    var sidebarElement = document.getElementById("sidebar");
    var overlayElement = document.getElementById("overlay");
    if (status) {
      if (sidebarCollapseElement) {
        sidebarElement?.classList.add("active");
        overlayElement?.classList.add("active");
      }
    } else {
      if (sidebarCollapseElement) {
        sidebarElement?.classList.remove("active");
        overlayElement?.classList.remove("active");
      }
    }
  };

  const logOut = (e: React.MouseEvent) => {
    userApi.callLogout(
      (message: string, resp: any) => {
        if (resp) {
          toogleSideBarOnOff(false);
          userAction.logout();
          localStorage.removeItem(STORAGE);
          localStorage.removeItem(SELECTED_EVENT_STORAGE);
          localStorage.removeItem(INSIDERODEO_EVENT_STORAGE);
          localStorage.removeItem(GUEST_USER_ID);
          localStorage.removeItem(APP_USER_ID);
          history.push(URLS.RODEOS);
        } else {
          toast.error(message);
        }
      },
      (message: string) => {
        userAction.logout()
        // toast.error(message)
      }
    );
  };

  const goToMyAccount = () => {
    history.push(URLS.USER.PROFILE);
  };

  const closeIosPopup = (e: any) => {
    e.preventDefault();
    setIsShowIosPopup(0);
    localStorage.setItem(IS_SHOWING_ADD_TO_HOME_SCREEN_IOS, "0");
  };

  const onSuccessGuestSaveBetModal = (status: boolean) => {
      if (status) {
          history.push(URLS.LOGIN)
      }
  }

  const closeGuestSaveBetModal = () => {
      if (showGuestSaveBetModal) setShowGuestSaveBetModal(false)
      
      eventAction.resetBetIds()
      localStorage.removeItem(GUEST_USER_ID)
      history.push(URLS.RODEOS)
  }

  const onStatusModalClose = () => {
    setShowStatusModal(false)
  }

  const onCouponModalClose = () => {
    setShowCouponModal(false)
  }

    const onStatusModalSuccess = () => {
        if(appRedirectData) {
            setShowStatusModal(false)
            let link = appRedirectData.game_info.pwa_link
            if(link) {
                // if (userDetails && userToken) {
                    setTimeout(()=>{
                      window.open(link + (appRedirectData?.rodeo_event_id && userToken ? `#token=${cryptr.encrypt(appRedirectData.rodeo_event_id)}&userToken=${userToken}` : userToken ? `#userToken=${userToken}` : ''), "_blank")
                    },500)
                    // window.location.href = link + (appRedirectData?.rodeo_event_id && userToken ? `#token=${cryptr.encrypt(appRedirectData.rodeo_event_id)}&userToken=${userToken}` : userToken ? `#userToken=${userToken}` : '')
                    // window.location.href = link + (appRedirectData?.rodeo_event_id ? `#token=${cryptr.encrypt(appRedirectData.rodeo_event_id)}&userToken=${userToken}` : "")
                // }
            }
        }  
    }

  const [socket] = useSocket(SOCKET_URL, {
    autoConnect: false,
  });

  useEffect(() => {
      socket.connect();
      let loc: any = location;
     // console.log({loc})
      //Status Change Socket
      socket.on(SOCKET_CHANNEL.START_EVENT, (changeEventStatus: any) => {
        console.log({changeEventStatus})
        let re_id=urlParams?.rodeoEventId ? cryptr.decrypt(urlParams.rodeoEventId) : null
        if(changeEventStatus && changeEventStatus.event_info && changeEventStatus.event_info.status==2 && changeEventStatus.rodeo_event_id==re_id && guestUserId)
        {
          setShowGuestSaveBetModal(true)
        }
        if(changeEventStatus && changeEventStatus.event_info && changeEventStatus.event_info.status && [0,1].includes(changeEventStatus.event_info.status)) {
          // history.push({
          //   pathname: '/players',
          //   state: { rodeo_event_id: changeEventStatus.rodeo_event_id, 
          //     performance_id: changeEventStatus.performance_id,
          //     id: changeEventStatus.rodeo_event_event_id
          //   },
          // });
          // if(changeEventStatus?.contestant_id && changeEventStatus?.rodeo_event_id==cryptr.decrypt(urlParams?.rodeoEventId)) {
          //   history.push(`/player-details/${cryptr.encrypt(changeEventStatus.rodeo_event_id)}/${cryptr.encrypt(changeEventStatus.performance_id)}/${cryptr.encrypt(changeEventStatus.rodeo_event_event_id)}/${cryptr.encrypt(changeEventStatus.contestant_id)}`)
          // }
          if(changeEventStatus?.rodeo_event_id && urlParams?.rodeoEventId && changeEventStatus?.rodeo_event_id==cryptr.decrypt(urlParams?.rodeoEventId)) {
          // else if(changeEventStatus?.rodeo_event_event_id) {
            // if(page!='contestant-list') {
            if(page=='event_list' || (page=='contestant-details' && changeEventStatus?.contestant_id!=cryptr.decrypt(urlParams?.playerId)) || (page=='contestant-list' && urlParams?.eventId && changeEventStatus?.rodeo_event_event_id!=cryptr.decrypt(urlParams?.eventId)) ) {
              history.push(`/players/${cryptr.encrypt(changeEventStatus.rodeo_event_id)}/${cryptr.encrypt(changeEventStatus.performance_id)}/${cryptr.encrypt(changeEventStatus.rodeo_event_event_id)}`)
            }
          }
        }
        if(page=='rodeos') {
          let events = [...eventList]
          console.log(events)
          events = events.map((x:any)=>{
            if(x.id==changeEventStatus.rodeo_event_id) {
              if(changeEventStatus.event_info.status==1) {
                x.currently_ongoing = 1
              }  else {
                x.currently_ongoing = 0
              }
              return x
            } else {
              return x
            }
          })
          console.log(events)
          eventAction.setEventList(events)
        }
        // eventAction.pushStartEventData(changeEventStatus)
      });

      socket.on(SOCKET_CHANNEL.IR_STATUS_MSG, (changeEventStatus: any) => {
        console.log("irStatus",changeEventStatus)
        if(changeEventStatus?.site == APPLICATION_GLOBAL_SLUG) {
          eventAction.getIrStatusMessage(changeEventStatus)
        }
      })

      socket.on(SOCKET_CHANNEL.OU_STATUS_MSG, (changeEventStatus: any) => {
        console.log("OuStatusChange>>>>>>>>>>",changeEventStatus)
          if(changeEventStatus?.rodeo_event_id && urlParams?.rodeoEventId && changeEventStatus?.rodeo_event_id==cryptr.decrypt(urlParams?.rodeoEventId)) {
            eventAction.getStatusMessage(changeEventStatus)
          }
      });

      socket.on(SOCKET_CHANNEL.APP_REDIRECT, (data: any) => {
        if(data.game_info.slug!=APPLICATION_GLOBAL_SLUG) {
           // console.log({data})
            setShowStatusModal(true)
            setAppRedirectData(data)
        }
      });

      socket.on(SOCKET_CHANNEL.OU_EMIT_SCORE, (changeEventStatus: any) => {
        console.log({changeEventStatus})
      });

      socket.on(SOCKET_CHANNEL.COUPON, (data: any) => {
        console.log({data})
        setCouponData(data)
        setShowCouponModal(true)
      });

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  useEffect(()=>{
   // console.log(page,statusMessageSelector)
  },[page,statusMessageSelector])

  const onPageChange = () => {
    if(userDetails && !userDetails.display_name) {
     // console.log({userDetails})
      setShowDisplayNameModal(true)
    }
  }

  const gotoCmsPage = (slug: any) => {
    history.push('/cms/'+slug)
  }

  const closeVideoModal = () => {
    localStorage.setItem("loginPromoVidSeen", "1")
    setTimeout(()=>setShowVideoModal(false),1000)
  }

  useEffect(()=>{    
    onPageChange()
  },[])

  useEffect(()=>{
    //console.log({statusMessageSelector})
  },[statusMessageSelector])

  useEffect(()=>{
    if(userToken) {
      userApi.callCheckValidToken(
        (message:any, resp:any)=>{
            console.log(1,{resp})
            if(!resp) {
            console.log(2,{resp})
            userAction.logout()
          }
        },
        (message:any, resp:any)=>{
          console.log(3,{resp})
          userAction.logout()
        }
      )
    }
    let parshedHash = localStorage.getItem('parshedHash')
    if(parshedHash=='1') {
      userApi.callCreateUpdateUserGame(
        {
            game_slug: APPLICATION_GLOBAL_SLUG
        },
        (message: string, resp: any) => {
            console.log({resp})
            localStorage.removeItem('parshedHash')
        }, (message: string) => {
        }
      )
    }
  },[page])

  useEffect(()=>{
    let x = localStorage.getItem("loginPromoVidSeen")
    if(page==RODEO_PAGE_NAME.LOGIN && gameDetails?.promotional_video_image && x!="1") {
      let vid = gameDetails?.promotional_video_image
      let type = vid?.file_mime.substring(0,vid?.file_mime?.indexOf('/'))
      setPromoType(type)
      setUrl(vid?.original)
      if(type=="video" || type=="image") {
        setShowVideoModal(true)
      }
    }
  },[page, gameDetails])

  useEffect(()=>{
    getGameDetails()
  },[])

  return (
    <>
      <section className="top-nav">
        <nav
          id="sidebar"
          className="mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar bgclr-set"
          style={{ overflow: "visible" }}
        >
          <div id="dismiss" onClick={() => toogleSideBarOnOff(false)} className="bgclr-set">
            <i className="fa fa-arrow-left" />
          </div>
          <div className="sidebar-header bgclr-set">
            <img src="/images/logo.png" alt="Over And Under" /> Over And Under
          </div>

          <ul className="list-unstyled components">
            {!isInsideRodeoTokenExists ? (
              <li>
                <Link to={URLS.RODEOS}>Rodeo Events</Link>
              </li>
            ) : null}
            {userDetails ? (
              <li>
                <Link to={URLS.USER.PROFILE}>My Profile</Link>
              </li>
            ): <li>
              <Link to={URLS.LOGIN}>Login</Link>
            </li>
            }
            

            {/* <li>
              <Link to={URLS.CMS.ABOUT_US}>About Us</Link>
            </li> */}

            <li>
              <a onClick={()=>gotoCmsPage("how-to-play-101")}>How to Play 101</a>
            </li>
            <li>
              <a onClick={()=>gotoCmsPage("privacy-policy")}>Privacy Policy</a>
            </li>
            <li>
              <a onClick={()=>gotoCmsPage("legal")}>Legal</a>
            </li>
            <li>
              <a onClick={()=>gotoCmsPage("prizes")}>Prizes</a>
            </li>
            {/*<li>
              <a onClick={()=>gotoCmsPage("term-of-use")}>Terms and Conditions</a>
            </li>*/}
            <li>
              <a onClick={()=>gotoCmsPage("add-app-icon")}>Add App Icon</a>
            </li>
            {userDetails && (
              <li>
                <Link to="#" onClick={(e) => logOut(e)}>
                  Logout
                </Link>
              </li>
            )}
          </ul>
        </nav>
        <nav className="navbar navbar-light">
          <div className="container-fluid">
            <button type="button" id="sidebarCollapse" className="btn-none">
              <span className="open-nav" onClick={() => toogleSideBarOnOff(true)}>
                {" "}
                <div className="hambargar-menu harberger-menu-desgn">
                  <a
                    className="sidebar-menu-icon text-dark"
                    data-bs-toggle="offcanvas"
                    role="button"
                    aria-controls="offcanvasExample"
                  >
                    <img src="/images/menu.png" alt="#" />
                  </a>
                </div>
              </span>
            </button>
            {[
              RODEO_PAGE_NAME.PERFORMANCE,
              RODEO_PAGE_NAME.CONTESTANT_LIST,
              RODEO_PAGE_NAME.CONTESTANT_DETAILS,
              RODEO_PAGE_NAME.LEADERBOARD,
              RODEO_PAGE_NAME.EVENT_LIST,
              RODEO_PAGE_NAME.PRIZES_LIST,
              RODEO_PAGE_NAME.PRIZE,
              RODEO_PAGE_NAME.RODEOS
            ].includes(page) && (
              // statusMessageSelector && statusMessageSelector.status_text &&
              // statusMessageSelector && statusMessageSelector.length && page!=RODEO_PAGE_NAME.RODEOS ?
              statusMessageSelector && page!=RODEO_PAGE_NAME.RODEOS ?
                <div className="status-bar-wrap">
                  <Marquee direction="left" loop={0} pauseOnHover={true} speed={60}>
                    {statusMessageSelector}
                  </Marquee>
                </div>
              :
              irStatusMessageSelector ? 
                <div className="status-bar-wrap">
                  <Marquee direction="left" loop={0} pauseOnHover={true} speed={60}>
                    {irStatusMessageSelector}
                  </Marquee>
                </div>
                : null
            )}
          </div>
        </nav>
        <div
          className="overlay"
          id="overlay"
          onClick={() => toogleSideBarOnOff(false)}
        />

        {detectDevice() && isShowIosPopup ? (
          <>
          </>
        ) : null}

        {
            showGuestSaveBetModal &&
            <GuestSaveBetModal
                onClose={closeGuestSaveBetModal}
                onSuccess={onSuccessGuestSaveBetModal}
                shouldShow={showGuestSaveBetModal}
            />
        }
        {
          showStatusModal && appRedirectData &&
          <StatusModal
              shouldShow={showStatusModal}
              onClose={onStatusModalClose}
              onSuccess={onStatusModalSuccess}
              message={appRedirectData?.message}
              type={2}
          />
        }
        {showDisplayNameModal && 
          <UpdateDisplayNameModal
            onClose={()=>setShowDisplayNameModal(false)}
            shouldShow={showDisplayNameModal}
          />
        }
        {
          showCouponModal && couponData && couponData.image && (couponData.type==1||(couponData.type==2 && userDetails && userDetails.id)) && 
          <CouponModal
              shouldShow={showCouponModal}
              onClose={onCouponModalClose}
              image={couponData.image}
          />
        }
      </section>

      {url && showVideoModal && promoType &&
        <VideoModal
          onClose={closeVideoModal}
          url={url}
          shouldShow={showVideoModal}
          type={promoType}
        />
      }
    </>
  );
}

export default Sidebar;
