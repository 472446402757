import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Router, useHistory, useLocation } from "react-router";
import moment from "moment";

import {
  APPLICATION_GLOBAL_SLUG,
  CUSTOM_MESSAGE,
  DATE_FORMAT,
  destroyMaskInput,
  INSIDERODEO_EVENT_STORAGE,
  SELECTED_EVENT_STORAGE,
  URLS,
} from "src/_config";
import { useUserApi } from "src/_common/hooks/actions/user/appUserApiHook";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import FormMaskedInput from "src/_common/components/form-elements/maskedInput/maskedInput";
import ReactFlagsSelect from "react-flags-select";
import { Form } from "react-bootstrap";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import DateInput from "src/_common/components/form-elements/datepicker/dateInput";
import Sponsor from "../common/Sponsor";
import SyncFusionDatePicker from "src/_common/components/form-elements/datepicker/syncfusionDatePicker";
interface LoginFormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  username: string;
  phone: any;
  dob: any;
}
interface OtpFormValues {
  otp: string;
}

// const loginSchema = yup.object().shape({
//   first_name: yup.string().required("First Name is required"),
//   last_name: yup.string().required("Last Name is required"),
//   email: yup.string().required("Email is required"),
//   password: yup.string().required("Password is required"),
//   dob: yup.string().required("DOB is required"),
//   username: yup.string().required("Display Name is required"),
//   phone: yup.string().required("Phone Number is required"),
// });

const loginSchema = yup.object().shape({
  first_name: yup.string(),
  last_name: yup.string(),
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
  dob: yup.string(),
  username: yup.string().required("Display Name is required"),
  phone: yup.string(),
});

function RegisterForm() {

  const hideplan = {
    display: "none",
  };
  const location: any = useLocation();
  const userApi = useUserApi();
  const toast = useToaster();
  const history = useHistory();
  const [geoLocation, setGeoLocation] = useState<any>(null);

  useEffect(() => {
    let loc: any = location;
    // console.log(loc?.state?.phone);
  }, [location]);

  const { control, handleSubmit, errors } = useForm<LoginFormValues>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      username: "",
      phone: location?.state?.phone ? location?.state?.phone : "",
      dob: "",
    },
  });

  const registrationSubmit = (data: any) => {
    const mobileNoDigit = data.phone && data.phone.replace(/\D/g, "").slice(-10);
    if (mobileNoDigit) {
      data["phone"] = parseInt(mobileNoDigit);
    }

    // data["dobDate"] = moment(data?.dob).format("DD");
    // data["dobMonth"] = moment(data?.dob).format("MM");
    // data["dobYear"] = moment(data?.dob).format("YYYY");

    data["game_slug"] = APPLICATION_GLOBAL_SLUG;
    if (location?.state?.isblock) {
      data["isblock"] = location?.state?.isblock;
    }
    if (geoLocation) {
      data["lat"] = geoLocation.lat;
      data["lng"] = geoLocation.lng;
    }
    userApi.callRegistration(
      data,
      (message: string, resp: any) => {
        // console.log(resp);
        if (resp) {
          // console.log(resp);
          toast.success("Your registration has been successfully completed");
          history.push(URLS.RODEOS);
        } else {
          toast.error(message);
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          const { latitude, longitude } = position.coords;
          setGeoLocation({ lat: latitude, lng: longitude });
        },
        (error: any) => {
          setGeoLocation({ lat: null, lng: null });
        }
      );
    } else {
      setGeoLocation({ lat: null, lng: null });
    }
  }, []);

  useEffect(() => {
    console.log({ geoLocation });
  }, [geoLocation]);

  return (
    <form onSubmit={handleSubmit(registrationSubmit)}>
      <div className="">
        <div className="container">
          <img src="images/logo.png" alt="#" />

          <div className="login-form-field">
            <div className="row">
              <div className="col-12">
                <div className="login-form-field-inner mt-3" style={hideplan}>
                  <Controller
                    control={control}
                    name="first_name"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <FormTextInput
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type={"text"}
                        error={errors.first_name}
                        placeholder="First Name"
                      />
                    )}
                  />
                  <img src="images/user.png" alt="#" />
                </div>
                <div className="login-form-field-inner mt-3" style={hideplan}>
                  <Controller
                    control={control}
                    name="last_name"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <FormTextInput
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type={"text"}
                        error={errors.last_name}
                        placeholder="Last Name"
                      />
                    )}
                  />
                  <img src="images/user.png" alt="#" />
                </div>

                <div className="login-form-field-inner mt-3">
                  <Controller
                    control={control}
                    name="email"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <FormTextInput
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type={"text"}
                        error={errors.email}
                        placeholder="Email Address"
                      />
                    )}
                  />
                  <img src="images/@-sing.png" alt="#" />
                </div>

                <div className="login-form-field-inner mt-3">
                  <Controller
                    control={control}
                    name="password"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <FormTextInput
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type={"password"}
                        error={errors.password}
                        placeholder="Password"
                      />
                    )}
                  />
                  <img src="images/lock.png" alt="#" />
                </div>

                <div className="login-form-field-inner mt-3" style={hideplan}>
                  <Controller
                    control={control}
                    name="dob"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <DateInput
                        onChange={(e) => {
                          onChange(e);
                        }}
                        onBlur={onBlur}
                        value={value}
                        maxDate={new Date()}
                        dateFormat={DATE_FORMAT}
                        inputRef={ref}
                        error={errors.dob}
                        placeholder="Date of Birth"
                      />
                    )}
                  />
                  {/* <Controller
                      control={control}
                      name="dob"
                      className="form-project mb-12 project-calender"
                      render={({ onChange, onBlur, value, name, ref }) => (
                          <SyncFusionDatePicker
                              name={name}
                              onChange={(date: any) => {
                                  onChange(date.value);
                                  // setStartDate(date.value);
                              }}
                              onBlur={onBlur}
                              value={value}
                              // setValue={setValue}
                              inputRef={ref}
                              id={name}
                              maxDate={new Date()}
                              error={errors.dob}
                              cssClass={'syncfusion_dp'}
                              dateFormat={DATE_FORMAT}
                              placeholder="MM/DD/YYYY"
                              className="form-control fr_date"
                          />
                      )}
                  /> */}
                  <img src="images/user.png" alt="#" />
                </div>

                <div className="login-form-field-inner mt-3">
                  <Controller
                    control={control}
                    name="username"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <FormTextInput
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type={"text"}
                        error={errors.username}
                        placeholder="Big Screen Display Name"
                      />
                    )}
                  />
                  <img src="images/user.png" alt="#" />
                </div>
                <div className="login-form-field-inner mt-3">
                  <Controller
                    control={control}
                    name="phone"
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <FormMaskedInput
                        name={name}
                        mask={"(999) 999 9999"}
                        maskChar=" "
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        //   disabled={isOtpFormActivate}
                        type="tel"
                        placeholder="Mobile Number (Optional)"
                        error={errors.phone}
                      />
                    )}
                  />
                  <img src="images/mob-.png" alt="#" />
                </div>
                {/* <div className="forget-pass-text">
                  <a href="#">Forgot Password? </a>
                </div> */}
                <div className="go-btn mt-3">
                  {/* <a href="#"> */}
                  {/* <Link to={URLS.LOGIN} className="back-history"> */}
                  <button type="submit" className="btn">
                    Let's Go!
                  </button>
                  {/* </Link> */}
                  {/* </a> */}
                </div>
                <div className="join-sec">
                  <p>
                    Already Joined?{" "}
                    <Link to={URLS.LOGIN_WITH_EMAIL} className="back-history">
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sponsor />
    </form>
  );
}

export default RegisterForm;
