import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Header from "src/components/common/Header";
import Sidebar from "src/components/common/Sidebar";
import Sponsor from "src/components/common/Sponsor";
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import {
  useAppAvailablePointsSelector,
  useAppEarnedPointsSelector,
  useAppEventDetailsSelector,
  useAppPerformanceInfoSelector,
  usePlayersListSelector,
  useTotalContestentDataSelector,
} from "src/_common/hooks/selectors/eventSelector";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import {
  APP_USER_ID,
  CRYPTO_SECRET_KEY,
  GUEST_USER_ID,
  RODEO_PAGE_NAME,
  SOCKET_CHANNEL,
  SOCKET_URL,
  URLS,
} from "src/_config";
import useSocket from "use-socket.io-client";
import ConfettiExplosion from "react-confetti-explosion";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function PlayerVsBullPage() {
  const eventDetails = useAppEventDetailsSelector();
  const playersList = usePlayersListSelector();
  const urlParams = useParams<any>();
  const history = useHistory();
  const [remainingBet, setRemainingBet] = useState<any>(0);
  const totalData = useTotalContestentDataSelector();
  const [leaderboardStatus, setLeaderboardStatus] = useState<any>("");
  const earnedPoints = useAppEarnedPointsSelector();
  const [statusModalMesssage, setStatusModalMesssage] = useState<any>();
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const negativePointInfo =
    "If an event’s score is corrected after picks are settled, for example due to an appeal or a data entry error, then all picks will be recalculated taking the correction into account.  In some cases, that may lead to a negative balance.  If you have a negative balance, you cannot place any additional wagers until and unless your balance becomes positive from the settlement of other picks you have already placed.";
  const availablePoints = useAppAvailablePointsSelector();
  const eventAction = useAppEventAction();
  const userDetails: any = useAppUserDetailsSelector();
  const eventApi = useEventApi();
  const guest_user_id = localStorage.getItem(GUEST_USER_ID);
  const performanceInfo = useAppPerformanceInfoSelector();
  const [winningUsersData, setWinningUsersData] = useState<any>();

  const getInnerEventList = () => {
    eventAction.setPlayersList(null);

    const params: any = {
      rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId),
      performance_id: cryptr.decrypt(urlParams.performanceId),
      inner_event_id: cryptr.decrypt(urlParams.eventId),
    };

    if (userDetails && !guest_user_id) {
      localStorage.setItem(APP_USER_ID, userDetails?.id);
    }

    if (guest_user_id && !userDetails) {
      params["guest_user_id"] = guest_user_id;
    }
    // console.log(params)
    eventApi.callGetPlayersList(
      params,
      (message: string, resp: any) => {
        console.log({ resp });
      },
      (message: string) => {
        // toast.error(message);
      }
    );
  };

  const goToEventPage = () => {
    history.push(`/events/${urlParams.rodeoEventId}/${urlParams.performanceId}`);
  };

  const goToPlayerDetailsPage = (p: any) => {
    history.push(
      `/player-details/${urlParams.rodeoEventId}/${urlParams.performanceId}/${urlParams.eventId}/${cryptr.encrypt(
        p.id
      )}`
    );
  };

  const goToRodeoEventLeaderboardPage = () => {
    // let loc: any = location;
    history.push({
      pathname: "/leaderboard",
      state: { rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId) },
    });
  };

  useEffect(() => {
    if (totalData?.min_bet_leaderboard != 0) {
      if (totalData?.user_remaining_bet == 0) {
        setLeaderboardStatus("green");
      } else if (totalData?.user_remaining_bet > 0) {
        setLeaderboardStatus("red");
        setRemainingBet(totalData?.user_remaining_bet);
      }
    } else {
    }
  }, [totalData]);

  const [socket] = useSocket(SOCKET_URL, {
    autoConnect: false,
  });

  useEffect(() => {
    socket.connect();

    //Status Change Socket
    socket.on(SOCKET_CHANNEL.OU_EVENT_DETAILS, (changeEventStatus: any) => {
      console.log({ playersList });
      console.log(changeEventStatus);
      eventAction.playerListStatusUpdate(changeEventStatus);
    });

    socket.on(SOCKET_CHANNEL.START_EVENT, (changeEventStatus: any) => {
      console.log({ playersList });
      console.log("StartEvent>>>", changeEventStatus);
      eventAction.pushEventStatusToTotalData(changeEventStatus.event_info);

      let c = changeEventStatus?.contestants?.find((x: any) => x.id == changeEventStatus?.contestant_id);
      console.log("StartEventStatus>>>", c);

      if (
        (c && ![1, 4].includes(c.status)) ||
        (c && c.other_status && c.other_status == 2) ||
        changeEventStatus.event_info.status == 0
      ) {
        getInnerEventList();
      }
      if (c?.status == 4) {
        // setCurrentPlayer(null)
      }
      eventAction.playerListStatusUpdate({ current_contestant: c });
    });

    socket.on(SOCKET_CHANNEL.OU_BET_ENABLE, (changeEventsStatus: any) => {
      console.log(changeEventsStatus);
      //   eventAction.playerListAllDataAndStatusChangesUpdate(changeEventsStatus);
    });

    socket.on(SOCKET_CHANNEL.OU_RESET_CONTESTANT, (changeEventsStatus: any) => {
      console.log(changeEventsStatus);
      //   eventAction.playerListResetContestents(changeEventsStatus);
    });

    socket.on(SOCKET_CHANNEL.OU_EMIT_SCORE, (changeEventsStatus: any) => {
      //   // let user = changeEventsStatus.user.find((x:any)=>{
      //   //   if((userDetails && x.user_id==userDetails.id) || (guest_user_id && guest_user_id==x.guest_user_id)) {
      //   //     return x
      //   //   }
      //   // })
      //   // if(user) {
      //   //   console.log(user)
      //   //   // setWinningUsersData(user)
      //   // }

      //     if(changeEventsStatus?.mode && changeEventsStatus?.mode=="edit") {
      //         getInnerEventList()
      //         return
      //     }
      //     eventAction.playerListEmitScore(changeEventsStatus)
      //     // if(performanceInfo?.id==changeEventsStatus?.performance_id) {
      //     //     // window.location.reload()

      //     //     let c = playersList?.find((x:any)=>x.id==changeEventsStatus?.contestant_id)
      //     //     c["status"] = 2
      //     //     eventAction.playerListStatusUpdate({current_contestant:c});

      //     // }
      console.log({ changeEventsStatus });
      if (changeEventsStatus?.mode && changeEventsStatus?.mode == "edit") {
        getInnerEventList();
        return;
      }
      let user = changeEventsStatus.user.find((x: any) => {
        if ((userDetails && x.user_id == userDetails.id) || (guest_user_id && guest_user_id == x.guest_user_id)) {
          return x;
        }
      });

      if (user && user?.score_type == 2) {
        console.log(user);
        setWinningUsersData(user);
        // setShowWinningModal(true)
      }

      console.log({ playersList });

      if (playersList) {
        let player: any = playersList?.find((p: any) => p.id == changeEventsStatus.contestant_id);
        let bull: any = player.contestant_bull.bull_details;
        let x = {
          status: 2,
          other_status: 0,
        };
        console.log({ player, bull });
        if (changeEventsStatus.event_id == eventDetails.id) {
          player = { ...player, ...x, score: changeEventsStatus.score };
          bull = { ...bull, ...x, score: changeEventsStatus.bull_score };
        }
        if (user) {
          console.log({ player, bull });
          // setWinningUsersData(user)
          if ([0, 1, 2, 3, 4].includes(user.score_type)) {
            // let x = {
            //   is_user_bet: {
            //     score_type: user.score_type
            //   }
            // }
            if (player?.is_user_bet?.created_at) {
              player = { ...player, is_user_bet: { ...player.is_user_bet, score_type: user.score_type } };
            } else if (bull?.is_user_bet?.created_at) {
              bull = { ...bull, is_user_bet: { ...bull.is_user_bet, score_type: user.score_type } };
            }
            console.log({ player, bull });
            // playersEmit[findGameIndex].is_user_bet.score_type = changeEventsStatus.user[findSocketUserIndex].score_type
            if (user.score_type != 1) {
              // available_point.available_poins.point= (available_point.available_poins.point + changeEventsStatus.user[findSocketUserIndex].score)
              // earnedPoints = earnedPoints + changeEventsStatus.user[findSocketUserIndex].score
              eventAction.setAvailablePoints(Number(availablePoints) + Number(user.score));
              eventAction.setEarnedPoints(Number(earnedPoints) + Number(user.score));
            } else {
              // available_point.available_poins.point= (available_point.available_poins.point + changeEventsStatus.user[findSocketUserIndex].point)
              eventAction.setAvailablePoints(Number(availablePoints) + Number(user.point));
            }
          }
        }
        player.contestant_bull.bull_details = bull;
        let finalList: any = playersList.map((p: any) => {
          if (player.id == p.id) {
            return player;
          } else {
            return p;
          }
        });
        eventAction.setPlayersList(finalList);
        //   setCurrentPlayer(player)
        //   setCurrentBull(bull)
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [socket, playersList]);

  useEffect(() => {
    if (winningUsersData) {
      setTimeout(() => setWinningUsersData(null), 3000);
    }
  }, [winningUsersData]);

  useEffect(() => {
    if (urlParams) {
      getInnerEventList();
    }
  }, [urlParams]);

  useEffect(() => {
    return () => {
      eventAction.setEventDetails(null);
      // eventAction.setPlayersList(null)
    };
  }, []);

  return (
    <>
      <div className="lite-logo">
        <div className="row">
          <div className="col-9">
            <Sidebar page={RODEO_PAGE_NAME.CONTESTANT_LIST} />
          </div>
          <div className="col-3">
            <Header type="side" />
          </div>
        </div>
      </div>
      <div className={leaderboardStatus == "red" ? leaderboardStatus + "-leaderboard-status" : "d-none"}>
        {leaderboardStatus == "red"
          ? "Pick " + remainingBet + " more to get qualified"
          : "You have successfully chosen your pick"}
      </div>
      <div className="back-sec back-sec-two">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="back-sec-one">
                <img
                  src="/images/back.png"
                  onClick={() => {
                    history.push(URLS.RODEOS);
                    // goToEventPage();
                  }}
                />
              </div>
              <h3>{eventDetails?.event_title}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button className="leaderboard-btn" onClick={goToRodeoEventLeaderboardPage}>
          Leaderboard
        </button>
      </div>
      <div className="event-btn event-btn-span">
        <a>
          <button type="button" className="btn points-btn">
            {/* {getPerformanceInfo && ( */}
            <span>
              {/* {getPerformanceInfo && getPerformanceInfo?.wager_status == 1 ? "Rodeo is LIVE!" : "Not Started yet"}ContestantAllData */}
              {totalData?.rodeo_event_event_dtls?.status == 2
                ? "Show Ended!"
                : totalData?.rodeo_event_event_dtls?.status == 1
                ? "Show is LIVE!"
                : "Show Not Yet Started"}
            </span>
            {/* )} */}
            <span>
              <p>
                Points Available: {availablePoints ? availablePoints.toFixed(1) : "0.0"}
                {availablePoints && availablePoints < 0 ? (
                  <a
                    className="info-icon"
                    onClick={() => {
                      setShowStatusModal(true);
                      setStatusModalMesssage(negativePointInfo);
                    }}
                  >
                    <i className="fa fa-info-circle" aria-hidden="true" />
                  </a>
                ) : null}
              </p>
              <p>Points Earned: {earnedPoints?.toFixed(1) || "0.0"}</p>
            </span>
          </button>
        </a>
        {/* { Number(getPoint)<0 && */}
        {/* } */}
      </div>
      <div>
        <ul className="list-style event-list">
          {playersList?.length
            ? playersList?.map((p: any, index: any) => (
                <li key={p.id} onClick={() => goToPlayerDetailsPage(p)}>
                  <a
                    className={
                      "ripple-effect d-flex " +
                      (p.status == 1 ? "lighting-bg " : "") +
                      (p.status == 2
                        ? "ended"
                        : p.status == 4
                        ? "on-going-game"
                        : p.other_status == 2
                        ? "ended"
                        : p.status == 0 || p.status == 1 || p.other_status == 1
                        ? "not-started"
                        : "")
                    }
                  >
                    <h2>{index + 1}. </h2>
                    <em>
                      {p.status == 2
                        ? "End"
                        : p.other_status == 1
                        ? "Re-ride"
                        : p.other_status == 2
                        ? "Scratched"
                        : p.status == 4
                        ? "Locked"
                        : p.status == 0
                        ? "Not Started"
                        : p.status == 1
                        ? "Ongoing"
                        : ""}
                    </em>
                    <div className={"col-left " + (p?.is_user_bet?.point ? "already-bet" : "")}>
                      {p?.is_user_bet?.score_type == 2 && winningUsersData && (
                        <ConfettiExplosion force={0.6} duration={3000} particleCount={200} width={1000} />
                      )}
                      <span className="event-img">
                        <img
                          src={
                            p?.avatar && p?.avatar?.id == 0
                              ? p?.contestant_dtls?.avatar && p?.contestant_dtls?.avatar?.id == 0
                                ? "/images/no-image.png"
                                : p?.contestant_dtls?.avatar?.thumb
                              : p?.avatar?.thumb
                          }
                        />
                      </span>
                      <span className="event-info">
                        <span className="d-inline-block event-title">
                          {p?.contestant_name}
                          {p?.is_user_bet && p?.is_user_bet?.score_type == 1 ? (
                            <h3 className="green-col">Push</h3>
                          ) : p?.is_user_bet && p?.is_user_bet?.score_type == 2 ? (
                            <h3 className="green-col">{`Win+${p?.is_user_bet?.point}`}</h3>
                          ) : p?.is_user_bet && p?.is_user_bet?.score_type == 0 ? (
                            <h3 className="red-col">{`Loss-${p?.is_user_bet?.point}`}</h3>
                          ) : p?.is_user_bet?.point ? (
                            <h3 className="green-col">
                              {`Bet - ${p?.is_user_bet?.point}`}{" "}
                              <strong style={{ color: "#fff" }}>{p?.is_user_bet?.wager_type}</strong>
                            </h3>
                          ) : null}
                        </span>
                      </span>
                    </div>
                    <div className="col-center">
                      <span>vs</span>
                    </div>
                    <div
                      className={
                        "col-right " + (p?.contestant_bull?.bull_details?.is_user_bet?.point ? "already-bet" : "")
                      }
                    >
                      {p?.contestant_bull?.bull_details?.is_user_bet?.score_type == 2 && winningUsersData && (
                        <ConfettiExplosion force={0.6} duration={3000} particleCount={200} width={1000} />
                      )}
                      <span className="event-img">
                        <img src={p?.contestant_bull?.bull_details?.avatar?.original} />
                      </span>
                      <span className="event-info">
                        <span className="d-inline-block event-title">
                          {p?.contestant_bull?.bull_details?.bull_name}

                          {p?.contestant_bull?.bull_details?.is_user_bet &&
                          p?.contestant_bull?.bull_details?.is_user_bet?.score_type == 1 ? (
                            <h3 className="green-col">Push</h3>
                          ) : p?.contestant_bull?.bull_details?.is_user_bet &&
                            p?.contestant_bull?.bull_details?.is_user_bet?.score_type == 2 ? (
                            <h3 className="green-col">{`Win+${p?.contestant_bull?.bull_details?.is_user_bet?.point}`}</h3>
                          ) : p?.contestant_bull?.bull_details?.is_user_bet &&
                            p?.contestant_bull?.bull_details?.is_user_bet?.score_type == 0 ? (
                            <h3 className="red-col">{`Loss-${p?.contestant_bull?.bull_details?.is_user_bet?.point}`}</h3>
                          ) : p?.contestant_bull?.bull_details?.is_user_bet?.point ? (
                            <h3 className="green-col">
                              {`Bet - ${p?.contestant_bull?.bull_details?.is_user_bet?.point}`}{" "}
                              <strong style={{ color: "#fff" }}>
                                {p?.contestant_bull?.bull_details?.is_user_bet?.wager_type}
                              </strong>
                            </h3>
                          ) : null}
                        </span>
                      </span>
                    </div>
                  </a>
                </li>
              ))
            : null}
        </ul>
      </div>
      {performanceInfo?.sponsor && <Sponsor details={performanceInfo?.sponsor} />}
    </>
  );
}
