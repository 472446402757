import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { URLS, createMarkup, CRYPTO_SECRET_KEY, APPLICATION_GLOBAL_SLUG, RODEO_PAGE_NAME } from "src/_config";
import { useCmsApi } from "src/_common/hooks/actions/cms/cmsApiHook";
import { toast } from "react-toastify";
import parse from 'html-react-parser'
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Sponsor from "../common/Sponsor";

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function CmsPage() {

    const { slug } = useParams<any>()
    const cmsApi = useCmsApi();
    const [content, setContent] = useState<any>()
    const history = useHistory()

    const getCmsContentDetails = () => {
        let params = { 
            game_slug: APPLICATION_GLOBAL_SLUG,
            slug
        }
        cmsApi.callGetCmsContent(params, (message: string, resp: any) => {
            if (resp && resp.list) {
                setContent(resp.list)
            } 
        }, (message: string) => {
            // toast.error(message)
        })
    }
    
    const gotoRodeoEventsPage = () => {
        if(history.length>1) {
            history.goBack()
        } else {
            history.push(URLS.HOME)
        }
    }

    useEffect(() => {
        if(slug) {
           // console.log({slug})
            getCmsContentDetails()
        }
    }, [slug])

    return (
        <React.Fragment>
            <div className="event-sec over-hid">
                <div className="container">
                    <div className="lite-logo">
                        <div className="row">
                            <div className="col-6">
                                <Sidebar page={RODEO_PAGE_NAME.CMS} />
                            </div>
                            <div className="col-6">
                                <Header type='side' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-body">
                    <div className="back-sec back-sec-two">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            <div className="back-sec-one">
                            {/* <Link to={URLS.HOME} className="back-history"> */}
                            <a className="back-history" onClick={gotoRodeoEventsPage}>
                                <img src="/images/left-white.svg" alt="back" />
                            </a>
                            </div>
                            <h3>{content?.title || "Back"}</h3>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            {/* {content && content.long_description ? parse(content.long_description) : <p className="normal-text">No content added!</p>} */}
                            <p className="normal-text"
                                dangerouslySetInnerHTML={createMarkup(content && content.long_description ? content.long_description : 'No content added!')}
                            />
                        </div>
                    </div>
                    <Sponsor />
                </div>
            </div>
        </React.Fragment>
    )
}