import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "src/components/common/Sidebar";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import {
  useAppEarnedPointsSelector,
  useAppEventDetailsSelector,
  useAppLastBettedPlayerListSelector,
  usePlayersListSelector,
  useTotalContestentDataSelector,
} from "src/_common/hooks/selectors/eventSelector";
// import NumberPad from "src/_common/numberpad/numberPad";
import NumberPad from "src/_common/numberpad/numberPad copy";
import useSocket from "use-socket.io-client";
import {
  APP_USER_ID,
  CRYPTO_SECRET_KEY,
  GUEST_USER_ID,
  RODEO_PAGE_NAME,
  SOCKET_CHANNEL,
  SOCKET_URL,
  URLS,
} from "src/_config";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
import StatusModal from "../modal/statusModal";
import Header from "../../common/Header";
import Sponsor from "src/components/common/Sponsor";
import WiningModal from "../modal/winingModal";
import ConfettiExplosion from "react-confetti-explosion";
import GeoLocation from "src/components/common/GeoLocation";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function PlayersPage() {
  const [numbperPadShow, setNumberPadShow] = useState<boolean>(false);
  const [sendInputValue, setSendInputValue] = useState<any>([]);
  const [sendItemId, setSendItemId] = useState<any>();
  const [sendItemIndex, setSendItemIndex] = useState<any>();
  const [changeVal, setChangeVal] = useState<any>("");
  const history = useHistory();
  const location = useLocation();
  const guest_user_id = localStorage.getItem(GUEST_USER_ID);
  const eventApi = useEventApi();
  const [playersList, setPlayersList] = useState<any>();
  const [getPoint, setPoints] = useState<any>();
  const [getConstantPoint, setConstantPoint] = useState<any>();
  const [getPointFromNumpad, setPointFromNumpad] = useState<any>();
  const [getIncreaseOrDecrease, setIncreaseOrDecrease] = useState<any>();
  const [getPerformanceInfo, setPerformanceInfo] = useState<any>();
  const usePlayersListSelectors: any = usePlayersListSelector();
  const userDetails: any = useAppUserDetailsSelector();
  const eventAction = useAppEventAction();
  const ContestantAllData = useTotalContestentDataSelector();
  const [getEventTitle, setEventTitle] = useState<any>();
  const [statusModalMesssage, setStatusModalMesssage] = useState<any>();
  const [resultMesssage, setResultMesssage] = useState<any>();
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [showWinningModal, setShowWinningModal] = useState<boolean>(false);
  const [playerListApiCalled, setPlayerListApiCalled] = useState<boolean>(false);
  const [winningUsersData, setWinningUsersData] = useState<any>();
  const urlParams = useParams<any>();
  const [leaderboardStatus, setLeaderboardStatus] = useState<any>("");
  const [remainingBet, setRemainingBet] = useState<any>(0);
  const [currentPlayer, setCurrentPlayer] = useState<any>();
  // const [lastBettedPlayer, setLastBettedPlayer] = useState<any>();
  const negativePointInfo =
    "If an event’s score is corrected after picks are settled, for example due to an appeal or a data entry error, then all picks will be recalculated taking the correction into account.  In some cases, that may lead to a negative balance.  If you have a negative balance, you cannot place any additional wagers until and unless your balance becomes positive from the settlement of other picks you have already placed.";
  const earnedPoints = useAppEarnedPointsSelector();
  const [intervalId, setIntervalId] = useState<any>();
  const [sponsorDetails, setSponsorDetails] = useState<any>();
  // const [mousedownPoints, setMousedownPoints] = useState<any>(0);
  const eventDetails = useAppEventDetailsSelector();
  const lastBettedPlayerList = useAppLastBettedPlayerListSelector();

  const handleMouseDown = (item?: any, index?: any, type?: any) => {
    let c = 0;
    let tempPoints = item?.is_user_bet?.point || 0;
    setIntervalId(
      setInterval(() => {
        console.log("MouseDown", getPoint, tempPoints, c);
        c = c + 1;
        if (getPoint >= c) {
          item = upArrowClick(item, index, true);
          eventAction.setTotalDataAvailablePoints(getPoint - c);
          // setMousedownPoints(c)
        }
      }, 500)
    );
  };

  const handleMouseUp = (item?: any, index?: any) => {
    console.log("MouseUp", playersList, getPoint);
    // if(mousedownPoints!=0) {
    // setPoints(getPoint - mousedownPoints)
    // eventAction.setTotalDataAvailablePoints(getPoint - mousedownPoints)
    //   setMousedownPoints(0)
    // }
    clearInterval(intervalId);
  };

  const handleMouseOut = (item?: any, index?: any) => {
    console.log("MouseOut");
    clearInterval(intervalId);
  };

  useEffect(() => {
    console.log({ getPoint });
  }, [getPoint]);

  // when user clicked on number numberpad show
  const clickEvent = (item?: any, index?: any) => {
    if (ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point < 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("You don't have enough available points for picking this contestant.");
      return;
    }
    if (currentPlayer && item.id != currentPlayer.id) {
      setShowStatusModal(true);
      setStatusModalMesssage("Please select your pick Over/Under for " + currentPlayer.contestant_name);
      return;
    }
    // if (item?.id && getPerformanceInfo && getPerformanceInfo && getPerformanceInfo?.wager_status == 1 && item?.status==0) {
    if (
      getPerformanceInfo &&
      getPerformanceInfo?.wager_status == 1 &&
      [0, 1].includes(item?.status) &&
      ContestantAllData?.rodeo_event_event_dtls?.status != 2
    ) {
      // if(!lastBettedPlayer) {
      //   setLastBettedPlayer(item)
      // }
      setNumberPadShow(true);
      setSendInputValue(playersList);
      setSendItemId(item.id);
      setSendItemIndex(index);
    }
  };

  // number pad hide
  const onclose = (item: any) => {
    setNumberPadShow(item);
  };

  const getVal = (item: any, id: any, index: any, increaseOrDecrease: any, newAddedValues: any) => {
    setIncreaseOrDecrease(increaseOrDecrease);
    setPointFromNumpad(newAddedValues);
    if (increaseOrDecrease == "remove") {
      setPoints(newAddedValues);
      eventAction.setTotalDataAvailablePoints(newAddedValues);
    } else if (increaseOrDecrease == "add") {
      setPoints(newAddedValues);
      eventAction.setTotalDataAvailablePoints(newAddedValues);
    }
    for (let i = 0; i < playersList.length; i++) {
      if (playersList[i].id === id) {
        setCurrentPlayer(playersList[index]);
        let updatedArray: any = {
          id: id,
          is_user_bet: {
            point: item,
            wager_set_value: playersList[i]?.is_user_bet?.wager_set_value
              ? playersList[i]?.is_user_bet?.wager_set_value
              : playersList[i]?.set_value?.set_value || 0,
          },
          avatar: playersList[i].avatar,
          contestant_name: playersList[i].contestant_name,
          contestant_dtls: playersList[i].contestant_dtls,
          set_value: playersList[i].set_value,
          score: playersList[i].score,
          performance_id: playersList[i].performance_id,
          rodeo_event_event_id: playersList[i].rodeo_event_event_id,
          rodeo_event_id: playersList[i].rodeo_event_id,
          status: playersList[i].status,
          other_status: playersList[i].other_status,
        };
        playersList.splice(index, 1);
        playersList.splice(index, 0, updatedArray);
        // setPlayersList(playersList);
        eventAction.setPlayersList(playersList);
      }
    }
  };

  const upArrowClick = (item?: any, index?: any, mousedown?: boolean) => {
    // console.log(item)
    // if(getPerformanceInfo && getPerformanceInfo?.wager_status == 1 && item?.status==0 && !item?.is_user_bet?.id &&ContestantAllData?.rodeo_event_event_dtls?.status!=2)
    if (ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point < 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("You don't have enough available points for picking this contestant.");
      return;
    }
    if (currentPlayer && item.id != currentPlayer.id) {
      setShowStatusModal(true);
      setStatusModalMesssage("Please select your pick Over/Under for " + currentPlayer.contestant_name);
      return;
    }
    if (
      getPerformanceInfo &&
      getPerformanceInfo?.wager_status == 1 &&
      [0, 1].includes(item?.status) &&
      ContestantAllData?.rodeo_event_event_dtls?.status != 2
    ) {
      // if(!lastBettedPlayer) {
      //   setLastBettedPlayer(item)
      // }
      setCurrentPlayer(item);
      // if (getPoint && getPoint != 0) {
      if (getPoint && getPoint > 0.9) {
        for (let i = 0; i < playersList.length; i++) {
          if (playersList[i].id === item?.id) {
            let numbers = Number(item?.is_user_bet?.point ? item?.is_user_bet?.point : 0);
            let AddingNumber = numbers + 1;
            let updatedArray: any = {
              id: item?.id,
              is_user_bet: {
                point: AddingNumber,
                wager_set_value: playersList[i]?.is_user_bet?.wager_set_value
                  ? playersList[i]?.is_user_bet?.wager_set_value
                  : playersList[i]?.set_value?.set_value || 0,
                created_at: null,
              },
              avatar: playersList[i].avatar,
              contestant_name: playersList[i].contestant_name,
              contestant_dtls: playersList[i].contestant_dtls,
              set_value: playersList[i].set_value,
              score: playersList[i].score,
              performance_id: playersList[i].performance_id,
              rodeo_event_event_id: playersList[i].rodeo_event_event_id,
              rodeo_event_id: playersList[i].rodeo_event_id,
              status: playersList[i].status,
              other_status: playersList[i].other_status,
            };

            playersList.splice(index, 1);
            playersList.splice(index, 0, updatedArray);
            eventAction.setPlayersList(playersList);
            setChangeVal(item);
            if (AddingNumber && !mousedown) {
              // if (AddingNumber) {
              console.log(getPoint);
              setPoints(getPoint - 1);
              eventAction.setTotalDataAvailablePoints(getPoint - 1);
              // ContestantAllData.available_poins.point=getPoint - 1
            }
            return updatedArray;
          }
        }
      }
    }
  };

  const downArrowClick = (item?: any, index?: any) => {
    // if(getPerformanceInfo && getPerformanceInfo?.wager_status == 1 && item?.status==0 && !item?.is_user_bet?.id &&ContestantAllData?.rodeo_event_event_dtls?.status!=2)
    if (ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point < 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("You don't have enough available points for picking this contestant.");
      return;
    }
    if (currentPlayer && item.id != currentPlayer.id) {
      setShowStatusModal(true);
      setStatusModalMesssage("Please select your pick Over/Under for " + currentPlayer.contestant_name);
      return;
    }
    if (
      getPerformanceInfo &&
      getPerformanceInfo?.wager_status == 1 &&
      [0, 1].includes(item?.status) &&
      ContestantAllData?.rodeo_event_event_dtls?.status != 2
    ) {
      // if(!lastBettedPlayer) {
      //   setLastBettedPlayer(item)
      // }
      // if (item?.is_user_bet && item?.is_user_bet?.point != "0") {
      if (item?.is_user_bet && item?.is_user_bet?.point != "0") {
        for (let i = 0; i < playersList.length; i++) {
          if (playersList[i].id === item?.id) {
            let numbers = Number(item?.is_user_bet?.point ? item?.is_user_bet?.point : 0);
            let AddingNumber = numbers - 1;
            // if(AddingNumber!=0) {
            setCurrentPlayer(item);
            // } else {
            //   setCurrentPlayer(null)
            // }
            let updatedArray: any = {
              id: item?.id,
              is_user_bet: {
                point: AddingNumber,
                wager_set_value: playersList[i]?.is_user_bet?.wager_set_value
                  ? playersList[i]?.is_user_bet?.wager_set_value
                  : playersList[i]?.set_value?.set_value || 0,
                created_at: null,
              },
              avatar: playersList[i].avatar,
              contestant_name: playersList[i].contestant_name,
              contestant_dtls: playersList[i].contestant_dtls,
              set_value: playersList[i].set_value,
              score: playersList[i].score,
              performance_id: playersList[i].performance_id,
              rodeo_event_event_id: playersList[i].rodeo_event_event_id,
              rodeo_event_id: playersList[i].rodeo_event_id,
              status: playersList[i].status,
              other_status: playersList[i].other_status,
            };
            playersList.splice(index, 1); // 2nd parameter means remove one item only
            playersList.splice(index, 0, updatedArray);
            eventAction.setPlayersList(playersList);
            setChangeVal(item);
            // if(AddingNumber){
            setPoints(getPoint + 1);
            eventAction.setTotalDataAvailablePoints(getPoint + 1);
            // ContestantAllData.available_poins.point=getPoint + 1
            // }
          }
        }
      }
    }
  };

  const onOverUnderClick = (item: any, wager_type: any) => {
    // if(getPerformanceInfo && getPerformanceInfo?.wager_status == 1 && item?.status==0 && !item?.is_user_bet?.id &&ContestantAllData?.rodeo_event_event_dtls?.status!=2)
    //console.log(item)
    // if(ContestantAllData && ContestantAllData?.available_poins && (ContestantAllData?.available_poins?.point+item?.is_user_bet?.point) < 0) {
    if (ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point < 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("You don't have enough available points for picking this contestant.");
      return;
    }
    if (currentPlayer && item.id != currentPlayer.id) {
      setShowStatusModal(true);
      setStatusModalMesssage("Please select your pick Over/Under for " + currentPlayer.contestant_name);
      return;
    }
    if (ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point < 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("You don't have enough available points for picking this contestant.");
      return;
    }
    if (
      getPerformanceInfo &&
      getPerformanceInfo?.wager_status == 1 &&
      [0, 1].includes(item?.status) &&
      ContestantAllData?.rodeo_event_event_dtls?.status != 2
    ) {
      var parms: any = {
        rodeo_event_id: item?.rodeo_event_id,
        performance_id: item?.performance_id,
        wager_type: wager_type,
        event_id: item?.rodeo_event_event_id,
        contestant_id: item?.id,
        bull_id: null,
        point: item?.is_user_bet && item?.is_user_bet?.point ? item?.is_user_bet?.point : 0,
        wager_set_value: item?.set_value?.set_value,
      };

      if (guest_user_id && !userDetails) {
        parms["guest_user_id"] = guest_user_id;
      }
      let geoId = localStorage.getItem("geolocation_val");
      if (geoId) {
        parms["location_id"] = geoId;
      } else {
        parms["location_id"] = null;
      }
      let lat = localStorage.getItem("latitude");
      let lng = localStorage.getItem("longitude");
      parms["lat"] = lat;
      parms["lng"] = lng;

      if (parms?.point != 0) {
        eventApi.callSubmitPlayersBettingPoints(
          parms,
          (message: string, resp: any) => {
            if (resp) {
              setCurrentPlayer(null);
              // console.log({resp})
              if (resp?.user_wager?.created_at) {
                // console.log({item})
                let newBetRemaining = remainingBet - 1;
                // console.log({newBetRemaining})
                setRemainingBet(newBetRemaining);
                if (newBetRemaining == 0) {
                  setLeaderboardStatus("green");
                } else if (newBetRemaining > 0) {
                  setLeaderboardStatus("red");
                }
              }

              toast.success(message);
              if (
                ContestantAllData &&
                ContestantAllData?.available_poins &&
                ContestantAllData?.available_poins?.point
              ) {
                ContestantAllData.available_poins.point = resp?.available_points?.point;
                // console.log(ContestantAllData.available_poins.point)
              }
              // setResultMesssage("Locked")
              var findGameIndex =
                playersList && playersList.length && playersList.findIndex((x: any) => x.id === item?.id);
              setChangeVal("");
              if (findGameIndex >= 0) {
                let today = new Date();
                playersList[findGameIndex].is_user_bet = {
                  point: parms?.point,
                  created_at: today,
                  wager_set_value: item?.set_value?.set_value,
                  wager_type: wager_type,
                };
                console.log(playersList[findGameIndex]);
                // setLastBettedPlayer(playersList[findGameIndex])
                eventAction.setLastBettedPlayer(playersList[findGameIndex]);
                setChangeVal(item);
              }
            } else {
              toast.error(message);
            }
          },
          (message: string) => {
            toast.error(message);
          }
        );
      } else {
        toast.error("Enter your pick first!");
      }
    }
  };

  useEffect(() => {
    render();
  }, [changeVal]);

  useEffect(() => {
    // let loc: any = location;
    // if(loc) {
    //   getInnerEventList();
    // }
  }, [location]);

  const getInnerEventList = () => {
    eventAction.setPlayersList(null);

    // console.log({currentPlayer})
    let loc: any = location;

    const params: any = {
      // rodeo_event_id: loc?.state?.rodeo_event_id,
      // performance_id: loc?.state?.performance_id,
      // inner_event_id: loc?.state?.id,
      rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId),
      performance_id: cryptr.decrypt(urlParams.performanceId),
      inner_event_id: cryptr.decrypt(urlParams.eventId),
    };

    if (userDetails && !guest_user_id) {
      localStorage.setItem(APP_USER_ID, userDetails?.id);
    }

    if (guest_user_id && !userDetails) {
      params["guest_user_id"] = guest_user_id;
    }
    // console.log(params)
    eventApi.callGetPlayersList(
      params,
      (message: string, resp: any) => {
        setCurrentPlayer(null);

        if (resp?.rodeo_event_event_dtls?.sponsor_details?.sponsor) {
          setSponsorDetails(resp.rodeo_event_event_dtls.sponsor_details.sponsor);
        }
        if (resp.min_bet_leaderboard != 0) {
          if (resp.user_remaining_bet == 0) {
            setLeaderboardStatus("green");
          } else if (resp.user_remaining_bet > 0) {
            setLeaderboardStatus("red");
            setRemainingBet(resp.user_remaining_bet);
          }
        } else {
        }
        if (resp && resp.list) {
          // setPoints(
          //   resp?.available_poins && resp?.available_poins?.point
          //     ? resp?.available_poins?.point
          //     : null
          // );

          // setPoints(
          //   ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point
          //       ? ContestantAllData?.available_poins?.point
          //       : null
          //   );

          setConstantPoint(resp?.available_poins && resp?.available_poins?.point ? resp?.available_poins?.point : 0);
          setEventTitle(resp?.rodeo_event_event_dtls?.event_title);
        } else {
          eventAction.setPlayersList("");
        }
        setPlayerListApiCalled(true);
      },
      (message: string) => {
        // toast.error(message);
      }
    );
  };

  useEffect(() => {
    if (urlParams) {
      getInnerEventList();
    }
  }, [urlParams]);

  // useEffect(() => {
  //   if(currentPlayer && playersList) {
  //     setTimeout(()=>{
  //       let x = playersList.map((y:any)=>{
  //           if(x.id==currentPlayer.id) {
  //             y=currentPlayer
  //           }
  //           return y
  //         }
  //       )
  //       setPlayersList(x)
  //     },1500)
  //   }
  // }, [currentPlayer, playersList]);

  useEffect(() => {
    setPlayersList(usePlayersListSelectors);
  }, [usePlayersListSelectors]);

  useEffect(() => {
    console.log({ ContestantAllData });
    setPerformanceInfo(ContestantAllData?.performance_info);
    // if(!getPoint) {
    setPoints(
      ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point
        ? ContestantAllData?.available_poins?.point
        : 0
    );
    // }
  }, [ContestantAllData]);

  const onchngFunc = (item: any) => {
    setChangeVal(item);
  };

  const [socket] = useSocket(SOCKET_URL, {
    autoConnect: false,
  });

  useEffect(() => {
    socket.connect();

    //Status Change Socket
    socket.on(SOCKET_CHANNEL.OU_EVENT_DETAILS, (changeEventStatus: any) => {
      //  console.log(changeEventStatus)
      eventAction.playerListStatusUpdate(changeEventStatus);
    });

    socket.on(SOCKET_CHANNEL.START_EVENT, (changeEventStatus: any) => {
      console.log("StartEvent>>>", changeEventStatus);
      setNumberPadShow(false);
      if (changeEventStatus?.event_info?.status == 0) {
        window.location.reload();
      }

      eventAction.pushEventStatusToTotalData(changeEventStatus.event_info);

      let c = changeEventStatus?.contestants?.find((x: any) => x.id == changeEventStatus?.contestant_id);
      // if(currentPlayer?.id==changeEventStatus?.contestant_id) {
      // let p = changeEventStatus.find((x:any)=>x.id==changeEventStatus?.contestant_id)
      //   // p.
      //   let x = getPoint
      //   console.log(p,x)
      // }
      console.log("currentPlayer>>>>>>>>>>>>>>", c);
      if ((c && ![1, 4].includes(c.status)) || (c && c.other_status && c.other_status == 2)) {
        getInnerEventList();
      }
      if (c?.status == 4) {
        // setCurrentPlayer(null)
      }
      if (c) {
        eventAction.playerListStatusUpdate({ current_contestant: c });
      }
      // eventAction.playerListStatusUpdate(changeEventStatus);
      // eventAction.playerListAllDataAndStatusChangesUpdate(changeEventStatus);
    });

    socket.on(SOCKET_CHANNEL.OU_BET_ENABLE, (changeEventsStatus: any) => {
      // console.log(changeEventsStatus)
      eventAction.playerListAllDataAndStatusChangesUpdate(changeEventsStatus);
    });

    socket.on(SOCKET_CHANNEL.OU_RESET_CONTESTANT, (changeEventsStatus: any) => {
      // console.log(changeEventsStatus)
      // getInnerEventList()
      eventAction.playerListResetContestents(changeEventsStatus);
    });

    socket.on(SOCKET_CHANNEL.OU_EMIT_SCORE, (changeEventsStatus: any) => {
      console.log(changeEventsStatus);
      // let prevScore = ContestantAllData?.list?.find((x:any)=>x.id==changeEventsStatus.contestant_id)?.score
      // console.log(prevScore,prevScore==0)
      let user = changeEventsStatus.user.find((x: any) => {
        if ((userDetails && x.user_id == userDetails.id) || (guest_user_id && guest_user_id == x.guest_user_id)) {
          return x;
        }
      });

      if (changeEventsStatus?.mode && changeEventsStatus?.mode == "edit") {
        // console.log("Edit mode")
        getInnerEventList();
        return;
      }
      if (user && user?.score_type == 2) {
        console.log(user);
        setWinningUsersData(user);
        // setShowWinningModal(true)
      }
      // if(user) {
      eventAction.playerListEmitScore(changeEventsStatus);
      // }
      // if(prevScore==0 && changeEventsStatus?.score!=prevScore) {
      //   console.log("inside If PrevScore0")
      //   getInnerEventList()
      //   return
      // }
      // if( changeEventsStatus?.score!=prevScore ) {
      //   console.log("inside If PrevScore")
      //   getInnerEventList()
      //   return
      // }
      // let x = ContestantAllData
      // let c = x?.list?.findIndex((i:any)=>i.id==changeEventsStatus.contestant_id)
      // x.list[c].status = 2
      // console.log(x)
      // eventAction.playerListStatusUpdate({contestants: x.list});
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (currentPlayer && playersList && ContestantAllData?.available_poins?.point >= 0) {
      let p = playersList.find((x: any) => x.id == currentPlayer.id);
      console.log({ currentPlayer, playersList, p });
      if (p?.status == 4 && p?.is_user_bet?.point >= 0) {
        let player = lastBettedPlayerList.find((pl: any) => pl.id == p.id);
        console.log({ p, player });
        // if(p?.id==lastBettedPlayer?.id) {
        if (player) {
          console.log("InsideIf");
          eventAction.setTotalDataAvailablePoints(
            Number(ContestantAllData?.available_poins?.point) +
              Number(p?.is_user_bet?.point) -
              Number(player?.is_user_bet?.point || 0)
          );
          p.is_user_bet = player.is_user_bet;
        } else {
          console.log("InsideElse");
          eventAction.setTotalDataAvailablePoints(
            Number(ContestantAllData?.available_poins?.point) + Number(p?.is_user_bet?.point)
          );
          p.is_user_bet = null;
        }
        let x = playersList.map((y: any) => {
          if (y.id == p.id) {
            return p;
          } else {
            return y;
          }
        });
        console.log(">>>>", { x });
        eventAction.setPlayersList(x);
        setCurrentPlayer(null);
      }
    }
  }, [playersList, currentPlayer, ContestantAllData, lastBettedPlayerList]);

  useEffect(() => {
    if (winningUsersData) {
      setTimeout(() => setWinningUsersData(null), 3000);
    }
  }, [winningUsersData]);

  useEffect(() => {
    console.log({ lastBettedPlayerList });
  }, [lastBettedPlayerList]);

  useEffect(() => {
    return () => {
      eventAction.setEventDetails(null);
      // eventAction.setPlayersList(null)
    };
  }, []);

  const render = () => {
    return (
      <>
        {playersList &&
          playersList?.length &&
          playersList?.map((item: any, index: any) => (
            <div
              className={`contestants-body ${item.status == 1 ? " lighting-bg" : ""} ${
                getPerformanceInfo && getPerformanceInfo?.wager_status == 0
                  ? // ? "disables-button"
                    ""
                  : ""
              }`}
              key={index}
            >
              {item?.is_user_bet?.score_type == 2 && winningUsersData && (
                <ConfettiExplosion force={0.6} duration={3000} particleCount={200} width={1000} />
              )}
              {/* <Link to={URLS.EVENT.PLAYER_DETAILS} className="back-history"> */}
              <h2
                onClick={() => {
                  goToPlayerDetailsPage(item);
                }}
              >
                {index + 1}. {item?.contestant_name}
              </h2>
              {
                // typeSelected=="bull" && (performanceInfo && performanceInfo?.wager_status == 1 && [0,1].includes(currentBull?.status) && eventDetails?.status!=2) &&
                !([2, 3, 4].includes(item?.status) || item?.other_status == 2) &&
                  (currentPlayer?.id == item?.id || item?.is_user_bet) && (
                    <div className="reset-btn" onClick={() => resetBet(item)}>
                      RESET
                    </div>
                  )
              }
              {/* </Link> */}
              <div className="row">
                <div className="col-3">
                  <div className="contestants-img">
                    <img
                      src={
                        item?.avatar && item?.avatar?.id == 0
                          ? item?.contestant_dtls?.avatar && item?.contestant_dtls?.avatar?.id == 0
                            ? "/images/no-image.png"
                            : item?.contestant_dtls?.avatar?.thumb
                          : item?.avatar?.thumb
                      }
                      alt="#"
                      onClick={() => {
                        goToPlayerDetailsPage(item);
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`col-9 ${
                    item && item?.status == 0 && getPerformanceInfo?.wager_status == 1 && item?.other_status == 0
                      ? ""
                      : //: "disables-button"
                        ""
                  }`}
                  onClick={() => onContestantClick(item)}
                >
                  <div
                    className={`contestants-body-in ${
                      // ((item && item?.is_user_bet && item?.is_user_bet?.created_at)  || (item?.status == 1 || item?.status == 3 || item?.status == 2  || item?.other_status == 2))
                      // ((item?.status == 4 || item?.status == 3 || item?.status == 2  || item?.other_status == 2))
                      [2, 3, 4].includes(item?.status) || item?.other_status == 2 ? "disables-button" : ""
                    }`}
                  >
                    <div className="row">
                      <div className="col-3">
                        <div className="contestants-body-in-one">
                          <span>Wager</span>
                          <button
                            type="button"
                            // className="btn active"
                            className={`btn ${item && item?.is_user_bet && item?.is_user_bet?.created_at ? "" : ""}`}
                            onClick={() => upArrowClick(item, index)}
                            // onMouseDown={()=>handleMouseDown(item,index,"mouse")}
                            // onMouseUp={()=>handleMouseUp(item,index)}
                            // onMouseLeave={()=>handleMouseUp(item,index)}
                            onPointerDown={() => handleMouseDown(item, index, "pointer")}
                            onPointerUp={() => handleMouseUp(item, index)}
                            onPointerLeave={() => handleMouseUp(item, index)}
                            onPointerCancel={() => handleMouseUp(item, index)}
                            // onTouchStart={()=>handleMouseDown(item,index,"touch")}
                            // onTouchEnd={()=>handleMouseUp(item,index)}
                            // onTouchCancel={()=>handleMouseUp(item,index)}
                            // onTouchMove={()=>handleMouseUp(item,index)}
                          >
                            {/* {intervalId && currentPlayer?.id==item.id ? <div style={{height:"15px"}}/> : <img src="/images/up.png" />} */}
                            {/* <img src="/images/up.png" /> */}
                            <i className="fa-solid fa-chevron-up" style={{ fontSize: "12px" }} />
                          </button>
                          <h3
                            onClick={() => {
                              clickEvent(item, index);
                            }}
                          >
                            {/* {item?.number ? Number(item?.number).toFixed(2) : 0} */}
                            {item?.is_user_bet && item?.is_user_bet?.point ? item?.is_user_bet?.point : 0}
                          </h3>
                          <button
                            type="button"
                            className={`btn ${item && item?.is_user_bet && item?.is_user_bet?.created_at ? "" : ""}`}
                            onClick={() => downArrowClick(item, index)}
                            disabled={!item?.is_user_bet || (item?.is_user_bet && item?.is_user_bet?.point == 0)}
                          >
                            {/* <img src="/images/down.png" alt="#" /> */}
                            <i className="fa-solid fa-chevron-down" style={{ fontSize: "12px" }} />
                          </button>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="contestants-body-in-two">
                          <span>Set</span>
                          <button
                            type="button"
                            // className="btn active"
                            className={`btn ${
                              item &&
                              item?.is_user_bet &&
                              item?.is_user_bet?.created_at &&
                              item?.is_user_bet?.wager_type == "O"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              onOverUnderClick(item, "O");
                            }}
                          >
                            Over
                          </button>
                          <h3>
                            {item?.is_user_bet?.wager_set_value
                              ? item?.is_user_bet?.wager_set_value
                              : item?.set_value?.set_value || 0}
                          </h3>
                          <button
                            type="button"
                            className={`btn ${
                              item &&
                              item?.is_user_bet &&
                              item?.is_user_bet?.created_at &&
                              item?.is_user_bet?.wager_type == "U"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              onOverUnderClick(item, "U");
                            }}
                          >
                            Under
                          </button>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="contestants-body-in-three">
                          <span>Actual Score</span>
                          <h4>{item && item.score != null ? item.score : "- -"}</h4>
                          <div className="status-bt">
                            <span>Status / Result</span>
                            {/* {(item?.status == 1 || item?.status == 3) ? <h3>Locked</h3> : item?.other_status == 2 ? <h3 className="green-col">Scratched</h3> : item?.other_status == 1 ? <h3 className="green-col">Re-ride</h3> :(item?.is_user_bet && item?.is_user_bet?.score_type == 1 ? <h3 className="green-col">Push</h3> :  item?.is_user_bet && item?.is_user_bet?.score_type==2 ? <h3 className="green-col">{`Win+${item?.is_user_bet?.point}`}</h3> : item?.is_user_bet && item?.is_user_bet?.score_type==0 ? <h3 className="red-col">{`Loss-${item?.is_user_bet?.point}`}</h3> :(item?.is_user_bet && item?.is_user_bet?.created_at || item?.status == 2) ? <h3>Locked</h3> : <h4>- -</h4>)} */}
                            {item?.status == 4 || item?.status == 3 ? (
                              <h3>Locked</h3>
                            ) : item?.other_status == 2 ? (
                              <h3 className="green-col">Scratched</h3>
                            ) : item?.other_status == 1 ? (
                              <h3 className="green-col">Re-ride</h3>
                            ) : item?.is_user_bet && item?.is_user_bet?.score_type == 1 ? (
                              <h3 className="green-col">Push</h3>
                            ) : item?.is_user_bet && item?.is_user_bet?.score_type == 2 ? (
                              <h3 className="green-col">{`Win+${item?.is_user_bet?.point}`}</h3>
                            ) : item?.is_user_bet && item?.is_user_bet?.score_type == 0 ? (
                              <h3 className="red-col">{`Loss-${item?.is_user_bet?.point}`}</h3>
                            ) : item?.status == 2 ? (
                              <h3>Locked</h3>
                            ) : (
                              <h4>- -</h4>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </>
    );
  };

  // open korte hobe close

  // const goToEventPage = (item?: any, index?: any) => {
  //   let loc: any = location;
  //   history.push({
  //     pathname: '/events',
  //     state: {
  //       id: loc?.state?.performance_id,
  //       rodeo_event_id: loc?.state?.rodeo_event_id,
  //     },
  //   });
  // };

  const goToEventPage = () => {
    history.push(`/events/${urlParams.rodeoEventId}/${urlParams.performanceId}`);
  };

  const goToPlayerDetailsPage = (p: any) => {
    history.push(
      `/player-details/${urlParams.rodeoEventId}/${urlParams.performanceId}/${urlParams.eventId}/${cryptr.encrypt(
        p.id
      )}`
    );
  };

  // const goToPlayerDetailsPage = (item?: any, index?: any) => {
  //   let loc: any = location;
  //   history.push({
  //     pathname: '/player-details',
  //     state: { id: loc?.state?.id, performance_id:loc?.state?.performance_id, rodeo_event_id: loc?.state?.rodeo_event_id, player_id:item?.id },
  //   });
  // };

  const goToLeaderboardPage = () => {
    // console.log(location)
    let loc: any = location;
    history.push({
      pathname: "/leaderboard",
      state: {
        rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId),
        performance_id: cryptr.decrypt(urlParams.performanceId),
        event_id: cryptr.decrypt(urlParams.eventId),
      },
    });
  };

  const resetBet = (item: any) => {
    let params: any = {
      rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId),
      performance_id: cryptr.decrypt(urlParams.performanceId),
      event_id: cryptr.decrypt(urlParams.eventId),
      contestant_id: item.id,
      bull_id: null,
    };
    if (userDetails && !guest_user_id) {
      localStorage.setItem(APP_USER_ID, userDetails?.id);
    }
    if (guest_user_id && !userDetails) {
      params["guest_user_id"] = guest_user_id;
    }
    eventApi.callResetBet(
      params,
      (message: any, resp: any) => {
        console.log({ resp });
        setPoints(resp.available_points.point);
        eventAction.setTotalDataAvailablePoints(resp.available_points.point);
        setCurrentPlayer(null);
        let x = playersList.map((p: any) => {
          if (p.id == item.id) {
            p["is_user_bet"] = null;
          }
          return p;
        });
        eventAction.setPlayersList(x);
        // eventAction.setAvailablePoints(resp.available_points.point)
        // setCurrentPlayer({...currentPlayer,is_user_bet: null})
      },
      (message: any) => {}
    );
  };

  // number pad hide
  const onNumpadClose = (val: any) => {
    setNumberPadShow(false);
    // if(val) {
    updateBetPoint(val);
    // }
  };

  const updateBetPoint = (val: any) => {
    // let item:any = currentPlayer
    let item: any = playersList[sendItemIndex];
    let updatedArray: any = {
      ...item,
      is_user_bet: {
        point: val,
        wager_set_value: item?.is_user_bet?.wager_set_value
          ? item?.is_user_bet?.wager_set_value
          : item?.set_value?.set_value || 0,
        created_at: null,
      },
    };
    for (let i = 0; i < playersList.length; i++) {
      // if (playersList[i].id === item.id) {
      if (playersList[i].id === sendItemId) {
        playersList.splice(i, 1); // 2nd parameter means remove one item only
        playersList.splice(i, 0, updatedArray);
        setCurrentPlayer(updatedArray);
        // setCurrentIndex(i)
        // setPlayersList(playersList);
        eventAction.setPlayersList(playersList);
      }
    }
    // setCurrentPlayer(updatedArray)
  };

  const setFinalPoints = (val: any) => {
    eventAction.setTotalDataAvailablePoints(val);
    setPoints(val);
  };

  const onContestantClick = (item: any) => {
    //console.log(item)
    if (getPerformanceInfo && getPerformanceInfo && getPerformanceInfo?.wager_status == 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("Picks are not open yet");
    } else if (ContestantAllData && ContestantAllData?.rodeo_event_event_dtls?.status == 2) {
      setShowStatusModal(true);
      setStatusModalMesssage("Picking has ended.");
    } else {
      // if(item && item?.is_user_bet && item?.is_user_bet?.created_at)
      // {
      //   setShowStatusModal(true)
      //   setStatusModalMesssage("You already placed bet for this contestant.")
      // }
      // else if(item && item?.status == 4)
      if (item && item?.status == 4) {
        setShowStatusModal(true);
        setStatusModalMesssage("Performance of this contestant already started, wait for the result.");
      } else if (item && item?.status == 2) {
        setShowStatusModal(true);
        setStatusModalMesssage("Contestant has completed his ride.");
      } else if (item && item?.other_status == 2) {
        setShowStatusModal(true);
        setStatusModalMesssage("Contestant has been scratched");
      }
    }
  };

  const onStatusModalClose = () => {
    setShowStatusModal(false);
    setStatusModalMesssage("");
  };

  const goToRodeoEventLeaderboardPage = () => {
    let loc: any = location;
    history.push({
      pathname: "/leaderboard",
      state: { rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId) },
    });
  };

  // const onStatusModalSuccess = () => {
  //   setShowStatusModal(false)
  //   setStatusModalMesssage("")
  // }

  // useEffect(()=>{
  //   console.log({lastBettedPlayer,playersList})
  //   if(lastBettedPlayer && playersList) {
  //     console.log("2222222222")
  //     var index = playersList && playersList.length && playersList.findIndex(
  //       (x: any) => x.id === lastBettedPlayer?.id
  //     );
  //     if(index && playersList[index].status==4) {
  //       console.log("333333333")
  //       let x = {...lastBettedPlayer, status:4}
  //       playersList.splice(index, 1);
  //       playersList.splice(index, 0, x);
  //       console.log({playersList})
  //       setPlayersList(playersList)
  //       eventAction.setPlayersList(playersList)
  //     }
  //   }
  // },[playersList, lastBettedPlayer])

  // useEffect(()=>{
  //     window.scrollTo(0, 1000)
  // },[])

  return (
    <React.Fragment>
      <GeoLocation />
      <div className="lite-logo">
        <div className="row">
          <div className="col-9">
            <Sidebar page={RODEO_PAGE_NAME.CONTESTANT_LIST} />
          </div>
          <div className="col-3">
            <Header type="side" />
          </div>
        </div>
      </div>
      <div className={leaderboardStatus == "red" ? leaderboardStatus + "-leaderboard-status" : "d-none"}>
        {leaderboardStatus == "red"
          ? "Pick " + remainingBet + " more to get qualified"
          : "You have successfully chosen your pick"}
      </div>
      {/* <div className="main-body"> */}
      <div className="back-sec back-sec-two">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="back-sec-one">
                <img
                  src="/images/back.png"
                  alt="#"
                  onClick={() => {
                    history.push(URLS.RODEOS);
                    // goToEventPage();
                  }}
                />
              </div>
              <h3>{eventDetails?.event_title}</h3>
            </div>
            {/* <div className="col-6">
                <div className="back-sec-two">
                  <h4 onClick={()=>goToLeaderboardPage()}>Leader Board</h4>
                </div>
              </div>
              */}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button className="leaderboard-btn" onClick={goToRodeoEventLeaderboardPage}>
          Leaderboard
        </button>
      </div>
      {/* <div className="container"> */}
      <div className="event-btn event-btn-span">
        <a>
          <button type="button" className="btn points-btn">
            {getPerformanceInfo && (
              <span>
                {/* {getPerformanceInfo && getPerformanceInfo?.wager_status == 1 ? "Rodeo is LIVE!" : "Not Started yet"}ContestantAllData */}
                {ContestantAllData?.rodeo_event_event_dtls?.status == 2
                  ? "Show Ended!"
                  : ContestantAllData?.rodeo_event_event_dtls?.status == 1
                  ? "Show is LIVE!"
                  : "Show Not Yet Started"}
              </span>
            )}
            <span>
              <p>
                Points Available: {getPoint ? getPoint.toFixed(1) : "0.0"}
                {getPoint && getPoint < 0 ? (
                  <a
                    className="info-icon"
                    onClick={() => {
                      setShowStatusModal(true);
                      setStatusModalMesssage(negativePointInfo);
                    }}
                  >
                    <i className="fa fa-info-circle" aria-hidden="true" />
                  </a>
                ) : null}
              </p>
              <p>Points Earned: {earnedPoints?.toFixed(1) || "0.0"}</p>
            </span>
          </button>
        </a>
        {/* { Number(getPoint)<0 && */}
        {/* } */}
      </div>

      <div className={"contestants " + (numbperPadShow ? "pb-221" : "")} style={{ padding: "15px" }}>
        {render()}
        {/* {numbperPadShow && (
              <NumberPad
                numbperPadShow={numbperPadShow}
                handleClose={onclose}
                getValue={getVal}
                sendInputValue={sendInputValue}
                sendItemId={sendItemId}
                sendItemIndex={sendItemIndex}
                getPoint={getPoint}
                onchngFunc={onchngFunc}
              />
            )} */}
        {numbperPadShow && getPoint >= 0 && playerListApiCalled && (
          <NumberPad
            show={numbperPadShow}
            onClose={onNumpadClose}
            existingBetPoints={playersList[sendItemIndex]?.is_user_bet?.point || 0}
            setFinalPoints={setFinalPoints}
            totalAvailablePoints={getPoint}
          />
        )}
      </div>
      {/* </div> */}
      {/* </div> */}
      {showStatusModal && statusModalMesssage && (
        <StatusModal shouldShow={showStatusModal} onClose={onStatusModalClose} message={statusModalMesssage} type={1} />
      )}
      {sponsorDetails && <Sponsor details={sponsorDetails} />}
      {showWinningModal && winningUsersData && (
        <WiningModal shouldShow={showWinningModal} onClose={() => setShowWinningModal(false)} data={winningUsersData} />
      )}
    </React.Fragment>
  );
}
