import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Offcanvas, Button } from "react-bootstrap";

interface NumberPadProps {
  show: boolean;
  onClose: Function;
  existingBetPoints: string;
  totalAvailablePoints: string;
  setFinalPoints: any;
}

export default function NumberPad ( { show, onClose, existingBetPoints, totalAvailablePoints, setFinalPoints }: NumberPadProps ) {
  
  const numberVal = Array.from(Array(10).keys())
  const [value, setValue] = useState<any>("")
  const [tempPoints, setTempPoints] = useState<any>()

  const appendVal = (item:any) => {
    if(Number(value+item)<=Number(tempPoints)) {
      setValue(value+item)
      setFinalPoints(Number(tempPoints)-Number(value+item))
    }
  }

  const removeLastVal = () => {
    let x = value.substring(0,value.length-1)
    setValue(x)
    if(x) {
      setFinalPoints(Number(tempPoints)-Number(x))
    } else {
      setFinalPoints(Number(tempPoints))
    }
  }

  // useEffect(()=>{
  //   if(existingBetPoints) {
  //     setValue(String(existingBetPoints))
  //   }
  // },[existingBetPoints])

  useEffect(()=>{
    if(existingBetPoints) {
      setValue(String(existingBetPoints))
      setTempPoints(Number(totalAvailablePoints)+Number(existingBetPoints))
    } else {
      setTempPoints(totalAvailablePoints)
    }
  },[])

  return (
    <React.Fragment>
      <>
        <Offcanvas
          show={show}
          onHide={()=>onClose(value)}
          placement={"bottom"}
          backdrop={false}
          onBlur={()=>onClose(value)}
        >
          <Offcanvas.Header closeButton className="canvusHeaderClr">
            <Offcanvas.Title>{value}</Offcanvas.Title>
            <div className="next-btn-inner bt-pos-fixed">
              <button type="button" className="btn" onClick={()=>onClose(value)}>
                Done
              </button>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className="backgroundclr">
            <div id="pad">
              <div id="numpad">
                { numberVal && numberVal.length && numberVal.map( (item: any) => 
                  <div className="button" id="number-field" key={item} onClick={()=>appendVal(item)}>
                    {item}
                  </div>
                )}
                <div className="button disables-button" onClick={()=>appendVal(".")}>
                  .
                </div>
                <div className="button" id="number-field" onClick={removeLastVal}>
                  <i className="fa fa-arrow-left" aria-hidden="true"/>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    </React.Fragment>
  );
}