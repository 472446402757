import { any } from "prop-types";
import { ActionExtended } from "src/_common/interfaces/ActionExtended";
import { ACTIONS, APP_USER_ID, GUEST_USER_ID } from "src/_config";
export interface EventReducer {
  getEventList: any;
  getEventGameList: any;
  getEventGameDetails: any;
  getEventStatusMessage: any;
  getIrStatusMessage: any;
  getLeaderBoard: any;
  getTvImageDetails: any;
  getWiningTruckDetails: any;
  winingTruckModalShow: boolean;
  fromRoute: any;
  gameId: any;
  getPlayersList: any;
  total_data: any;
  getPlayersDetails: any;
  bet_id: any;
  playersList: any;
  performanceInfo: any;
  performanceList: any;
  eventDetails: any;
  availablePoints: any;
  rodeoEventSettings: any;
  earnedPoints: any;
  geoLocations: any;
  currentLocation: any;
  lastBettedPlayerList: any;
  gameDetails: any;
  performanceEventsList: any;
}

const initialState: EventReducer = {
  getEventList: null,
  getEventGameList: null,
  getEventGameDetails: null,
  getEventStatusMessage: null,
  getIrStatusMessage: null,
  getLeaderBoard: null,
  getTvImageDetails: null,
  getWiningTruckDetails: null,
  winingTruckModalShow: false,
  fromRoute: null,
  gameId: null,
  getPlayersList: null,
  total_data: null,
  getPlayersDetails: null,
  bet_id: null,
  playersList: null,
  performanceInfo: null,
  performanceList: null,
  eventDetails: null,
  availablePoints: null,
  rodeoEventSettings: null,
  earnedPoints: null,
  geoLocations: null,
  currentLocation: null,
  lastBettedPlayerList: null,
  gameDetails: null,
  performanceEventsList: null,
};

const eventReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.EVENT.GET_EVENT_LIST:
      return {
        ...state,
        getEventList: action.payload,
        gameId: action.gameId,
      };
    case ACTIONS.EVENT.SET_EVENT_LIST:
      return {
        ...state,
        getEventList: action.payload,
      };
    case ACTIONS.EVENT.GET_EVENT_GAME:
      return {
        ...state,
        getEventGameList: action.payload,
      };
    case ACTIONS.EVENT.PLAYERS_LIST:
      return {
        ...state,
        getPlayersList: action.payload,
        total_data: action.total_data,
      };
    case ACTIONS.EVENT.LAST_BETTED_PLAYERS_LIST:
      let data = action.payload
      let list = data.filter((p:any)=>p.is_user_bet)
      return {
        ...state,
        lastBettedPlayerList: list,
      };
    case ACTIONS.EVENT.SET_LAST_BETTED_PLAYER:
      let p = action.payload
      let betList = state.lastBettedPlayerList
      let updatedList = betList.filter((x:any)=>x.id!=p.id)
      updatedList.push(p)
      return {
        ...state,
        lastBettedPlayerList: updatedList,
      };
    case ACTIONS.EVENT.SET_EVENT_STATUS:
      let rodeo_event_event_dtls = action.payload
      let totalData = state.total_data
      console.log({rodeo_event_event_dtls,totalData})
      return {
        ...state,
        total_data: {
          ...totalData,
          rodeo_event_event_dtls: {
            ...totalData.rodeo_event_event_dtls, ...rodeo_event_event_dtls
          }
        },
      };
    case ACTIONS.EVENT.PLAYERS_DETAILS:
      return {
        ...state,
        getPlayersDetails: action.payload,
      };
    case ACTIONS.EVENT.GET_EVENT_GAME_DETAILS:
      return {
        ...state,
        getEventGameDetails: action.payload,
      };
    case ACTIONS.EVENT.GET_IR_STATUS_MESSAGE:
      let y: any = null
      // if(action.payload && action.payload.status_text) {
      if(action.payload) {
        y = action.payload.status_text
      }   
      return {
        ...state,
        getIrStatusMessage: y,
      };
    case ACTIONS.EVENT.GET_STATUS_MESSAGE:
      let x: any = null
      // if(action.payload && action.payload.status_text) {
      if(action.payload) {
        x = action.payload.status_text
      }   
      return {
        ...state,
        getEventStatusMessage: x,
      };
    case ACTIONS.EVENT.LEADERBOARD:
      return {
        ...state,
        getLeaderBoard: action.payload,
      };
    case ACTIONS.TV_IMAGE.DETAILS:
      return {
        ...state,
        getTvImageDetails: action.payload,
      };
    case ACTIONS.EVENT.OPEN_WINING_MODAL:
      return {
        ...state,
        winingTruckModalShow: action.payload,
      };
    case ACTIONS.EVENT.NEXT_EVENT_FROM_ROUTE:
      return {
        ...state,
        fromRoute: action.payload,
      };
      case ACTIONS.EVENT.SET_BET_ID:
          // if(action.payload.length)
          let betId=[]
          betId=state.bet_id
          if(betId && action.payload)
          {
           // console.log("if")
            betId.push(action.payload)
          }
          else if(action.payload)
          {
           // console.log("else")
            betId=[action.payload]
          }
          //console.log(betId)
          return {
              ...state,
              bet_id: betId
          }
      case ACTIONS.EVENT.RESET_BET_ID:
          return {
              ...state,
              bet_id: null
          }

    //Players LIst Status Update using socket

    case ACTIONS.EVENT.GET_PLAYER_LIST_STATUS_UPDATE:
      var { socketData } = action.payload;
      let playerStatusChange = state.getPlayersList;
      console.log("socketData event reducers page", {socketData,playerStatusChange});
      var findGameIndex = playerStatusChange.findIndex(
        (x: any) => x.id === socketData?.current_contestant?.id
      );
     // console.log("findGameIndex", findGameIndex);
      if (findGameIndex >= 0) {
         // console.log('if part')
        playerStatusChange[findGameIndex].status = socketData?.current_contestant?.status;
        // if(socketData?.current_contestant?.status==4) {
        //   playerStatusChange[findGameIndex].is_user_bet = null
        // }
        playerStatusChange[findGameIndex].other_status = socketData?.current_contestant?.other_status;
      }
      // console.log("2222222222222222", {playerStatusChange});

      return {
        ...state,
        getPlayersList:
          playerStatusChange && playerStatusChange.length
            ? playerStatusChange.map((x: any) => Object.assign(x))
            : [],
      };

    case ACTIONS.EVENT.GET_PLAYER_LIST_ALL_DATA_AND_STATUS_CHANGE_UPDATE:
      var { socketData } = action.payload;
      let gameDetails = state.total_data;
      let performance_info = state.performanceInfo
      // console.log("gameDetails total data", gameDetails);
      // console.log('gameDetails socket data', socketData)

        if (
          gameDetails &&
          gameDetails.performance_info &&
          gameDetails.performance_info.id == socketData.performance_id
        ) {
          gameDetails.performance_info.status = socketData.status;
          gameDetails.performance_info.wager_status = socketData.wager_status;
          performance_info.status = socketData.status
          performance_info.wager_status = socketData.wager_status
        }
      return {
        ...state,
        total_data: {
          ...gameDetails,
        },
      };


      case ACTIONS.EVENT.GET_PLAYER_DETAILS_STATUS_UPDATE:
      var { socketData } = action.payload;
      let playerDetails = state.getPlayersDetails;
      // console.log("gameDetails total data", playerDetails);
      // console.log('gameDetails socket data', socketData)

        if (
            playerDetails &&
            playerDetails.details &&
            playerDetails.details.id == socketData?.current_contestant?.id
        ) {
            playerDetails.details.status = socketData?.current_contestant?.status;
            playerDetails.details.other_status = socketData?.current_contestant?.other_status;
        }
      return {
        ...state,
        getPlayersDetails: {
          ...playerDetails,
        },
      };


      case ACTIONS.EVENT.GET_PLAYER_RESET_CONTESTANTS:
      var { socketData } = action.payload;
      let playerResetContestants = state.getPlayersList;
      // console.log("ACTIONS.EVENT.GET_PLAYER_RESET_CONTESTANTS", socketData);
      // console.log("playerStatusChanges", playerResetContestants)
      var findGameIndex = playerResetContestants && playerResetContestants.length && playerResetContestants.findIndex(
        (x: any) => x.id == socketData.contestant_id
      );
      //console.log("findGameIndex", findGameIndex);
      if (findGameIndex >= 0) {
          // console.log('if part')
          // console.log('socketData.type',socketData.type)
          if(!socketData?.type){
            playerResetContestants[findGameIndex].other_status = 1
            playerResetContestants[findGameIndex].status = 0
            playerResetContestants[findGameIndex].score = null
          }
          else if(socketData?.type && socketData?.type=="scratched"){
            playerResetContestants[findGameIndex].other_status = 2
            playerResetContestants[findGameIndex].status = 0
            playerResetContestants[findGameIndex].score = null
            playerResetContestants[findGameIndex].is_user_bet= null
          }

         // console.log(playerResetContestants);
      }
      

      return {
        ...state,
        getPlayersList:
        playerResetContestants && playerResetContestants.length
            ? playerResetContestants.map((x: any) => Object.assign(x))
            : [],
      };


      case ACTIONS.EVENT.GET_PLAYER_EMIT_SCORE:
      var { socketData } = action.payload;
      let playersEmit = state.getPlayersList;
      let available_point= state.total_data;
     // console.log('available_pint', available_point)
      let user_id= localStorage.getItem(APP_USER_ID)
      const guest_user_id= localStorage.getItem(GUEST_USER_ID)
      var findGameIndex = playersEmit && playersEmit.length && playersEmit.findIndex(
        (x: any) => x.id == socketData.contestant_id
      );
    
      var findSocketUserIndex=socketData && socketData.user && socketData.user.length && socketData.user.findIndex(
        (x: any) => {
          if(x.user_id == user_id && !x.guest_user_id){
            return x;
          }
          else if (x.guest_user_id == guest_user_id && !x.user_id ){
            return x;
          }
        }
      );

      console.log("findGameIndex", findGameIndex);
      console.log("findSocketUserIndex", findSocketUserIndex)
      let earnedPoints = state.earnedPoints ? state.earnedPoints : 0
      if (findGameIndex >= 0) {
        console.log("findGameIndexInsideIf", findGameIndex)
        playersEmit[findGameIndex].score = socketData.score
            // playersEmit[findGameIndex].status=0
            playersEmit[findGameIndex].status=2
            playersEmit[findGameIndex].other_status=0
            // if(playersEmit[findGameIndex].is_user_bet.score_type) {
            console.log('socketDataOut', socketData)
            
            // if(socketData.user[findSocketUserIndex].score_type) {
            if(socketData.user && socketData.user[findSocketUserIndex] && [0,1,2,3,4].includes(socketData.user[findSocketUserIndex].score_type)) {
                console.log('socketDataIn', socketData)
                // playersEmit[findGameIndex].is_user_bet.score_type = socketData.user[findSocketUserIndex].score_type 
                playersEmit[findGameIndex].is_user_bet = {...playersEmit[findGameIndex].is_user_bet, score_type: socketData.user[findSocketUserIndex].score_type}
                console.log(playersEmit[findGameIndex])
                if(socketData.user[findSocketUserIndex].score_type!=1) {
                  available_point.available_poins.point= (available_point.available_poins.point + socketData.user[findSocketUserIndex].score)
                  earnedPoints = earnedPoints + socketData.user[findSocketUserIndex].score
                }
                else {
                  available_point.available_poins.point= (available_point.available_poins.point + socketData.user[findSocketUserIndex].point)
                  // earnedPoints = earnedPoints + socketData.user[findSocketUserIndex].point
                }
              console.log('earnedPoints', earnedPoints)
            }

      }

      return {
        ...state,
        getPlayersList:
        playersEmit && playersEmit.length
            ? playersEmit.map((x: any) => Object.assign(x))
            : [],
            total_data: {
              ...available_point,
            },
        earnedPoints,
      };

      case ACTIONS.EVENT.SET_TOTAL_DATA_AVAILABLE_POINTS:
        let total = state.total_data;
        total.available_poins.point = action.payload

      return {
        ...state,
        total_data: {
          ...total,
        },
      };


      case ACTIONS.EVENT.SET_PLAYERS_LIST:
        return {
          ...state,
          getPlayersList: action.payload,
        };

      case ACTIONS.EVENT.SET_PERFORMANCE_INFO:
        return {
          ...state,
          performanceInfo: action.payload,
        };

      case ACTIONS.EVENT.SET_PERFORMANCE_LIST:
        return {
          ...state,
          performanceList: action.payload,
        };

      case ACTIONS.EVENT.SET_EVENT_DETAILS:
        return {
          ...state,
          eventDetails: action.payload,
        };

      case ACTIONS.EVENT.SET_AVAILABLE_POINTS:
        return {
          ...state,
          availablePoints: action.payload,
        };

      case ACTIONS.EVENT.SET_EARNED_POINTS:
        return {
          ...state,
          earnedPoints: action.payload,
        };

      case ACTIONS.EVENT.RODEO_EVENT_SETTINGS:
        return {
          ...state,
          rodeoEventSettings: action.payload,
        };

      case ACTIONS.EVENT.GET_RODEO_EVENT_GEO_LOCATIONS:
        return {
          ...state,
          geoLocations: action.payload,
        };

      case ACTIONS.EVENT.CURRENT_LOCATION:
        return {
          ...state,
          currentLocation: action.payload,
        };
      case ACTIONS.EVENT.GET_GAME_DETAILS:
          return {
              ...state,
              gameDetails: action.payload
          }
      case ACTIONS.EVENT.SET_PERFORMANCE_EVENTS_LIST:
          return {
              ...state,
              performanceEventsList: action.payload
          }
      

      
      //this one for default

    default:
      return state;
  }
};

export default eventReducer;
