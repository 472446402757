import React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormTextInput from 'src/_common/components/form-elements/textinput/formTextInput';
import { useUserApi } from 'src/_common/hooks/actions/user/appUserApiHook';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';

interface UpdateDisplayNameModalProps {
    onClose: Function;
    shouldShow: boolean;
}

interface UpdateDisplayNameModalValues {
    display_name: string;
}

const updateDisplayNameModalSchema = yup.object().shape({
    display_name: yup
        .string()
        .required('Display name is required'),
})


export default function UpdateDisplayNameModal({ onClose, shouldShow }: UpdateDisplayNameModalProps) {

    const { control, errors, setValue, handleSubmit, } = useForm<UpdateDisplayNameModalValues>({
        resolver: yupResolver(updateDisplayNameModalSchema),
        defaultValues: {
            display_name: '',
        },
    })

    const userApi = useUserApi()
    const userAction = useAppUserAction()

    const onSubmit = (values: UpdateDisplayNameModalValues) => {        
       // console.log({values})
        let params = {
            display_name: values?.display_name
        }
        userApi.callUpdateDisplayName(
            params,
            (message:any, resp:any) => {
                onClose()
                userAction.updateDisplayName(resp.display_name)
               // console.log({resp})
            },            
            (message:any) => {
                
            }            
        )
    }

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='custom-modal'
            >
                <Modal.Body bsPrefix={'main-container'}>
                    <div className="modal-body">
                        <h4 className="rider-name-content">Please enter your display name</h4>
                        <div className="mb-3">
                            {/* <label className="form-label">Display Name</label> */}
                            <Controller
                                control={control}
                                name="display_name"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.display_name}
                                        placeholder="Display Name"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-wrap mb-3">
                        <button type="button"
                            className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Update
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )

}