import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import LoginUserForm from 'src/components/login/loginUserForm'
// import Sidebar from 'src/components/common/Sidebar'
import RegisterForm from '../../components/register/registerForm'
import { APPLICATION_NAME, RODEO_PAGE_NAME, URLS } from '../../_config'

export default function Register() {

  //Set Login Page Title
  useEffect(() => {
    const prevTitle = document.title
    document.title = `${APPLICATION_NAME} : Login`
    return () => {
      document.title = prevTitle
    }
  }, [])

  return (
    <React.Fragment>
      {/* <Sidebar page={RODEO_PAGE_NAME.LOGIN} /> */}
      <section>
        {/* <img src="images/logo.png" alt="Logo" className="medium-logo" /> */}
        <RegisterForm />
        {/* <div className="how-to-play-btn-wrap">
          <Link to={URLS.CMS.HOW_TO_PLAY}>How to Play</Link>
          <Link to={URLS.CMS.TERM_OF_USE}>Terms of Use</Link>
        </div> */}
      </section>
    </React.Fragment>
  )
}
