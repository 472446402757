import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { useAppCurrentLocationSelector, useAppGeoLocationListSelector } from 'src/_common/hooks/selectors/eventSelector';
import { APPLICATION_GLOBAL_SLUG, CRYPTO_SECRET_KEY } from 'src/_config';

interface GeoLocationProps {
  type?: string;
  page?: string;
}

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

function GeoLocation({ type, page }: GeoLocationProps) {

  const urlParams = useParams<any>()
  const eventApi = useEventApi()
  const eventAction = useAppEventAction()
  const toast = useToaster()
  // const geoLocationList = useAppGeoLocationListSelector()
  // const [geoLocationList, setGeoLocationList] = useState<any>([])
  const [message, setMessage] = useState<any>()
  // const currentLocation = useAppCurrentLocationSelector()

  const getRodeoEventGeoLocations = () => {
    const params:any = {
      game_slug: APPLICATION_GLOBAL_SLUG,
      rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId),
    };
    // const guest_user_id = localStorage.getItem(GUEST_USER_ID);
    // if (guest_user_id && !userDetails) {
    //   params["guest_user_id"] = guest_user_id;
    // }

    let options = { timeout: 100000, enableHighAccuracy: false, maximumAge: 0 };


    eventApi.callGetRodeoEventGeoLocations(
      params,
      (message: string, resp: any) => {
        // console.log({resp})
        // setGeoLocationList(resp)
        if (navigator.geolocation) {
          // timeout at 100000 milliseconds (10 seconds)
          let options = { timeout: 100000, enableHighAccuracy: false, maximumAge: 0 };
          let geoLoc = navigator.geolocation;
          let watchID = geoLoc.watchPosition(e=>loadLocation(e, resp), errorLoadLocation, options);
          // console.log({watchID})

        } else {
          console.log("Sorry, browser does not support geolocation!");
        }
      },
      (message: string) => {
        toast.error(message)
      }
    )
  }

  const pointInPolygon = (polygon: any, point: any) => {
    let odd = false
    for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
        if (((polygon[i][1] > point[1]) !== (polygon[j][1] > point[1])) 
            && (point[0] < ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1]) / (polygon[j][1] - polygon[i][1]) + polygon[i][0]))) {
            odd = !odd
        }
        j = i
    }
    return odd
  }

  const isInPolygon = (points_polygon: any, vertices_x: any, vertices_y: any, latitude: any, longitude: any) => {
    let i = 0
    let j = 0
    let c = false
    console.log({points_polygon,vertices_x,vertices_y, latitude, longitude})
    for (i = 0, j = points_polygon - 1; i < points_polygon; j = i++) {
      
      if (((Number(vertices_y[i]) > longitude) != (Number(vertices_y[j]) > longitude)) && (latitude < (Number(vertices_x[j]) - Number(vertices_x[i])) * (longitude - Number(vertices_y[i])) / (Number(vertices_y[j]) - Number(vertices_y[i])) + Number(vertices_x[i]))) {
        c = !c;
      }
    }
    return c
  }

  const loadLocation = (position: any, geoList: any) => {
    // const { dispatch, geoLocationList, currentLocation } = this.props;
    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;
    localStorage.setItem('latitude', latitude)
    localStorage.setItem('longitude', longitude)
    let geoId = 0
    let cnt = false
    let vertices_x = []
    let vertices_y = []
    // console.log({geoList})
    if (geoList) {
      geoList.forEach((x:any) => {
        if (!cnt) {

          let points_polygon = x.vertices_x.length;
          let cur_loc = isInPolygon(points_polygon, x.vertices_x, x.vertices_y, latitude, longitude)
          //  const polygon = JSON.parse(x.data)
          //  let cur_loc2 = insidePolygon([latitude, longitude], polygon)

          // let polygon = x.vertices_x?.map((xv:any, i: any)=>
          //   [xv,x.vertices_y[i]]
          // )          
          // let cur_loc = pointInPolygon(polygon, [latitude,longitude])

          console.log({latitude, longitude, cur_loc})
          
          if (cur_loc) {
            setMessage("Inside")
            geoId = x.id
            cnt = true
          } else {
            setMessage("Outside")
          }
        }
      })

      let chkCnt = Number(localStorage.getItem('outCount'))
      let currentLocation = Number(localStorage.getItem('geolocation_val'))
      // console.log({currentLocation, chkCnt})
      // if ((!currentLocation && geoId) || (currentLocation && geoId && currentLocation.geoId != geoId)) {
      if ((!currentLocation && geoId) || (currentLocation && geoId && currentLocation != geoId)) {
          // eventAction.currentLoc({
          //   latitude: latitude,
          //   longitude: longitude,
          //   insidestate: true,
          //   geo_id: geoId
          // })
          localStorage.setItem('geolocation_val', geoId+"");
      }
      if (geoId == 0) {
        chkCnt++
        localStorage.setItem('outCount', chkCnt+"")
        setMessage("Out>>"+chkCnt)
        if(chkCnt > 5) {
          localStorage.removeItem('geolocation_val')
          localStorage.removeItem('outCount')
        }
      }
      // if (localStorage.getItem('geolocation_val') && geoId == 0 && chkCnt > 5) {
      //   localStorage.removeItem('geolocation_val')
      //   localStorage.removeItem('outCount')
        // chkCnt = 0
        // eventAction.clearCurrentLoc()
      // }
    }
    else {
      if (localStorage.getItem('geolocation_val')) {
        localStorage.removeItem('geolocation_val')
        // eventAction.clearCurrentLoc()
      }
    }
  }

  const errorLoadLocation = (err: any) => {
    if (err.code == 1) {
      console.log("Error: Access is denied!");
    } else if (err.code == 2) {
      console.log("Error: Position is unavailable!");
    }
  }

  useEffect(()=>{
    localStorage.setItem('outCount', "0")
    getRodeoEventGeoLocations()
  },[])

  // useEffect(()=>{
  //   if(geoLocationList && navigator.geolocation) {
  //       console.log({geoLocationList})
  //       // timeout at 100000 milliseconds (10 seconds)
  //       let options = { timeout: 100000, enableHighAccuracy: false, maximumAge: 0 };
  //       let geoLoc = navigator.geolocation;
  //       let watchID = geoLoc.watchPosition(e=>loadLocation(e, geoLocationList), errorLoadLocation, options);

  //   } else {
  //     console.log("Sorry, browser does not support geolocation!");
  //   }
  // },[geoLocationList, navigator])

  return (
    <React.Fragment>
      {/* <h2 style={{color: "white"}}>{message}</h2> */}
    </React.Fragment>
  )
}

export default GeoLocation
