import React, { useEffect, useState } from "react";
import Sidebar from "src/components/common/Sidebar";
import PlayerDetailsPage from "src/components/events/playerDetails/playerDetailsPage";
import PlayerVsBullDetailsPage from "src/components/events/playerDetails/playerVsBullDetails";
import { useAppEventDetailsSelector, useAppPerformanceEventsListSelector } from "src/_common/hooks/selectors/eventSelector";
import { RODEO_PAGE_NAME, APPLICATION_NAME, CRYPTO_SECRET_KEY } from "src/_config";
import { useParams } from "react-router";

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function PlayerDetailsContainer() {


  // const eventDetails = useAppEventDetailsSelector()
  const perfEventsList = useAppPerformanceEventsListSelector()
  const { eventId } = useParams<any>()
  const [currentEvent, setCurrentEvent] = useState<any>()

  useEffect(() => {
    let eId = cryptr.decrypt(eventId)
    let event = perfEventsList?.find((e:any)=>e.id==eId)
    console.log("PlayerDetailsContainer",{event})
    setCurrentEvent(event)

    const prevTitle = document.title;
    document.title = `${APPLICATION_NAME} : Results`;
    return () => {
      document.title = prevTitle;
    };
  }, []);

  return (
    <React.Fragment>
      <section className="main-container">
        {currentEvent?.pbr_enable==0 ? <PlayerDetailsPage /> : <PlayerVsBullDetailsPage /> }
      </section>
    </React.Fragment>
  );
}
