import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Header from "src/components/common/Header";
import Sidebar from "src/components/common/Sidebar";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { CRYPTO_SECRET_KEY, RODEO_PAGE_NAME } from "src/_config";
import parse from 'html-react-parser'
import Sponsor from "src/components/common/Sponsor";
import { useAppPerformanceListSelector } from "src/_common/hooks/selectors/eventSelector";

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function PrizeDetailsPage() {

    const { type, rodeoEventId, performanceId } = useParams<any>()
    const eventApi = useEventApi()
    const toast = useToaster()
    const history = useHistory()
    const [prizes, setPrizes] = useState<any>()
    const performanceList = useAppPerformanceListSelector()
    const [sponsorDetails, setSponsorDetails] = useState<any>()

    const  goToPrizesListPage = () => {
      history.push(`/prizes/${rodeoEventId}/${performanceId}`)
    }

    const getPrizeDetails = () => {
        let params = {
            type: cryptr.decrypt(type).toLowerCase(),
            game_id: cryptr.decrypt(rodeoEventId),
        }
        eventApi.callPrizeDetails(
            params,
            (message:any, resp:any) => {
                //console.log({resp})
                setPrizes(resp.prizes)
            },
            (message:any) => {
                toast.error(message)
            }
        )
    }

    useEffect(()=>{
        if(type && rodeoEventId) {
            getPrizeDetails()
        }
    },[type, rodeoEventId])

    useEffect(()=>{
        if(performanceList && performanceList.length) {
            let pId = cryptr.decrypt(performanceId)
            let x = performanceList.find((p:any)=>p.id==pId)
            if(x) {
                setSponsorDetails(x.sponsor)
            }
        }
    },[performanceList])

    return (
        <React.Fragment>
        <div className="event-sec over-hid">
          <div className="container">
            <div className="lite-logo">
              <div className="row">
                <div className="col-9">
                  <Sidebar page={RODEO_PAGE_NAME.PRIZE} />
                </div>
                <div className="col-3">
                    <Header type='side' />
                </div>
              </div>
            </div>
          </div>
  
          <div className="main-body">
            <div className="back-sec back-sec-two">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="back-sec-one">
                      
                        <img src="/images/back.png" onClick={goToPrizesListPage} />
                     
                    </div>
                    <h3>{cryptr.decrypt(type)} Prize</h3>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="container">
              <div className="prizes-wrap">
                {prizes?.length ?
                  prizes.map((p:any)=>
                    <div className="score-range-wrap back_sec" key={p.id}>
                      <div className="contestants-wrap leaderboard-title">
                        <div className="contestants-title w-100">
                          <h4>{cryptr.decrypt(type)=="Game" ? p?.rodeo_event_dtls?.title : ((p?.performance_id==-1) ? "All Performances" : p?.rodeo_performancet_dtls?.title) }</h4>
                        </div>
                      </div>
                      <ul className="performence-list cons-list-new">
                        <li className="selected" >
                          {parse(p.content)}
                        </li>
                      </ul>
                    </div>
                  )
                  : null
                }
              </div>
              
            </div>
          </div>
          {sponsorDetails && 
              <Sponsor
                  details={sponsorDetails}
              />
          }
        </div>
      </React.Fragment>
    );
}
