import React from 'react';
import { Modal } from 'react-bootstrap';

interface DaysheetModalProps {
    onClose: (success: any) => void;
    shouldShow: boolean;
    performanceInfo: any;
}


export default function DaysheetModal({ onClose, shouldShow, performanceInfo }: DaysheetModalProps) {

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='custom-modal'
            >
                <Modal.Header>
                    <h2 style={{color:"white"}}>Daysheet</h2>
                </Modal.Header>
                <Modal.Body bsPrefix={'main-container'}>
                    <div className="modal-body" style={{ overflowX: 'auto' }}>
                        <h4 className="rider-name-content"></h4>
                        <iframe src={performanceInfo?.document_link} width="100%" height="300" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-wrap mb-3">
                        <button type="button" className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )

}