import React, { useEffect } from 'react';
import PlayersPage from 'src/components/events/players/players';
import PrizesListPage from 'src/components/events/prize/prizesList';
import { RODEO_PAGE_NAME, APPLICATION_NAME } from 'src/_config';

export default function PrizesListContainer() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : Prizes`
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>
            {/* <Sidebar page={RODEO_PAGE_NAME.LEADERBOARD} /> */}
            <section className="main-container">
            <div className="event-sec">
                <div className="container">
                    <PrizesListPage />
                </div>
            </div>
            </section>
        </React.Fragment>
    )
}
