import { API_BASE_URL, SOCKETURL } from "./site_urls";

export const APPLICATION_NAME = "Over And Under";
export const APPLICATION_GLOBAL_SLUG = "overunder-mvp";
export const STORAGE = "RODEO-APP";
export const SELECTED_EVENT_STORAGE = `${APPLICATION_NAME}-Event`;

export const INSIDERODEO_EVENT_STORAGE = `EXTERNAL-EVENT-FOR-OVER-AND-UNDER`;

export const GUEST_USER_ID = `GUEST_USER_ID`;

export const APP_USER_ID = `USER_ID`;

export const RODEPICK_NEXT_GAME_IDS = `${APPLICATION_NAME}-NEXT-GAMES`;

export const IS_SHOWING_ADD_TO_HOME_SCREEN_IOS = `${APPLICATION_NAME}-IOS`;

export const PER_PAGE = 10;
export const WITHOUT_PER_PAGE = 99999;

export const FILE_BASE_PATH = "";
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_STAGE = process.env.REACT_APP_STAGE;

export const DATE_FORMAT = "MM/dd/yyyy";

export const DATE_ALL_FORMAT = {
  DATE_PICKER_FORMAT: "MM/dd/yyyy",
  MOMENT_FORMAT: "MM/DD/yyyy",
};

export const CHAT_DATE_TIME_FORMAT = {
  DISPLAY_DATE_WITH_TIME: "Do MMMM, YYYY hh:mm a",
  DISPLAY_DAY_TIME: "dddd hh:mm a",
};
export const TIME_CONFIG = {
  TIME_FORMAT: "hh:mm a",
  TIME_INTERVALS: 5,
};

export const SOCKET_URL = SOCKETURL;

export const URLS = {
  HOME: "/",
  LOGIN: "/login",
  LANDING: "/landing",
  LOGIN_WITH_OTP: "/loginwithotp",
  LOGIN_WITH_EMAIL: "/loginwithemail",
  REGISTRATION: "/registration",
  OTP: "/otp",
  RODEOS: "/rodeos",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_OTP: "/forgot-password-otp",
  // RESET_PASSWORD: '/reset-password',
  TV_IMAGE: "/tv-leaderboard/:gameId",
  USER: {
    DASHBOARD: "/dashboard",
    PROFILE: "/profile",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password/:token_value",
  },
  CMS: {
    CMS: "/cms/:slug",
    ABOUT_US: "/cms/about-us",
    LEGAL: "/cms/legal",
    HOW_TO_PLAY: "/cms/how-to-play-101",
    TERM_OF_USE: "/cms/legal",
  },
  EVENT: {
    PERFORMANCE: "/performance/:rodeoEventId?",
    LIST: "/events/:rodeoEventId?/:performanceId?",
    PLAYERS: "/players/:rodeoEventId?/:performanceId?/:eventId?",
    PLAYER_DETAILS: "/player-details/:rodeoEventId?/:performanceId?/:eventId?/:playerId?",
    LEADERBOARD: "/leaderboard",
    EVENT_GAMES: "/event/:eventId",
    EVENT_GAMES_DETAILS: "/event/:eventId/:gameId",
    RIDER_LIST: "/event/:eventId/:gameId",
    LEADER_BOARD: {
      EVENT: "/result/:eventId",
      GAME: "/result/:eventId/:gameId",
    },
    PLAYER_STATUS: "/status",
    PRIZES_LIST: "/prizes/:rodeoEventId/:performanceId",
    PRIZE: "/prize/:rodeoEventId/:performanceId/:type",
  },
};

export const API_URL = {
  USER: {
    LOGIN: `${API_BASE_URL}login`,
    LOGIN_WITH_OTP: `${API_BASE_URL}login-with-otp`,
    LOGOUT: `${API_BASE_URL}auth/logout`,
    DETAILS: `${API_BASE_URL}init`,
    UPDATE_PROFILE: `${API_BASE_URL}users/update`,
    REGISTRATION: `${API_BASE_URL}register`,
    LOGIN_WITH_EMAIL: `${API_BASE_URL}login-with-email`,
    FORGOT_PASSWORD: `${API_BASE_URL}password/email`,
    RESET_PASSWORD: `${API_BASE_URL}reset-password`,
    UPDATE_DISPLAY_NAME: `${API_BASE_URL}users/update-dispaly-name`,
    CHECK_VALID_TOKEN: `${API_BASE_URL}check-valid-token`,
    CREATE_UPDATE_USER_GAME: `${API_BASE_URL}create-update-user-game`,
    SAVE_NOTIFICATION: `${API_BASE_URL}over-under/save-notification`,
  },
  TV_IMAGE: {
    DETAILS: `${API_BASE_URL}over-under/leaderboard-tvimage`,
  },
  EVENT: {
    GET_EVENT_LIST: `${API_BASE_URL}over-under/rodeo-event`,
    GET_EVENT_GAME: `${API_BASE_URL}over-under/rodeo-event-performances`,
    GET_EVENT_GAME_DETAILS: `${API_BASE_URL}rodeo-pick/rodeo-event/event-details`,
    PICK_RIDER: `${API_BASE_URL}rodeo-pick/rodeo-event/user-pick`,
    LEADERBOARD: `${API_BASE_URL}over-under/leader-board`,

    GET_UPCOMING_EVENT: `${API_BASE_URL}over-under/performance-events`,
    JOIN_EVENT_GAME: `${API_BASE_URL}rodeo-race/rodeo-event/join`,
    PLAYERS_LIST: `${API_BASE_URL}over-under/event-contestants`,
    PLAYERS_DETAILS: `${API_BASE_URL}over-under/contestant-details`,
    BETTING_POINT_SUBMIT: `${API_BASE_URL}over-under/save-bet`,
    SAVE_BET: `${API_BASE_URL}over-under/save-user-bet`,
    PRIZE: `${API_BASE_URL}over-under/prize`,
    RESET_BET: `${API_BASE_URL}over-under/reset-bet`,
    GET_RODEO_EVENT_GEO_LOCATIONS: `${API_BASE_URL}get-rodeo-event-geo-locations`,
    LEADERBOARD_LOCATION_LIST: `${API_BASE_URL}over-under/leader-board-location-list`,
    GET_GAME_STATUS_TEXT: `${API_BASE_URL}get-game-status-text`,
    GET_GAME_DETAILS: `${API_BASE_URL}get-game-list-details`,
  },
  CMS: {
    GET_CMS: `${API_BASE_URL}rodeo-race/get-cms`,
  },
  COMMON: {
    GENDER_LIST: `${API_BASE_URL}genders`,
    COUNTRY_LIST: `${API_BASE_URL}country/list`,
    SECRET_QUESTION_LIST: `${API_BASE_URL}secret/questions`,
  },
};

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: "ACTIONS/LOADER/SET_FP_STATUS",
  },
  GLOBAL: {
    SET_LOGIN_MODAL: "ACTIONS/GLOBAL/SET_LOGIN_MODAL",
  },
  FORGOT_PASSWORD: {
    FORGOT_PASSWORD_STEP: "ACTIONS/FORGOT_PASSWORD/FORGOT_PASSWORD_STEP",
    FORGOT_PASSWORD_DATA: "ACTIONS/FORGOT_PASSWORD/FORGOT_PASSWORD_DATA",
  },
  USER: {
    LOGIN: "ACTIONS/USER/LOGIN",
    REGISTRATION: "ACTIONS/USER/REGISTRATION",
    LOGIN_WITH_OTP: "ACTIONS/USER/LOGIN_WITH_OTP",
    LOGIN_WITH_EMAIL: "ACTIONS/USER/LOGIN_WITH_EMAIL",
    ME: "ACTIONS/USER/ME",
    ONLINE_STATUS_CHANGE: "ACTIONS/USER/ONLINE_STATUS_CHANGE",
    SOCKET_INSTANCE_CONTAINER: "ACTIONS/USER/SOCKET_INSTANCE_CONTAINER",
    LOGOUT: "ACTIONS/USER/LOGOUT",
    PROFILE: "ACTIONS/USER/PROFILE",
    GAME_ID: "ACTIONS/USER/GAME_ID",
    FORGOT_PASSWORD: "ACTIONS/USER/FORGOT_PASSWORD",
    RESET_PASSWORD: "ACTIONS/USER/RESET_PASSWORD",
    UPDATE_DISPLAY_NAME: "ACTIONS/USER/UPDATE_DISPLAY_NAME",
  },
  EVENT: {
    GET_EVENT_LIST: "ACTIONS/EVENT/GET_EVENT_LIST",
    SET_EVENT_LIST: "ACTIONS/EVENT/SET_EVENT_LIST",
    // GET_UPCOMING_EVENT: 'ACTIONS/EVENT/GET_UPCOMING_EVENT',
    GET_EVENT_GAME: "ACTIONS/EVENT/GET_EVENT_GAME",
    GET_EVENT_GAME_DETAILS: "ACTIONS/EVENT/GET_EVENT_GAME_DETAILS",
    GET_STATUS_MESSAGE: "ACTIONS/EVENT/GET_STATUS_MESSAGE",
    GET_IR_STATUS_MESSAGE: "ACTIONS/EVENT/GET_IR_STATUS_MESSAGE",
    LEADERBOARD: "ACTIONS/EVENT/LEADERBOARD",

    OPEN_WINING_MODAL: "ACTIONS/EVENT/OPEN_WINING_MODAL",
    PLAYERS_LIST: "ACTIONS/EVENT/PLAYERS_LIST",
    PLAYERS_DETAILS: "ACTIONS/EVENT/PLAYERS_DETAILS",
    BETTING_POINT_SUBMIT: "ACTIONS/EVENT/BETTING_POINT_SUBMIT",
    TOTAL_DATA: "ACTIONS/EVENT/TOTAL_DATA",
    SET_TOTAL_DATA_AVAILABLE_POINTS: "ACTIONS/EVENT/SET_TOTAL_DATA_AVAILABLE_POINTS",
    SET_BET_ID: "ACTIONS/EVENT/SET_BET_ID",
    RESET_BET_ID: "ACTIONS/EVENT/RESET_BET_ID",
    LAST_BETTED_PLAYERS_LIST: "ACTIONS/EVENT/LAST_BETTED_PLAYERS_LIST",
    SET_LAST_BETTED_PLAYER: "ACTIONS/EVENT/SET_LAST_BETTED_PLAYER",

    //SOCKET
    GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST: "ACTIONS/EVENT/GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST",
    GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS: "ACTIONS/EVENT/GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS",
    GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST:
      "ACTIONS/EVENT/GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST",
    GET_SOCKET_RIDER_WITH_VOTE_DETAILS: "ACTIONS/EVENT/GET_SOCKET_RIDER_WITH_VOTE_DETAILS",
    GET_WINING_SOCKET_DATA_AND_PUSH_TO_WINING_DETAILS: "ACTIONS/EVENT/GET_WINING_SOCKET_DATA",

    GET_EVENT_LIST_GLOWING_SOCKET: "ACTIONS/EVENT/GET_EVENT_LIST_GLOWING_SOCKET",

    //Route change EVENT
    NEXT_EVENT_FROM_ROUTE: "ACTIONS/EVENT/NEXT_EVENT_FROM_ROUTE",
    GET_PLAYER_LIST_STATUS_UPDATE: "ACTIONS/EVENT/GET_PLAYER_LIST_STATUS_UPDATE",
    GET_PLAYER_DETAILS_STATUS_UPDATE: "ACTIONS/EVENT/GET_PLAYER_DETAILS_STATUS_UPDATE",
    GET_PLAYER_LIST_ALL_DATA_AND_STATUS_CHANGE_UPDATE:
      "ACTIONS/EVENT/GET_PLAYER_LIST_ALL_DATA_AND_STATUS_CHANGE_UPDATE",
    GET_PLAYER_RESET_CONTESTANTS: "ACTIONS/EVENT/GET_PLAYER_RESET_CONTESTANTS",
    GET_PLAYER_EMIT_SCORE: "ACTIONS/EVENT/GET_PLAYER_EMIT_SCORE",

    SET_PLAYERS_LIST: "ACTIONS/EVENT/SET_PLAYERS_LIST",
    SET_PERFORMANCE_INFO: "ACTIONS/EVENT/SET_PERFORMANCE_INFO",
    SET_PERFORMANCE_LIST: "ACTIONS/EVENT/SET_PERFORMANCE_LIST",
    SET_PERFORMANCE_EVENTS_LIST: "ACTIONS/EVENT/SET_PERFORMANCE_EVENTS_LIST",
    SET_EVENT_DETAILS: "ACTIONS/EVENT/SET_EVENT_DETAILS",
    SET_AVAILABLE_POINTS: "ACTIONS/EVENT/SET_AVAILABLE_POINTS",
    RODEO_EVENT_SETTINGS: "ACTIONS/EVENT/RODEO_EVENT_SETTINGS",
    SET_EVENT_STATUS: "ACTIONS/EVENT/SET_EVENT_STATUS",
    SET_EARNED_POINTS: "ACTIONS/EVENT/SET_EARNED_POINTS",
    GET_RODEO_EVENT_GEO_LOCATIONS: "ACTIONS/EVENT/GET_RODEO_EVENT_GEO_LOCATIONS",
    CURRENT_LOCATION: "ACTIONS/EVENT/CURRENT_LOCATION",
    GET_GAME_DETAILS: "ACTIONS/EVENT/GET_GAME_DETAILS",
  },
  TV_IMAGE: {
    DETAILS: "ACTIONS/TV_IMAGE/DETAILS",
  },
};

export const SAGA_ACTIONS = {
  USER: {
    LOGIN: "SAGA_ACTIONS/USER/LOGIN",
    LOGIN_WITH_OTP: "SAGA_ACTIONS/USER/LOGIN_WITH_OTP",
    LOGOUT: "SAGA_ACTIONS/USER/LOGOUT",
    DETAILS: "SAGA_ACTIONS/USER/DETAILS",
    UPDATE_PROFILE: "SAGA_ACTIONS/USER/UPDATE_PROFILE",
    REGISTRATION: "SAGA_ACTIONS/USER/REGISTRATION",
    LOGIN_WITH_EMAIL: "SAGA_ACTIONS/USER/LOGIN_WITH_EMAIL",
    FORGOT_PASSWORD: "SAGA_ACTIONS/USER/FORGOT_PASSWORD",
    RESET_PASSWORD: "SAGA_ACTIONS/USER/RESET_PASSWORD",
    UPDATE_DISPLAY_NAME: "SAGA_ACTIONS/USER/UPDATE_DISPLAY_NAME",
    CHECK_VALID_TOKEN: "SAGA_ACTIONS/USER/CHECK_VALID_TOKEN",
    CREATE_UPDATE_USER_GAME: "SAGA_ACTIONS/USER/CREATE_UPDATE_USER_GAME",
    SAVE_NOTIFICATION: "SAGA_ACTIONS/USER/SAVE_NOTIFICATION",
  },
  EVENT: {
    GET_EVENT_LIST: "SAGA_ACTIONS/EVENT/GET_EVENT_LIST",
    GET_EVENT_GAME: "SAGA_ACTIONS/EVENT/GET_EVENT_GAME",
    GET_EVENT_GAME_DETAILS: "SAGA_ACTIONS/EVENT/GET_EVENT_GAME_DETAILS",
    PICK_RIDER: "SAGA_ACTIONS/EVENT/PICK_RIDER",
    LEADERBOARD: "SAGA_ACTIONS/EVENT/LEADERBOARD",
    SAVE_BET: "SAGA_ACTIONS/EVENT/SAVE_BET",

    GET_UPCOMING_EVENT: "SAGA_ACTIONS/EVENT/GET_UPCOMING_EVENT",
    JOIN_EVENT_GAME: "SAGA_ACTIONS/EVENT/JOIN_EVENT_GAME",
    PLAYERS_LIST: "SAGA_ACTIONS/EVENT/PLAYERS_LIST",
    PLAYERS_DETAILS: "SAGA_ACTIONS/EVENT/PLAYERS_DETAILS",
    BETTING_POINT_SUBMIT: "SAGA_ACTIONS/EVENT/BETTING_POINT_SUBMIT",
    TOTAL_DATA: "SAGA_ACTIONS/EVENT/TOTAL_DATA",
    PRIZE_DETAILS: "SAGA_ACTIONS/EVENT/PRIZE_DETAILS",
    RESET_BET: "SAGA_ACTIONS/EVENT/RESET_BET",
    GET_RODEO_EVENT_GEO_LOCATIONS: "SAGA_ACTIONS/EVENT/GET_RODEO_EVENT_GEO_LOCATIONS",
    LEADERBOARD_LOCATION_LIST: "SAGA_ACTIONS/EVENT/LEADERBOARD_LOCATION_LIST",
    GET_GAME_STATUS_TEXT: "SAGA_ACTIONS/EVENT/GET_GAME_STATUS_TEXT",
    GET_GAME_DETAILS: "SAGA_ACTIONS/EVENT/GET_GAME_DETAILS",
  },
  TV_IMAGE: {
    DETAILS: "SAGA_ACTIONS/TV_IMAGE/DETAILS",
  },
  CMS: {
    GET_CMS: "SAGA_ACTIONS/COMMON/GET_CMS",
  },
  COMMON: {
    GENDER_LIST: "SAGA_ACTIONS/COMMON/GENDER_LIST",
    COUNTRY_LIST: "SAGA_ACTIONS/COMMON/COUNTRY_LIST",
    SECRET_QUESTION_LIST: "SAGA_ACTIONS/COMMON/SECRET_QUESTION_LIST",
  },
};

export * from "./site_statics";
export * from "./site_urls";
export * from "./functions";
export * from "./canvasUtils";
export * from "./card_utils";
