import React, { useEffect, useState } from "react";
import useSocket from "use-socket.io-client";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "src/components/common/Sidebar";
import { useCmsApi } from "src/_common/hooks/actions/cms/cmsApiHook";
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import {
  useAppEarnedPointsSelector,
  useAppEventDetailsSelector,
  useAppEventLeaderBoardSelector,
  usePlayersDetailsSelector,
  usePlayersListSelector,
  useTotalContestentDataSelector,
} from "src/_common/hooks/selectors/eventSelector";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import {
  APPLICATION_GLOBAL_SLUG,
  APP_USER_ID,
  CRYPTO_SECRET_KEY,
  GUEST_USER_ID,
  removeTrailingZero,
  RODEO_PAGE_NAME,
  SOCKET_CHANNEL,
  SOCKET_URL,
  URLS,
} from "src/_config";
// import NumberPad from "src/_common/numberpad/numberPad";
import NumberPad from "src/_common/numberpad/numberPad copy";
import StatusModal from "../modal/statusModal";
import Header from "../../common/Header";
import ConfettiExplosion from "react-confetti-explosion";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function PlayerDetailsPage() {
  // const cmsApi = useCmsApi();
  const [content, setContent] = useState<any>();
  const history = useHistory();
  const location = useLocation();
  const guest_user_id = localStorage.getItem(GUEST_USER_ID);
  const eventApi = useEventApi();
  const usePlayersListSelectors: any = usePlayersListSelector();
  const userDetails: any = useAppUserDetailsSelector();
  const eventAction = useAppEventAction();
  const usePlayersDetailsSelectors = usePlayersDetailsSelector();
  // const [getPlayersDetails, setPlayersDetails] = useState<any>()
  const [numbperPadShow, setNumberPadShow] = useState<boolean>(false);
  const ContestantAllData = useTotalContestentDataSelector();
  const [getPerformanceInfo, setPerformanceInfo] = useState<any>();
  const [playersList, setPlayersList] = useState<any>();
  const [sendInputValue, setSendInputValue] = useState<any>([]);
  const [sendItemId, setSendItemId] = useState<any>();
  const [sendItemIndex, setSendItemIndex] = useState<any>();
  const [getPoint, setPoints] = useState<any>();
  const [getConstantPoint, setConstantPoint] = useState<any>();
  const [getPointFromNumpad, setPointFromNumpad] = useState<any>();
  const [getIncreaseOrDecrease, setIncreaseOrDecrease] = useState<any>();
  const [changeVal, setChangeVal] = useState<any>("");
  const [getEventTitle, setEventTitle] = useState<any>();
  const [currentPlayer, setCurrentPlayer] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [statusModalMesssage, setStatusModalMesssage] = useState<any>();
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const urlParams = useParams<any>();
  const [leaderboardStatus, setLeaderboardStatus] = useState<any>("");
  const [remainingBet, setRemainingBet] = useState<any>(0);
  const negativePointInfo =
    "If an event’s score is corrected after picks are settled, for example due to an appeal or a data entry error, then all picks will be recalculated taking the correction into account.  In some cases, that may lead to a negative balance.  If you have a negative balance, you cannot place any additional wagers until and unless your balance becomes positive from the settlement of other picks you have already placed.";
  const earnedPoints = useAppEarnedPointsSelector();
  const eventDetails = useAppEventDetailsSelector();
  const [winningUsersData, setWinningUsersData] = useState<any>();
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const [currentPlayerSet, setCurrentPlayerSet] = useState<boolean>(false);
  const [playerDetailsApiCalled, setPlayerDetailsApiCalled] = useState<boolean>(false);

  useEffect(() => {
    // let loc: any = location;
    // console.log('loc', loc)
    // if(loc && !loc.state){
    //   history.push({pathname: URLS.RODEOS})
    // }
  }, [location]);

  useEffect(() => {
    setPlayersList(usePlayersListSelectors);
  }, [usePlayersListSelectors]);

  useEffect(() => {
    setPerformanceInfo(ContestantAllData?.performance_info);
    setPoints(
      ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point
        ? ContestantAllData?.available_poins?.point
        : null
    );
  }, [ContestantAllData]);

  const goToPlayerPage = (item?: any, index?: any) => {
    // let loc: any = location;
    // history.push({
    //   pathname: '/players',
    //   state: { id: loc?.state?.id, performance_id: loc?.state?.performance_id, rodeo_event_id: loc?.state?.rodeo_event_id, player_id:loc?.state?.player_id },
    // });
    const params: any = {
      rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId),
      performance_id: cryptr.decrypt(urlParams.performanceId),
      inner_event_id: cryptr.decrypt(urlParams.eventId),
    };
    // console.log(params)
    history.push(`/players/${urlParams.rodeoEventId}/${urlParams.performanceId}/${urlParams.eventId}`);
  };

  useEffect(() => {
    getInnerEventList();
  }, [urlParams]);

  const [socket] = useSocket(SOCKET_URL, {
    autoConnect: false,
  });

  useEffect(() => {
    // if (userDetails) {
    socket.connect();

    //Status Change Socket
    socket.on(SOCKET_CHANNEL.OU_EVENT_DETAILS, (changeEventStatus: any) => {
      eventAction.playerDetailsStatusUpdate(changeEventStatus);
    });

    socket.on(SOCKET_CHANNEL.START_EVENT, (changeEventStatus: any) => {
      let player = changeEventStatus.contestants.find((p: any) => p.id == changeEventStatus?.contestant_id);
      if (player?.id == cryptr.decrypt(urlParams.playerId)) {
        if (player?.status == 4 || player?.other_status == 2) {
          getPlayersDetails();
          return;
        }
        if (player?.other_status == 0) {
          setCurrentPlayer((prev: any) => ({ ...prev, ...player }));
          setCurrentPlayerSet(true);
        }
      }
    });

    socket.on(SOCKET_CHANNEL.OU_BET_ENABLE, (changeEventsStatus: any) => {
      // console.log(changeEventsStatus)
      eventAction.playerListAllDataAndStatusChangesUpdate(changeEventsStatus);
    });

    socket.on(SOCKET_CHANNEL.OU_RESET_CONTESTANT, (changeEventsStatus: any) => {
      //console.log(changeEventsStatus)
      eventAction.playerListResetContestents(changeEventsStatus);
    });

    socket.on(SOCKET_CHANNEL.OU_EMIT_SCORE, (changeEventsStatus: any) => {
      // console.log(changeEventsStatus)
      let user = changeEventsStatus.user.find((x: any) => {
        if ((userDetails && x.user_id == userDetails.id) || (guest_user_id && guest_user_id == x.guest_user_id)) {
          return x;
        }
      });
      if (changeEventsStatus?.mode && changeEventsStatus?.mode == "edit") {
        // console.log("Edit mode")
        getPlayersDetails();
        return;
      }
      if (user && user?.score_type == 2) {
        console.log(user);
        setWinningUsersData(user);
        // setShowWinningModal(true)
      }
      eventAction.playerListEmitScore(changeEventsStatus);
      // let prevScore = ContestantAllData?.list?.find((x:any)=>x.id==changeEventsStatus.contestant_id)?.score
      // console.log(prevScore,prevScore==0)
      // if(prevScore==0 && changeEventsStatus?.score!=prevScore) {
      //   console.log("inside If PrevScore0")
      //   getInnerEventList()
      //   return
      // }
      // if( changeEventsStatus?.score!=prevScore ) {
      //   console.log("inside If PrevScore")
      //   getInnerEventList()
      //   return
      // }
    });
    // }

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  const onchngFunc = (item: any) => {
    setChangeVal(item);
  };

  // number pad hide
  const onNumpadClose = (val: any) => {
    setNumberPadShow(false);
    // if(val) {
    updateBetPoint(val);
    // }
  };

  const onContestantClick = (item: any) => {
    //console.log(item)
    if (getPerformanceInfo && getPerformanceInfo && getPerformanceInfo?.wager_status == 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("Picks are not open yet");
    } else if (ContestantAllData && ContestantAllData?.rodeo_event_event_dtls?.status == 2) {
      setShowStatusModal(true);
      setStatusModalMesssage("Picking has ended.");
    } else {
      // if(item && item?.is_user_bet && item?.is_user_bet?.created_at)
      // {
      //   setShowStatusModal(true)
      //   setStatusModalMesssage("You already placed bet for this contestant.")
      // }
      // else if(item && item?.status == 4)
      if (item && item?.status == 4) {
        setShowStatusModal(true);
        setStatusModalMesssage("Performance of this contestant already started, wait for the result.");
      } else if (item && item?.status == 2) {
        setShowStatusModal(true);
        setStatusModalMesssage("Contestant has completed his ride.");
      } else if (item && item?.other_status == 2) {
        setShowStatusModal(true);
        setStatusModalMesssage("Contestant has been scratched");
      }
    }
  };

  const updateBetPoint = (val: any) => {
    let item: any = currentPlayer;
    let updatedArray: any = {
      ...item,
      is_user_bet: {
        point: val,
        wager_set_value: item?.is_user_bet?.wager_set_value
          ? item?.is_user_bet?.wager_set_value
          : item?.set_value?.set_value || 0,
        created_at: null,
      },
    };
    for (let i = 0; i < playersList.length; i++) {
      if (playersList[i].id === item.id) {
        playersList.splice(i, 1); // 2nd parameter means remove one item only
        playersList.splice(i, 0, updatedArray);
        setCurrentPlayer(updatedArray);
        setCurrentIndex(i);
        // setPlayersList(playersList);
        eventAction.setPlayersList(playersList);
      }
    }
    // setCurrentPlayer(updatedArray)
  };

  const setFinalPoints = (val: any) => {
    eventAction.setTotalDataAvailablePoints(val);
    setPoints(val);
  };

  const getVal = (item: any, id: any, index: any, increaseOrDecrease: any, newAddedValues: any) => {
    setIncreaseOrDecrease(increaseOrDecrease);
    setPointFromNumpad(newAddedValues);
    if (increaseOrDecrease == "remove") {
      setPoints(newAddedValues);
    } else if (increaseOrDecrease == "add") {
      setPoints(newAddedValues);
    }
    // console.log(playersList)
    // console.log('item', item)

    for (let i = 0; i < playersList.length; i++) {
      if (playersList[i].id === id) {
        let updatedArray: any = {
          id: id,
          is_user_bet: {
            point: item,
            wager_set_value: playersList[i]?.is_user_bet?.wager_set_value
              ? playersList[i]?.is_user_bet?.wager_set_value
              : playersList[i]?.set_value?.set_value || 0,
          },
          avatar: playersList[i].avatar,
          contestant_name: playersList[i].contestant_name,
          contestant_dtls: playersList[i].contestant_dtls,
          set_value: playersList[i].set_value,
          score: playersList[i].score,
          performance_id: playersList[i].performance_id,
          rodeo_event_event_id: playersList[i].rodeo_event_event_id,
          rodeo_event_id: playersList[i].rodeo_event_id,
          status: playersList[i].status,
          other_status: playersList[i].other_status,
        };
        playersList.splice(index, 1); // 2nd parameter means remove one item only
        playersList.splice(index, 0, updatedArray);
        setCurrentPlayer(updatedArray);
        setCurrentIndex(index);
        // setPlayersList(playersList);
        eventAction.setPlayersList(playersList);
      }
    }
  };

  // when user clicked on number numberpad show
  const clickEvent = (item?: any, index?: any) => {
    // if (item?.id && getPerformanceInfo && getPerformanceInfo && getPerformanceInfo?.wager_status == 1 && item?.status==0) {
    onContestantClick(item);
    if (ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point < 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("You don't have enough available points for picking this contestant.");
      return;
    }
    if (
      getPerformanceInfo &&
      getPerformanceInfo?.wager_status == 1 &&
      [0, 1].includes(item?.status) &&
      ContestantAllData?.rodeo_event_event_dtls?.status != 2
    ) {
      setNumberPadShow(true);
      setSendInputValue(playersList);
      setSendItemId(item.id);
      setSendItemIndex(index);
      // console.log(index,item.id)
    }
  };

  // number pad hide
  const onclose = (item: any) => {
    setNumberPadShow(item);
  };

  const onOverUnderClick = (item: any, wager_type: any) => {
    // if(getPerformanceInfo && getPerformanceInfo?.wager_status == 1 && item?.status==0 && !item?.is_user_bet?.id &&ContestantAllData?.rodeo_event_event_dtls?.status!=2)
    onContestantClick(item);
    if (ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point < 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("You don't have enough available points for picking this contestant.");
      return;
    }
    if (
      getPerformanceInfo &&
      getPerformanceInfo?.wager_status == 1 &&
      [0, 1].includes(item?.status) &&
      ContestantAllData?.rodeo_event_event_dtls?.status != 2
    ) {
      var parms: any = {
        rodeo_event_id: item?.rodeo_event_id,
        performance_id: item?.performance_id,
        wager_type: wager_type,
        event_id: item?.rodeo_event_event_id,
        contestant_id: item?.id,
        point: item?.is_user_bet && item?.is_user_bet?.point ? item?.is_user_bet?.point : 0,
        wager_set_value: item?.set_value?.set_value,
      };

      if (guest_user_id && !userDetails) {
        parms["guest_user_id"] = guest_user_id;
      }

      if (parms?.point != 0) {
        eventApi.callSubmitPlayersBettingPoints(
          parms,
          (message: string, resp: any) => {
            if (resp) {
              if (resp?.user_wager?.created_at) {
                let newBetRemaining = remainingBet - 1;
                setRemainingBet(newBetRemaining);
                if (newBetRemaining == 0) {
                  setLeaderboardStatus("green");
                } else if (newBetRemaining > 0) {
                  setLeaderboardStatus("red");
                }
              }
              toast.success(message);
              if (
                ContestantAllData &&
                ContestantAllData?.available_poins &&
                ContestantAllData?.available_poins?.point
              ) {
                ContestantAllData.available_poins.point = resp?.available_points?.point;
              }
              var findGameIndex =
                playersList && playersList.length && playersList.findIndex((x: any) => x.id === item?.id);
              if (findGameIndex >= 0) {
                let today = new Date();
                playersList[findGameIndex].is_user_bet = {
                  point: parms?.point,
                  created_at: today,
                  wager_set_value: item?.set_value?.set_value,
                  wager_type: wager_type,
                };
                console.log(">>>", playersList[findGameIndex]);
                setCurrentPlayer({ ...currentPlayer, is_user_bet: playersList[findGameIndex].is_user_bet });
                setChangeVal(item);
              }
            } else {
              toast.error(message);
            }
          },
          (message: string) => {
            toast.error(message);
          }
        );
      } else {
        toast.error("Please enter your bet ");
      }
    }
  };

  const downArrowClick = (item?: any, index?: any) => {
    // if(getPerformanceInfo && getPerformanceInfo?.wager_status == 1 && item?.status==0 && !item?.is_user_bet?.id &&ContestantAllData?.rodeo_event_event_dtls?.status!=2)
    onContestantClick(item);
    if (ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point < 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("You don't have enough available points for picking this contestant.");
      return;
    }
    //console.log(item,">>",index)
    if (
      getPerformanceInfo &&
      getPerformanceInfo?.wager_status == 1 &&
      [0, 1].includes(item?.status) &&
      ContestantAllData?.rodeo_event_event_dtls?.status != 2
    ) {
      if (item?.is_user_bet && item?.is_user_bet?.point != "0") {
        for (let i = 0; i < playersList.length; i++) {
          if (playersList[i].id === item?.id) {
            let numbers = Number(item?.is_user_bet?.point ? item?.is_user_bet?.point : 0);
            let AddingNumber = numbers - 1;
            let updatedArray: any = {
              id: item?.id,
              is_user_bet: {
                point: AddingNumber,
                wager_set_value: playersList[i]?.is_user_bet?.wager_set_value
                  ? playersList[i]?.is_user_bet?.wager_set_value
                  : playersList[i]?.set_value?.set_value || 0,
                created_at: null,
              },
              avatar: playersList[i].avatar,
              contestant_name: playersList[i].contestant_name,
              contestant_dtls: playersList[i].contestant_dtls,
              set_value: playersList[i].set_value,
              score: playersList[i].score,
              performance_id: playersList[i].performance_id,
              rodeo_event_event_id: playersList[i].rodeo_event_event_id,
              rodeo_event_id: playersList[i].rodeo_event_id,
              status: playersList[i].status,
              other_status: playersList[i].other_status,
            };
            playersList.splice(index, 1); // 2nd parameter means remove one item only
            playersList.splice(index, 0, updatedArray);
            // setPlayersList(playersList);
            eventAction.setPlayersList(playersList);
            setCurrentPlayer(playersList[i]);
            setChangeVal(item);
            // if(AddingNumber){
            setPoints(getPoint + 1);
            // }
          }
        }
      }
    }
  };

  const upArrowClick = (item?: any, index?: any) => {
    // if(getPerformanceInfo && getPerformanceInfo?.wager_status == 1 && item?.status==0 && !item?.is_user_bet?.id &&ContestantAllData?.rodeo_event_event_dtls?.status!=2)
    //console.log(item,">>",index)
    onContestantClick(item);
    if (ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point < 0) {
      setShowStatusModal(true);
      setStatusModalMesssage("You don't have enough available points for picking this contestant.");
      return;
    }
    if (
      getPerformanceInfo &&
      getPerformanceInfo?.wager_status == 1 &&
      [0, 1].includes(item?.status) &&
      ContestantAllData?.rodeo_event_event_dtls?.status != 2
    ) {
      if (getPoint && getPoint > 0.9) {
        // if (getPoint && getPoint != 0) {
        for (let i = 0; i < playersList.length; i++) {
          if (playersList[i].id === item?.id) {
            let numbers = Number(item?.is_user_bet?.point ? item?.is_user_bet?.point : 0);
            let AddingNumber = numbers + 1;
            let updatedArray: any = {
              id: item?.id,
              is_user_bet: {
                point: AddingNumber,
                wager_set_value: playersList[i]?.is_user_bet?.wager_set_value
                  ? playersList[i]?.is_user_bet?.wager_set_value
                  : playersList[i]?.set_value?.set_value || 0,
                created_at: null,
              },
              avatar: playersList[i].avatar,
              contestant_name: playersList[i].contestant_name,
              contestant_dtls: playersList[i].contestant_dtls,
              set_value: playersList[i].set_value,
              score: playersList[i].score,
              performance_id: playersList[i].performance_id,
              rodeo_event_event_id: playersList[i].rodeo_event_event_id,
              rodeo_event_id: playersList[i].rodeo_event_id,
              status: playersList[i].status,
              other_status: playersList[i].other_status,
            };
            playersList.splice(index, 1); // 2nd parameter means remove one item only
            playersList.splice(index, 0, updatedArray);
            //console.log(playersList)
            // setPlayersList(playersList);
            eventAction.setPlayersList(playersList);
            setCurrentPlayer(playersList[i]);
            setChangeVal(item);
            if (AddingNumber) {
              setPoints(getPoint - 1);
            }
          }
        }
      }
    }
  };

  const getInnerEventList = () => {
    let loc: any = location;
    const params: any = {
      rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId),
      performance_id: cryptr.decrypt(urlParams.performanceId),
      inner_event_id: cryptr.decrypt(urlParams.eventId),
    };
    if (guest_user_id && !userDetails) {
      params["guest_user_id"] = guest_user_id;
    }

    // console.log(params)
    // console.log('usePlayersListSelectors', usePlayersListSelectors)
    // console.log('total Data', ContestantAllData)

    // eventApi.callGetPlayersList(
    //   params,
    //   (message: string, resp: any) => {
    //     console.log(resp)
    if (ContestantAllData && ContestantAllData.list) {
      let pid = cryptr.decrypt(urlParams.playerId);
      ContestantAllData.list.map((x: any, i: any) => {
        if (x.id == pid) {
          setCurrentPlayer(x);
          setCurrentIndex(i);
        }
      });
      setPoints(
        ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point
          ? ContestantAllData?.available_poins?.point
          : null
      );
      setConstantPoint(
        ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point
          ? ContestantAllData?.available_poins?.point
          : 0
      );
      setEventTitle(ContestantAllData?.rodeo_event_event_dtls?.event_title);
    } else {
      // setPlayersList("");
      eventAction.setPlayersList("");
    }
    //   },
    //   (message: string) => {
    //     toast.error(message);
    //   }
    // );
  };

  useEffect(() => {
    if (playersList) {
      let loc: any = location;
      let pid = cryptr.decrypt(urlParams.playerId);
      //console.log("playersList-->",pid)
      playersList.map((x: any, i: any) => {
        if (x.id == pid) {
          setCurrentPlayer(x);
          setCurrentIndex(i);
        }
      });
    }
  }, [playersList, changeVal]);

  useEffect(() => {
    if (playersList?.length && currentPlayer && currentPlayerSet) {
      let index = playersList.findIndex((p: any) => p.id == currentPlayer.id);
      let x = [...playersList];
      x.splice(index, 1);
      x.splice(index, 0, currentPlayer);
      // setPlayersList(x)
      eventAction.setPlayersList(x);
      setCurrentPlayerSet(false);
    }
  }, [playersList, currentPlayer, currentPlayerSet]);

  const changePage = (n: any) => {
    let i = currentIndex + n;
    let x = playersList[i];
    // let loc: any = location;
    // history.push({
    //   state: { id: loc?.state?.id, performance_id: loc?.state?.performance_id, rodeo_event_id: loc?.state?.rodeo_event_id, player_id:x.id },
    // });
    history.push(
      `/player-details/${urlParams.rodeoEventId}/${urlParams.performanceId}/${urlParams.eventId}/${cryptr.encrypt(
        x.id
      )}`
    );

    setCurrentPlayer(x);
    setCurrentIndex(i);
  };

  const onStatusModalClose = () => {
    setShowStatusModal(false);
    setStatusModalMesssage("");
  };

  // const onStatusModalSuccess = () => {
  //   setShowStatusModal(false)
  //   setStatusModalMesssage("")
  // }

  const showInfo = (msg: any) => {
    if (msg) {
      setStatusModalMesssage(msg);
      setShowStatusModal(true);
    }
  };

  const getPlayersDetails = () => {
    setCurrentPlayer(null);
    const params: any = {
      player_id: cryptr.decrypt(urlParams.playerId),
    };

    if (userDetails && !guest_user_id) {
      localStorage.setItem(APP_USER_ID, userDetails?.id);
    }

    if (guest_user_id && !userDetails) {
      params["guest_user_id"] = guest_user_id;
    }

    eventApi.callGetPlayersDetails(
      params,
      (message: string, resp: any) => {
        // console.log({resp})
        eventAction.setEventDetails(resp.rodeo_event_event_dtls);
        setCurrentPlayer(resp.details);
        setCurrentPlayerSet(true);
        setPoints(resp.available_poins.point);
        if (resp.min_bet_leaderboard != 0) {
          if (resp.user_remaining_bet == 0) {
            setLeaderboardStatus("green");
          } else if (resp.user_remaining_bet > 0) {
            setLeaderboardStatus("red");
            setRemainingBet(resp.user_remaining_bet);
          }
        } else {
        }
        setPlayerDetailsApiCalled(true);
      },
      (message: string) => {}
    );
  };

  const changeShowFooter = (prev: any) => setShowFooter(!prev);

  useEffect(() => {
    getPlayersDetails();
  }, [urlParams]);

  useEffect(() => {
    if (winningUsersData) {
      setTimeout(() => setWinningUsersData(null), 3000);
    }
  }, [winningUsersData]);

  return (
    <React.Fragment>
      <div className="event-sec over-hid">
        <div className="container">
          <div className="lite-logo">
            <div className="row">
              <div className="col-9">
                <Sidebar page={RODEO_PAGE_NAME.CONTESTANT_DETAILS} />
              </div>
              <div className="col-3">
                <Header type="side" />
              </div>
            </div>
          </div>
        </div>
        <div className={leaderboardStatus == "red" ? leaderboardStatus + "-leaderboard-status" : "d-none"}>
          {leaderboardStatus == "red"
            ? "Pick " + remainingBet + " more to get qualified"
            : "You have successfully chosen your pick"}
        </div>
        <div className="main-body">
          <div className="back-sec back-sec-two">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="back-sec-one">
                    <img
                      src="/images/back.png"
                      onClick={() => {
                        goToPlayerPage();
                      }}
                    />
                  </div>
                  {/* <h3>{ContestantAllData?.rodeo_event_event_dtls?.event_title}</h3> */}
                  <h3>{eventDetails?.event_title}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="player-image">
              <img
                src={
                  currentPlayer?.avatar && currentPlayer?.avatar?.id == 0
                    ? currentPlayer?.contestant_dtls?.avatar && currentPlayer?.contestant_dtls?.avatar?.id == 0
                      ? "/images/no-image.png"
                      : currentPlayer?.contestant_dtls?.avatar?.thumb
                    : currentPlayer?.avatar?.thumb
                }
              />
              <h2>{currentPlayer?.contestant_name}</h2>
            </div>
            <div className="player-score-table">
              <div className="player-inner-sec">
                <h3>Set</h3>
                <div>
                  <div className="player-locked-button justify-content-between">
                    <span>
                      {currentPlayer?.is_user_bet
                        ? currentPlayer?.is_user_bet?.wager_set_value
                        : currentPlayer?.set_value?.set_value || 0}
                    </span>

                    <button type="button" className="btn">
                      {/* {(currentPlayer?.status == 1 || currentPlayer?.status == 3) ? <div>Locked</div> : currentPlayer?.other_status == 2 ? <div className="green-col">Scratched</div> : currentPlayer?.other_status == 1 ? <div className="green-col">Re-ride</div> :(currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.score_type == 1 ? <div className="green-col">Push</div> :  currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.score_type==2 ? <div className="green-col">{`Win+${currentPlayer?.is_user_bet?.point}`}</div> : currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.score_type==0 ? <div className="red-col">{`Loss-${currentPlayer?.is_user_bet?.point}`}</div> :(currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.created_at) ? <div>Locked</div> : <h4>- -</h4>)} */}
                      {currentPlayer?.status == 4 || currentPlayer?.status == 3 ? (
                        <div>Locked</div>
                      ) : currentPlayer?.other_status == 2 ? (
                        <div className="green-col">Scratched</div>
                      ) : currentPlayer?.other_status == 1 ? (
                        <div className="green-col">Re-ride</div>
                      ) : currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.score_type == 1 ? (
                        <div className="green-col">Push</div>
                      ) : currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.score_type == 2 ? (
                        <div className="green-col">{`Win+${currentPlayer?.is_user_bet?.point}`}</div>
                      ) : currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.score_type == 0 ? (
                        <div className="red-col">{`Loss-${currentPlayer?.is_user_bet?.point}`}</div>
                      ) : currentPlayer?.status == 2 ? (
                        <div>Locked</div>
                      ) : (
                        <h4>- -</h4>
                      )}
                    </button>
                  </div>
                  {/* <a href="javascript:void(0)" onClick={()=>showInfo(currentPlayer?.set_value_info)}>
                  <img src="/images/i-sing.png"  />
                </a> */}
                </div>
                {currentPlayer?.is_user_bet?.score_type == 2 && winningUsersData && (
                  <ConfettiExplosion force={0.6} duration={3000} particleCount={200} width={1000} />
                )}
              </div>
              <div className="player-inner-sec player-wager-sec">
                <h3>Wager</h3>
                <div>
                  {/* {((currentPlayer && currentPlayer?.is_user_bet && currentPlayer?.is_user_bet.created_at) || (currentPlayer?.status == 1 || currentPlayer?.status == 3  || currentPlayer?.other_status == 2)) ? */}
                  {[2, 3, 4].includes(currentPlayer?.status) || currentPlayer?.other_status == 2 ? (
                    <div className="player-locked-button">
                      <span>
                        {currentPlayer && currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.point
                          ? currentPlayer?.is_user_bet?.point
                          : 0}
                      </span>
                    </div>
                  ) : (
                    <div className="wag-inp">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          currentPlayer && currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.point
                            ? currentPlayer?.is_user_bet?.point
                            : 0
                        }
                        onClick={() => {
                          clickEvent(currentPlayer, currentIndex);
                        }}
                      />

                      <button
                        type="button"
                        className="btn p1"
                        onClick={() => downArrowClick(currentPlayer, currentIndex)}
                      >
                        <img src="/images/wh-do.png" />
                      </button>
                      <button
                        type="button"
                        className="btn p2"
                        onClick={() => upArrowClick(currentPlayer, currentIndex)}
                      >
                        <img src="/images/wh-up.png" />
                      </button>
                    </div>
                  )}
                  {/* <a href="javascript:void(0)" onClick={()=>showInfo(currentPlayer?.wager_info)}>
                    <img src="/images/i-sing.png"/>
                  </a> */}
                </div>
              </div>
              <div className="player-inner-sec">
                <h3>ACTUAL SCORE</h3>

                <div>
                  (
                  <div
                    className=// {((currentPlayer && currentPlayer?.is_user_bet && currentPlayer?.is_user_bet.created_at) || (currentPlayer?.status == 1 || currentPlayer?.status == 3  || currentPlayer?.other_status == 2)) ?
                    {
                      [2, 3, 4].includes(currentPlayer?.status) || currentPlayer?.other_status == 2
                        ? "player-locked-button"
                        : "player-button"
                    }
                  >
                    <span>{`${currentPlayer && currentPlayer.score != null ? currentPlayer.score : "- -"}`}</span>
                  </div>
                  )
                  {/* <a href="javascript:void(0)" onClick={()=>showInfo(currentPlayer?.actual_time_info)}>
                    <img src="/images/i-sing.png"/>
                  </a> */}
                </div>
              </div>

              <div className="point-sec">
                <div>
                  {[2, 3, 4].includes(currentPlayer?.status) || currentPlayer?.other_status == 2 ? (
                    currentPlayer?.is_user_bet?.wager_type == "O" ? (
                      <button type="button" className="btn btn-white">
                        Over
                      </button>
                    ) : currentPlayer?.is_user_bet?.wager_type == "U" ? (
                      <button type="button" className="btn btn-white">
                        Under
                      </button>
                    ) : null
                  ) : (
                    <>
                      <button
                        type="button"
                        className={`btn ${
                          currentPlayer &&
                          currentPlayer?.is_user_bet &&
                          currentPlayer?.is_user_bet?.created_at &&
                          currentPlayer?.is_user_bet?.wager_type == "O"
                            ? "active"
                            : // ? "btn-white"
                              ""
                        }`}
                        onClick={() => {
                          onOverUnderClick(currentPlayer, "O");
                        }}
                      >
                        Over
                      </button>
                      <button
                        type="button"
                        className={`btn ${
                          currentPlayer &&
                          currentPlayer?.is_user_bet &&
                          currentPlayer?.is_user_bet?.created_at &&
                          currentPlayer?.is_user_bet?.wager_type == "U"
                            ? "active"
                            : // ? "btn-white"
                              ""
                        }`}
                        onClick={() => {
                          onOverUnderClick(currentPlayer, "U");
                        }}
                      >
                        Under
                      </button>
                    </>
                  )}
                </div>
                {/* {((currentPlayer?.other_status !=2)) ?
                <div>
                  {(currentPlayer?.is_user_bet?.wager_type=="O")?
                      <button type="button" className="btn btn-white">
                      Over
                      </button>
                    :
                    (currentPlayer?.is_user_bet?.wager_type=="U")?
                      <button type="button" className="btn btn-white">
                        Under
                      </button>
                    :
                    <>
                      <button type="button"
                        className={`btn ${
                          currentPlayer && currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.created_at && currentPlayer?.is_user_bet?.wager_type=="O"
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          onOverUnderClick(currentPlayer, "O");
                      }}>
                        Over
                      </button>
                      <button type="button" className={`btn ${
                          currentPlayer && currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.created_at && currentPlayer?.is_user_bet?.wager_type=="U"
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          onOverUnderClick(currentPlayer, "U");
                      }}>
                        Under
                      </button>
                    </>
                  }
                </div>:null} */}
              </div>
            </div>
            <div className="footer-btm">
              <div onClick={() => changeShowFooter(showFooter)} className="text-center">
                {/* <i className={"fa fa-arrow-"+(showFooter ? "up" : "down")} /> */}
                <img src={"/images/wh-" + (!showFooter ? "up" : "do") + ".png"} width={"20px"} />
              </div>
              {showFooter && (
                <>
                  <div className="player-normal-text">
                    <p>Penalties and No Score/No Time results in a Scratch and No Points Lost</p>
                  </div>
                  <div className="next-btn">
                    <div className="row">
                      <div className="col-12">
                        <div className="next-btn-text points-2-col">
                          <div>
                            <h3>
                              Points Available: <span>{Number(getPoint)?.toFixed(1)}</span>
                            </h3>
                            {getPoint && getPoint < 0 ? (
                              <a
                                className="info-icon"
                                onClick={() => {
                                  setShowStatusModal(true);
                                  setStatusModalMesssage(negativePointInfo);
                                }}
                              >
                                <i className="fa fa-info-circle" aria-hidden="true" />
                              </a>
                            ) : null}
                          </div>
                          <div>
                            <h3>
                              Points Earned: <span>{Number(earnedPoints)?.toFixed(1)}</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="next-btn-inner">
                          {currentIndex != 0 ? (
                            <button
                              type="button"
                              className="btn mr-6"
                              onClick={() => {
                                changePage(-1);
                              }}
                            >
                              Prev{" "}
                            </button>
                          ) : (
                            <button type="button" className="btn btn-disable mr-6">
                              Prev
                            </button>
                          )}

                          {currentIndex != playersList?.length - 1 ? (
                            <button
                              type="button"
                              className="btn"
                              onClick={() => {
                                changePage(1);
                              }}
                            >
                              Next
                            </button>
                          ) : (
                            <button type="button" className="btn btn-disable">
                              Next
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="container">
            <div className="contestants">
              {/* {numbperPadShow &&  (
                <NumberPad
                  numbperPadShow={numbperPadShow}
                  handleClose={onclose}
                  getValue={getVal}
                  sendInputValue={sendInputValue}
                  sendItemId={sendItemId}
                  sendItemIndex={sendItemIndex}
                  getPoint={getPoint}
                  onchngFunc={onchngFunc}
                />
              )} */}
              {numbperPadShow && getPoint >= 0 && playerDetailsApiCalled && (
                <NumberPad
                  show={numbperPadShow}
                  onClose={onNumpadClose}
                  existingBetPoints={currentPlayer?.is_user_bet?.point || 0}
                  setFinalPoints={setFinalPoints}
                  totalAvailablePoints={getPoint}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showStatusModal && statusModalMesssage && (
        <StatusModal shouldShow={showStatusModal} onClose={onStatusModalClose} message={statusModalMesssage} type={1} />
      )}
    </React.Fragment>
  );
}
