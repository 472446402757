import React from 'react';

const LazyRodeoEventLeaderBoard = React.lazy(() =>
    import('./reset-password')
);

const ResetPassword = (props: Record<string, any>) => (
    <React.Suspense fallback={<h1>Loading...</h1>}>
        <LazyRodeoEventLeaderBoard {...props} />
    </React.Suspense>
);

export default ResetPassword