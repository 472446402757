import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "src/components/common/Header";
import Sidebar from "src/components/common/Sidebar";
import Sponsor from "src/components/common/Sponsor";
import { useAppPerformanceInfoSelector, useAppPerformanceListSelector, useAppRodeoEventSettingsSelector } from "src/_common/hooks/selectors/eventSelector";
import { CRYPTO_SECRET_KEY, RODEO_PAGE_NAME, URLS } from "src/_config";

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function PrizesListPage() {

    const history = useHistory()
    const { rodeoEventId, performanceId } = useParams<any>()
    const rodeoEventSettings = useAppRodeoEventSettingsSelector()
    const performanceList = useAppPerformanceListSelector()
    const types = ["Game", "Performance", "Event"]
    const [sponsorDetails, setSponsorDetails] = useState<any>()

    const gotoPrizeDetailsPage = (type: any) => {
        history.push(`/prize/${rodeoEventId}/${performanceId}/${cryptr.encrypt(type)}`)
    }

    const gotoPerformancePage = () => {
        history.push(`/performance/${rodeoEventId}`)
    }

    const gotoEventPage = () => {
        history.push(`/events/${rodeoEventId}/${performanceId}`)
    }

    useEffect(()=>{
        if(performanceList && performanceList.length) {
            let pId = cryptr.decrypt(performanceId)
            let x = performanceList.find((p:any)=>p.id==pId)
            if(x) {
                setSponsorDetails(x.sponsor)
            }
        }
    },[performanceList])

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <Sidebar page={RODEO_PAGE_NAME.PRIZES_LIST} />
                </div>                
            </div>
            <div className="main-body">
                <Header type='full' />   
                <div className="back-sec back-sec-two">
                <div className="container">
                    <div className="row">
                    <div className="col-12">
                        <div className="back-sec-one">                        
                            <img src="/images/back.png" onClick={gotoPerformancePage}/>                        
                        </div>
                        <h3>PRIZES</h3>
                    </div>
                    </div>
                </div>
                </div>
                <div className="inner_all gap_between_content">
                    {types.map((type:any, i:any) =>
                        (rodeoEventSettings["is_"+type.toLowerCase()+"_prize"] ? 
                            <div className="inner_all_sec prize-list" onClick={()=>gotoPrizeDetailsPage(type)} key={i}>
                                <div className="row">
                                    <div className="col-2" style={{color: "white"}}>
                                        <i className={type=="Game" ? "fas fa-trophy" : type=="Performance" ? "fa fa-shield" : "fa fa-flag" }/>
                                    </div>
                                    <div className="col-10">
                                        <h3>{type} Prize</h3>
                                    </div>
                                </div>
                            </div>
                            : null
                        )
                    )}
                </div>
                <div className="event-btn">
                    <button className="btn" onClick={gotoEventPage}>
                        Play Now
                    </button>
                </div>
            </div>
            {sponsorDetails && 
                <Sponsor
                    details={sponsorDetails}
                />
            }
        </React.Fragment>
    );
}
