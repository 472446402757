import React, { useEffect, useState } from "react";
import useSocket from "use-socket.io-client";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "src/components/common/Sidebar";
import { useCmsApi } from "src/_common/hooks/actions/cms/cmsApiHook";
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { useAppAvailablePointsSelector, useAppEarnedPointsSelector, useAppEventDetailsSelector, useAppEventLeaderBoardSelector, useAppPerformanceInfoSelector, usePlayersDetailsSelector, usePlayersListSelector, useTotalContestentDataSelector } from "src/_common/hooks/selectors/eventSelector";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import { APPLICATION_GLOBAL_SLUG, APP_USER_ID, CRYPTO_SECRET_KEY, GUEST_USER_ID, removeTrailingZero, RODEO_PAGE_NAME, SOCKET_CHANNEL, SOCKET_URL, URLS } from "src/_config";
// import NumberPad from "src/_common/numberpad/numberPad";
import NumberPad from "src/_common/numberpad/numberPad copy";
import StatusModal from "../modal/statusModal";
import Header from "../../common/Header";
import ConfettiExplosion from "react-confetti-explosion";
import Sponsor from "src/components/common/Sponsor";
import GeoLocation from "src/components/common/GeoLocation";

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);


export default function PlayerVsBullDetailsPage() {

  const urlParams = useParams<any>()
  const userDetails:any = useAppUserDetailsSelector()
  const eventApi = useEventApi()
  const guest_user_id = localStorage.getItem(GUEST_USER_ID)
  const [leaderboardStatus, setLeaderboardStatus] = useState<any>("");
  const [remainingBet, setRemainingBet] = useState<any>(0)
  const [currentPlayer,setCurrentPlayer] = useState<any>()
  const [currentBull,setCurrentBull] = useState<any>()
  const [getPoint, setPoints] = useState<any>()
  const eventDetails = useAppEventDetailsSelector()
  const performanceInfo = useAppPerformanceInfoSelector()
  const history = useHistory()
  const totalData = useTotalContestentDataSelector()
  const earnedPoints = useAppEarnedPointsSelector()
  const [statusModalMesssage, setStatusModalMesssage] = useState<any>();
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const negativePointInfo = "If an event’s score is corrected after picks are settled, for example due to an appeal or a data entry error, then all picks will be recalculated taking the correction into account.  In some cases, that may lead to a negative balance.  If you have a negative balance, you cannot place any additional wagers until and unless your balance becomes positive from the settlement of other picks you have already placed."
  const availablePoints = useAppAvailablePointsSelector()
  const [typeSelected, setTypeSelected] = useState<any>()
  const playersList = usePlayersListSelector()
  const eventAction = useAppEventAction()
  const [intervalId, setIntervalId] = useState<any>()
  const [sponsorDetails, setSponsorDetails] = useState<any>()
  const [numberPadShow, setNumberPadShow] = useState<boolean>(false);
  const [winningUsersData, setWinningUsersData] = useState<any>();
  const [ socketData, setSocketData ] = useState<any>()

  const socketChannels = [ SOCKET_CHANNEL.OU_EVENT_DETAILS, SOCKET_CHANNEL.START_EVENT, SOCKET_CHANNEL.OU_BET_ENABLE, SOCKET_CHANNEL.OU_RESET_CONTESTANT, SOCKET_CHANNEL.OU_EMIT_SCORE ]

  const setFinalPoints = (val: any) => {
    eventAction.setAvailablePoints(val)
  }
  
  const onStatusModalClose = () => {
    setShowStatusModal(false)
    setStatusModalMesssage("")
  }

  // when user clicked on number numberpad show
  const clickEvent = (type?: any) => {
    let item:any = null
    if(type=="player") {
      item = currentPlayer
    } else if(type=="bull") {
      item = currentBull
    }
    if(availablePoints < 0) {
      setShowStatusModal(true)
      setStatusModalMesssage("You don't have enough available points for picking this contestant.")
      return
    }
    // if(currentPlayer && item.id!=currentPlayer.id) {
    //   setShowStatusModal(true)
    //   setStatusModalMesssage("Please select your pick Over/Under for "+currentPlayer.contestant_name)
    //   return
    // }
    // if (item?.id && getPerformanceInfo && getPerformanceInfo && getPerformanceInfo?.wager_status == 1 && item?.status==0) {
    if(performanceInfo && performanceInfo?.wager_status == 1 && [0,1].includes(item?.status) && eventDetails?.status!=2) {
      setTypeSelected(type)
      setNumberPadShow(true)
      // setSendInputValue(playersList);
      // setSendItemId(item.id);
      // setSendItemIndex(index);
    }
  }

  // number pad hide
  const onNumpadClose = (val: any) => {
    setNumberPadShow(false)
    // if(val) {
      updateBetPoint(val)
    // }
  }

  const updateBetPoint = (val: any) => {

    let item:any = null
    if(typeSelected=="player") {
      item = currentPlayer
    } else if(typeSelected=="bull") {
      item = currentBull
    }
    let updatedArray: any = {
      ...item,
      is_user_bet: {
        point: val,
        wager_set_value: item?.is_user_bet?.wager_set_value
          ? item?.is_user_bet?.wager_set_value
          : item?.set_value?.set_value || 0,
        created_at: null,
      },
    }
    if(typeSelected=="player") {
      setCurrentPlayer(updatedArray)
    } else if(typeSelected=="bull") {
      setCurrentBull(updatedArray)
    }
  }

  const handleMouseDown = (arrow: any, type: any, mousedown: boolean) => {
    let c=0
    let item:any = null
    if(type=="player") {
      item = currentPlayer
    } else if(type=="bull") {
      item = currentBull
    }    
    let tempPoints=item?.is_user_bet?.point || 0
    setIntervalId(setInterval(()=>{
      console.log("MouseDown",getPoint,tempPoints,c)
      c=c+1
      if(availablePoints>=c) {
        setTypeSelected(type)
        item = upDownArrowClick(arrow, type, mousedown)
        // if(type=="player") {
        //   setCurrentPlayer(item)
        // } else if(type=="bull") {
        //   setCurrentBull(item)
        // }
        updateBetPoint(Number(tempPoints)+c)
        console.log({item})
        eventAction.setAvailablePoints(availablePoints - c)
        // setMousedownPoints(c)
      }
    },500))
    // setIntervalId(setInterval(()=>{
    //     upDownArrowClick(type, arrow, mousedown)
    //     // eventAction.setTotalDataAvailablePoints(getPoint - c)
    // },500))
  }

  const handleMouseUp = () => {
    clearInterval(intervalId)
  }

  const getPlayersDetails = () => {
    const params: any = {
      player_id: cryptr.decrypt(urlParams.playerId),
    };

    if(userDetails && !guest_user_id){
      localStorage.setItem(APP_USER_ID, userDetails?.id)
    }

    if (guest_user_id && !userDetails) {
      params["guest_user_id"] = guest_user_id;
    }

    eventApi.callGetPlayersDetails(params,
      (message: string, resp: any) => {
       // console.log({resp})
        eventAction.setEventDetails(resp.rodeo_event_event_dtls)
        setCurrentPlayer(resp.details)
        setCurrentBull(resp.details.contestant_bull.bull_details)
        if(resp.details.is_user_bet) {
          setTypeSelected("player")
        } else if(resp.details.contestant_bull.bull_details.is_user_bet) {
          setTypeSelected("bull")
        }
        eventAction.setAvailablePoints(resp.available_poins.point)
        // setPoints(resp.available_poins.point)
        if(resp.min_bet_leaderboard!=0) {
          if(resp.user_remaining_bet==0) {
            setLeaderboardStatus("green")
          }
          else if(resp.user_remaining_bet>0) {
            setLeaderboardStatus("red")
            setRemainingBet(resp.user_remaining_bet)
          }
        }
        else {

        }
      },
      (message: string) => {

      }
    )
  }

  const goToPlayerPage = () => {
    history.push(`/players/${urlParams.rodeoEventId}/${urlParams.performanceId}/${urlParams.eventId}`)
  }

  const onOverUnderClick = (item: any, wager_type: any) => {
    if(availablePoints < 0) {
      setShowStatusModal(true)
      setStatusModalMesssage("You don't have enough available points for picking this contestant.")
      return
    }
    // if(currentPlayer && item.id!=currentPlayer.id) {
    //   setShowStatusModal(true)
    //   setStatusModalMesssage("Please select your pick Over/Under for "+currentPlayer.contestant_name)
    //   return
    // }
    // if(ContestantAllData && ContestantAllData?.available_poins && ContestantAllData?.available_poins?.point < 0) {
    //   setShowStatusModal(true)
    //   setStatusModalMesssage("You don't have enough available points for picking this contestant.")
    //   return
    // }
    // if(getPerformanceInfo && getPerformanceInfo?.wager_status == 1 && [0,1].includes(item?.status) && ContestantAllData?.rodeo_event_event_dtls?.status!=2)
    // {
    if(performanceInfo && performanceInfo?.wager_status == 1 && [0,1].includes(item?.status) && eventDetails?.status!=2) {
      var parms: any = {
        rodeo_event_id: item?.rodeo_event_id,
        performance_id: item?.performance_id,
        wager_type: wager_type,
        event_id: item?.rodeo_event_event_id,
        contestant_id: typeSelected=="player" ? currentPlayer.id : null,
        bull_id: typeSelected=="bull" ? currentBull.id : null,
        point:
          item?.is_user_bet && item?.is_user_bet?.point
            ? item?.is_user_bet?.point
            : 0,
        wager_set_value: item?.set_value?.set_value,
      };
  
      if (guest_user_id && !userDetails) {
        parms["guest_user_id"] = guest_user_id;
      }
      let geoId = localStorage.getItem("geolocation_val")
      if(geoId) {
        parms['location_id'] = geoId
      } else {
        parms['location_id'] = null
      }
      let lat = localStorage.getItem("latitude")
      let lng = localStorage.getItem("longitude")
      parms['lat'] = lat
      parms['lng'] = lng
  
      if(parms?.point!=0){
        eventApi.callSubmitPlayersBettingPoints(
          parms,
          (message: string, resp: any) => {
              let today = new Date();
              if(typeSelected=="player") {
              let x = {
                ...currentPlayer,
                is_user_bet: {
                  point: parms.point,
                  wager_set_value: parms.wager_set_value,
                  created_at: today,
                  wager_type: wager_type
                },
              }
              setCurrentPlayer(x)
            } else if(typeSelected=="bull") {
              let x = {
                ...currentBull,
                is_user_bet: {
                  point: parms.point,
                  wager_set_value: parms.wager_set_value,
                  created_at: today,
                  wager_type: wager_type
                },
              }
              setCurrentBull(x)
            }
            toast.success(message);
          },
          (message: string) => {
            toast.error(message);
          }
        );
      }
      else{
        toast.error("Enter your pick first!");
      }
    }
  }

  const upDownArrowClick = (arrow: any, type: any, mousedown?: boolean) => {
    let item:any = null
    if(type=="player") {
      item = currentPlayer
    } else if(type=="bull") {
      item = currentBull
    }
    if(availablePoints < 0) {
      setShowStatusModal(true)
      setStatusModalMesssage("You don't have enough available points for picking this contestant.")
      return
    }
    // if(currentPlayer && item.id!=currentPlayer.id) {
    //   setShowStatusModal(true)
    //   setStatusModalMesssage("Please select your pick Over/Under for "+currentPlayer.contestant_name)
    //   return
    // }
    if(performanceInfo && performanceInfo?.wager_status == 1 && [0,1].includes(item?.status) && eventDetails?.status!=2) {
      if ((arrow=="up" && availablePoints && (availablePoints > 0.9))||(arrow=="down" && availablePoints >=0)) {
        setTypeSelected(type)
        console.log({item})
        let numbers = Number(
          item?.is_user_bet?.point ? item?.is_user_bet?.point : 0
        );
        let AddingNumber = numbers + ((arrow=="up") ? 1 : -1);
        let updatedArray: any = {
          ...item,
          is_user_bet: {
            point: AddingNumber,
            wager_set_value: item?.is_user_bet?.wager_set_value
              ? item?.is_user_bet?.wager_set_value
              : item?.set_value?.set_value || 0,
            created_at: null,
          },
        }
        if (AddingNumber>=0 && !mousedown) {
          if(type=="player") {
            setCurrentPlayer(updatedArray)
          } else if(type=="bull") {
            setCurrentBull(updatedArray)
          }
          eventAction.setAvailablePoints(availablePoints-((arrow=="up") ? 1 : -1))
        }
        return updatedArray
      }
    }
  }

  const resetBet = () => {
    console.log({typeSelected})
    let params: any = {
      rodeo_event_id: cryptr.decrypt(urlParams.rodeoEventId),
      performance_id: cryptr.decrypt(urlParams.performanceId),
      event_id: cryptr.decrypt(urlParams.eventId),
      contestant_id: typeSelected=="player" ? currentPlayer.id : null,
      bull_id: typeSelected=="bull" ? currentBull.id : null, 
    }
    if(userDetails && !guest_user_id){
      localStorage.setItem(APP_USER_ID, userDetails?.id)
    }
    if (guest_user_id && !userDetails) {
      params["guest_user_id"] = guest_user_id;
    }
    eventApi.callResetBet(params,
      (message: any, resp: any)=>{
        console.log({resp})
        eventAction.setAvailablePoints(resp.available_points.point)
        // if(typeSelected=="player") {
        //   let x = {
        //     ...currentPlayer,
        //     is_user_bet: null
        //   }
        //   setCurrentPlayer(x)
        // } else if(typeSelected=="bull") {
        //   let x = {
        //     ...currentBull,
        //     is_user_bet: null
        //   }
        //   setCurrentBull(x)
        // }
        setCurrentPlayer({...currentPlayer,is_user_bet: null})
        setCurrentBull({...currentBull,is_user_bet: null})
        setTypeSelected("")
      },
      (message: any)=>{

      }
    )
  }

  const onContestantClick = (type: any, item:any) => {
    //console.log(item)
    if(performanceInfo?.wager_status == 0)
    {
      setShowStatusModal(true)
      setStatusModalMesssage("Picks are not open yet")
    }
    else if (eventDetails?.status==2){
      setShowStatusModal(true)
      setStatusModalMesssage("Picking has ended.")
    }
    else
    {
      // if(item && item?.is_user_bet && item?.is_user_bet?.created_at)
      // {
      //   setShowStatusModal(true)
      //   setStatusModalMesssage("You already placed bet for this contestant.")
      // }
      // else if(item && item?.status == 4)
      if(item && item?.status == 4)
      {
        setShowStatusModal(true)
        setStatusModalMesssage("Performance of this " + ( type=="player" ? "contestant" : "bull" ) + " already started, wait for the result.")
      }
      else if(item && item?.status == 2)
      {
        setShowStatusModal(true)
        setStatusModalMesssage(( type=="player" ? "Contestant" : "Bull" ) + " has completed the ride.")
      }
      else if(item && item?.other_status == 2)
      {
        setShowStatusModal(true)
        setStatusModalMesssage(( type=="player" ? "Contestant" : "Bull" ) + " has been scratched")
      }
      else {
        if(type=="bull" && !currentPlayer?.is_user_bet?.created_at && currentPlayer?.is_user_bet?.point>=0) {
          setShowStatusModal(true)
          setStatusModalMesssage("Please pick Over/Under for "+currentPlayer?.contestant_name)
        }
        if(type=="player" && !currentBull?.is_user_bet?.created_at && currentBull?.is_user_bet?.point>=0) {
          setShowStatusModal(true)
          setStatusModalMesssage("Please pick Over/Under for "+currentBull?.bull_name)
        }
        if(type=="bull" && currentPlayer?.is_user_bet?.created_at) {
          setShowStatusModal(true)
          setStatusModalMesssage("You already placed for "+currentPlayer?.contestant_name)
        }
        if(type=="player" && currentBull?.is_user_bet?.created_at) {
          setShowStatusModal(true)
          setStatusModalMesssage("You already placed for "+currentBull?.bull_name)
        }
      }
    }
  }

  useEffect(()=>{
    getPlayersDetails()
  },[urlParams])

  const [socket] = useSocket(SOCKET_URL, {
    autoConnect: false,
  });

  useEffect(() => {
    // console.log({currentPlayer})
    // console.log({currentBull})
    if(socketData && currentPlayer && currentBull) {
      
      switch(socketData.channel) {

        case SOCKET_CHANNEL.OU_EVENT_DETAILS:
          console.log(socketData.data)
          eventAction.playerListStatusUpdate(socketData.data);
        break;

        case SOCKET_CHANNEL.START_EVENT:
          console.log("StartEvent>>>",socketData.data)

          if(socketData.data?.event_info?.status==0) {
            window.location.reload()
          }
          eventAction.pushEventStatusToTotalData(socketData.data.event_info)

          let c = socketData.data?.contestants?.find((x:any)=>x.id==socketData.data?.contestant_id)
          if(c) {
            // console.log({currentPlayer})
            // console.log({currentBull})
            setCurrentPlayer({...currentPlayer,status: c.status})
            setCurrentBull({...currentBull,status: c.status})
          }
          if((c && !([1,4].includes(c.status))) || (c && c.other_status && c.other_status==2)) {
            // getInnerEventList()
            window.location.reload()
          }
          if(c?.status==4) {
            // setCurrentPlayer(null)
          }
          // eventAction.playerListStatusUpdate({current_contestant:c});
        break;

        case SOCKET_CHANNEL.OU_BET_ENABLE:
          console.log(socketData.data)
          eventAction.playerListAllDataAndStatusChangesUpdate(socketData.data);
        break;

        case SOCKET_CHANNEL.OU_RESET_CONTESTANT:
          console.log(socketData.data)
          // eventAction.playerListResetContestents(socketData.data);
          let y: any = null
          if(!socketData.data?.type){
            y = {
              other_status: 1,
              status: 0,
              score: null
            }
          }
          else if(socketData.data?.type && socketData.data?.type=="scratched"){
            y = {
              other_status: 2,
              status: 0,
              score: null,
              is_user_bet: null
            }
          }
          setCurrentPlayer({...currentPlayer,...y})
          setCurrentBull({...currentBull,...y})
        break;

        case SOCKET_CHANNEL.OU_EMIT_SCORE:
          console.log(socketData.data)
          if(socketData.data?.mode && socketData.data?.mode=="edit") {
            // getInnerEventList()
            window.location.reload()
            return
          }
          let user = socketData.data.user.find((x:any)=>{
            if((userDetails && x.user_id==userDetails.id) || (guest_user_id && guest_user_id==x.guest_user_id)) {
              return x
            }
          })

          let player:any = {...currentPlayer}
          let bull:any = {...currentBull}
          let x = {
            status: 2,
            other_status:0
          }
          if(socketData.data.event_id==eventDetails.id) {
            player = {...player,...x,score:socketData.data.score}
            bull = {...bull,...x,score:socketData.data.bull_score}
          }
          if(user) {
            console.log(user)
            setWinningUsersData(user)
            if([0,1,2,3,4].includes(user.score_type)) {
              // let x = {
              //   is_user_bet: {
              //     score_type: user.score_type
              //   }
              // }
              if(typeSelected=="player") {
                player = {...player,is_user_bet:{...player.is_user_bet, score_type: user.score_type}}
              } else {
                bull = {...bull,is_user_bet:{...bull.is_user_bet, score_type: user.score_type}}
              }
              // playersEmit[findGameIndex].is_user_bet.score_type = socketData.data.user[findSocketUserIndex].score_type
              if(user.score_type!=1) {
                // available_point.available_poins.point= (available_point.available_poins.point + socketData.data.user[findSocketUserIndex].score)
                // earnedPoints = earnedPoints + socketData.data.user[findSocketUserIndex].score
                eventAction.setAvailablePoints(Number(availablePoints) + Number(user.score))
                eventAction.setEarnedPoints(Number(earnedPoints) + Number(user.score))
              }
              else {
                // available_point.available_poins.point= (available_point.available_poins.point + socketData.data.user[findSocketUserIndex].point)
                eventAction.setAvailablePoints(Number(availablePoints) + Number(user.point))
              }
            }
          }
          setCurrentPlayer(player)
          setCurrentBull(bull)
          // eventAction.playerListEmitScore(socketData.data)
        break;
      }
      setSocketData(null)
      // setWinningUsersData(null)
    }
  }, [socketData, currentPlayer, currentBull])

  useEffect(() => {

      socket.connect()

      socketChannels.map((item:any)=>
          socket.on(item, (data: any) => {                
              // console.log(data)
              // setSiteDataChannelValues(GLOBAL_SLUGS.OVER_UNDER, data, item)
              setSocketData({channel: item, data})
          }))

      return () => {
          socket.disconnect()
      }

  }, [])

  // useEffect(()=>{
  //   let a={x:{1:1}} 
  //   let b={x:{2:2}} 
  //   console.log({...a.x,...b.x})
  // },[])

  useEffect(()=>{
    if(winningUsersData) {
      setTimeout(()=>setWinningUsersData(null),2500)
    }
  },[winningUsersData])

  return (
    <React.Fragment>
      <GeoLocation />
      <div className="event-sec over-hid">
        <div className="container">
          <div className="lite-logo">
            <div className="row">
              <div className="col-9">
              <Sidebar page={RODEO_PAGE_NAME.CONTESTANT_DETAILS} />
              </div>
              <div className="col-3">
              <Header type='side' />
              </div>
            </div>
          </div>
          <div className={leaderboardStatus=="red" ? leaderboardStatus+"-leaderboard-status" : "d-none"}>
            {leaderboardStatus=="red"?"Pick "+remainingBet+" more to get qualified":"You have successfully chosen your pick"}
          </div>
          <div className="back-sec back-sec-two">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="back-sec-one">
                      <img src="/images/back.png" onClick={() => {
                      goToPlayerPage();
                    }}/>
                  </div>
                  <h3>{eventDetails?.event_title}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="event-btn event-btn-span">
              <a>
              <button type="button" className="btn points-btn">
                  {/* {totalData?.performance_info && ( */}
                  <span>
                      {/* {totalData?.performance_info && totalData?.performance_info?.wager_status == 1 ? "Rodeo is LIVE!" : "Not Started yet"}totalData */}
                      {totalData?.rodeo_event_event_dtls?.status == 2 ? "Show Ended!": totalData?.rodeo_event_event_dtls?.status == 1 ? "Show is LIVE!" : "Show Not Yet Started"}
                  </span>
                  {/* )} */}
                  <span>
                  <p>Points Available: {availablePoints ? availablePoints.toFixed(1):"0.0"}
                      {
                      availablePoints && availablePoints<0 ?
                        <a className="info-icon"
                          // onClick={()=>{setShowStatusModal(true);setStatusModalMesssage(negativePointInfo)}}
                        >
                            <i className="fa fa-info-circle" />
                        </a>
                        : null
                      }
                  </p>
                  <p>Points Earned:  {earnedPoints?.toFixed(1) || "0.0"}</p>
                  </span>
              </button>
              </a>
          </div>
          <div className={`contestants-body ${currentPlayer?.status==1 ? " lighting-bg" : ""}`} >
            {currentPlayer?.is_user_bet?.score_type==2 && winningUsersData &&  typeSelected=="player" &&
              <ConfettiExplosion
                force={0.6}
                duration={2500}
                particleCount={200}
                width={1000}
              />
            }
            {/* <Link to={URLS.EVENT.PLAYER_DETAILS} className="back-history"> */}
            <h2
              // onClick={() => {
              //   goToPlayerDetailsPage(item);
              // }}
              className="name-wrap"
            >
              {currentPlayer?.contestant_name}
              {typeSelected=="player" && (performanceInfo && performanceInfo?.wager_status == 1 && [0,1].includes(currentPlayer?.status) && eventDetails?.status!=2) && <div className="reset-btn" onClick={resetBet}>
                {/* <i className="fa fa-refresh" /> */}
                RESET
              </div>}
            </h2>
            {/* </Link> */}
            <div className="row">
              <div className="col-3">
                <div className="contestants-img">
                  <img
                    src={
                      currentPlayer?.avatar && currentPlayer?.avatar?.id == 0
                        ? currentPlayer?.contestant_dtls?.avatar && currentPlayer?.contestant_dtls?.avatar?.id == 0
                        ? '/images/no-image.png'
                        : currentPlayer?.contestant_dtls?.avatar?.thumb
                        : currentPlayer?.avatar?.thumb
                    }
                    // alt="#"
                    // onClick={() => {
                    //   goToPlayerDetailsPage(currentPlayer);
                    // }}
                  />
                </div>
              </div>
              <div
                className={`col-9 `}
                onClick={() => onContestantClick("player", currentPlayer)}
              >
                <div
                  className={`contestants-body-in ${
                    ([2,3,4].includes(currentPlayer?.status)  || currentPlayer?.other_status == 2) || typeSelected=="bull"
                      ? "disables-button"
                      : ""
                  }`}
                >
                  <div className="row">
                    <div className="col-3">
                      <div className="contestants-body-in-one">
                        <span>Wager</span>
                        <button
                          type="button"
                          className={`btn`}
                          onClick={() => upDownArrowClick("up","player")}
                          onPointerDown={()=>handleMouseDown("up","player", true)}
                          onPointerUp={()=>handleMouseUp()}
                          onPointerLeave={()=>handleMouseUp()}
                          onPointerCancel={()=>handleMouseUp()}                            
                        >
                          {/* {intervalId && currentPlayer?.id==item.id ? <div style={{height:"15px"}}/> : <img src="/images/up.png" />} */}
                          {/* <img src="/images/up.png" /> */}
                          <i className="fa-solid fa-chevron-up" style={{fontSize:"12px"}}/>
                        </button>
                        <h3
                          onClick={() => {
                            clickEvent("player")
                          }}
                        >
                          {/* {item?.number ? Number(item?.number).toFixed(2) : 0} */}
                          {currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.point
                            ? currentPlayer?.is_user_bet?.point
                            : 0}
                        </h3>
                        <button
                          type="button"
                          className={`btn`}
                          onClick={() => upDownArrowClick("down", "player")}
                          disabled={!currentPlayer?.is_user_bet || (currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.point == "0")}
                        >
                          {/* <img src="/images/down.png" alt="#" /> */}
                          <i className="fa-solid fa-chevron-down" style={{fontSize:"12px"}}/>
                        </button>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="contestants-body-in-two">
                        <span>Set</span>
                        <button
                          type="button"
                          className={`btn ${
                            currentPlayer && currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.created_at && currentPlayer?.is_user_bet?.wager_type=="O"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            onOverUnderClick(currentPlayer, "O");
                          }}
                        >
                          Over
                        </button>
                        <h3>
                          {currentPlayer?.is_user_bet
                            ? currentPlayer?.is_user_bet?.wager_set_value
                            : currentPlayer?.set_value?.set_value || 0}
                        </h3>
                        <button
                          type="button"
                          className={`btn ${
                            currentPlayer && currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.created_at && currentPlayer?.is_user_bet?.wager_type=="U"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            onOverUnderClick(currentPlayer, "U");
                          }}
                        >
                          Under
                        </button>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="contestants-body-in-three">
                        <span>Actual Score</span>
                        <h4>{currentPlayer && currentPlayer?.score!=null? currentPlayer?.score: "- -"}</h4>
                        <div className="status-bt">
                          <span>Status / Result</span>
                          {(currentPlayer?.status == 4 || currentPlayer?.status == 3) ? <h3>Locked</h3> : currentPlayer?.other_status == 2 ? <h3 className="green-col">Scratched</h3> : currentPlayer?.other_status == 1 ? <h3 className="green-col">Re-ride</h3> :(currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.score_type == 1 ? <h3 className="green-col">Push</h3> :  currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.score_type==2 ? <h3 className="green-col">{`Win+${currentPlayer?.is_user_bet?.point}`}</h3> : currentPlayer?.is_user_bet && currentPlayer?.is_user_bet?.score_type==0 ? <h3 className="red-col">{`Loss-${currentPlayer?.is_user_bet?.point}`}</h3> :(currentPlayer?.status == 2) ? <h3>Locked</h3> : <h4>- -</h4>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="vs-wrap">
            <span>vs</span>
          </div>
          <div className={`contestants-body ${currentBull?.status==1 ? " lighting-bg" : ""}`} >
            {currentBull?.is_user_bet?.score_type==2 && winningUsersData &&  typeSelected=="bull" &&
              <ConfettiExplosion
                force={0.6}
                duration={2500}
                particleCount={200}
                width={1000}
              />
            }
            {/* <Link to={URLS.EVENT.PLAYER_DETAILS} className="back-history"> */}
            <h2
              // onClick={() => {
              //   goToPlayerDetailsPage(item);
              // }}
              className="name-wrap"
            >
              {currentBull?.bull_name}
              {typeSelected=="bull" && (performanceInfo && performanceInfo?.wager_status == 1 && [0,1].includes(currentBull?.status) && eventDetails?.status!=2) && <div className="reset-btn" onClick={resetBet}>
                {/* <i className="fa fa-refresh" /> */}
                RESET
              </div>}
            </h2>
            {/* </Link> */}
            <div className="row">
              <div className="col-3">
                <div className="contestants-img">
                  <img
                    src={
                      currentBull?.avatar && currentBull?.avatar?.id == 0
                        // ? currentBull?.contestant_dtls?.avatar?.thumb
                        ? '/images/no-image.png'
                        : currentBull?.avatar?.thumb
                    }
                    // alt="#"
                    // onClick={() => {
                    //   goToPlayerDetailsPage(currentBull);
                    // }}
                  />
                </div>
              </div>
              <div
                className={`col-9 `}
                onClick={() => onContestantClick("bull", currentBull)}
              >
                <div
                  className={`contestants-body-in ${
                    ([2,3,4].includes(currentBull?.status)  || currentBull?.other_status == 2) || typeSelected=="player"
                      ? "disables-button"
                      : ""
                  }`}
                >
                  <div className="row">
                    <div className="col-3">
                      <div className="contestants-body-in-one">
                        <span>Wager</span>
                        <button
                          type="button"
                          className={`btn`}
                          onClick={() => upDownArrowClick("up","bull")}
                          onPointerDown={()=>handleMouseDown("up","bull", true)}
                          onPointerUp={()=>handleMouseUp()}
                          onPointerLeave={()=>handleMouseUp()}
                          onPointerCancel={()=>handleMouseUp()}                             
                        >
                          {/* {intervalId && currentBull?.id==item.id ? <div style={{height:"15px"}}/> : <img src="/images/up.png" />} */}
                          {/* <img src="/images/up.png" /> */}
                          <i className="fa-solid fa-chevron-up" style={{fontSize:"12px"}}/>
                        </button>
                        <h3
                          onClick={() => {
                            clickEvent("bull");
                          }}
                        >
                          {/* {item?.number ? Number(item?.number).toFixed(2) : 0} */}
                          {currentBull?.is_user_bet && currentBull?.is_user_bet?.point
                            ? currentBull?.is_user_bet?.point
                            : 0}
                        </h3>
                        <button
                          type="button"
                          className={`btn`}
                          onClick={() => upDownArrowClick("down","bull")}
                          disabled={!currentBull?.is_user_bet || (currentBull?.is_user_bet && currentBull?.is_user_bet?.point == "0")}
                        >
                          {/* <img src="/images/down.png" alt="#" /> */}
                          <i className="fa-solid fa-chevron-down" style={{fontSize:"12px"}}/>
                        </button>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="contestants-body-in-two">
                        <span>Set</span>
                        <button
                          type="button"
                          // className="btn active"
                          className={`btn ${
                            currentBull && currentBull?.is_user_bet && currentBull?.is_user_bet?.created_at && currentBull?.is_user_bet?.wager_type=="O"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            onOverUnderClick(currentBull, "O");
                          }}
                        >
                          Over
                        </button>
                        <h3>
                          {currentBull?.is_user_bet
                            ? currentBull?.is_user_bet?.wager_set_value
                            : currentBull?.set_value?.set_value || 0}
                        </h3>
                        <button
                          type="button"
                          className={`btn ${
                            currentBull && currentBull?.is_user_bet && currentBull?.is_user_bet?.created_at && currentBull?.is_user_bet?.wager_type=="U"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            onOverUnderClick(currentBull, "U");
                          }}
                        >
                          Under
                        </button>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="contestants-body-in-three">
                        <span>Actual Score</span>
                        <h4>{currentBull && currentBull?.score!=null? currentBull?.score: "- -"}</h4>
                        <div className="status-bt">
                          <span>Status / Result</span>
                          {(currentBull?.status == 4 || currentBull?.status == 3) ? <h3>Locked</h3> : currentBull?.other_status == 2 ? <h3 className="green-col">Scratched</h3> : currentBull?.other_status == 1 ? <h3 className="green-col">Re-ride</h3> :(currentBull?.is_user_bet && currentBull?.is_user_bet?.score_type == 1 ? <h3 className="green-col">Push</h3> :  currentBull?.is_user_bet && currentBull?.is_user_bet?.score_type==2 ? <h3 className="green-col">{`Win+${currentBull?.is_user_bet?.point}`}</h3> : currentBull?.is_user_bet && currentBull?.is_user_bet?.score_type==0 ? <h3 className="red-col">{`Loss-${currentBull?.is_user_bet?.point}`}</h3> :(currentBull?.status == 2) ? <h3>Locked</h3> : <h4>- -</h4>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {performanceInfo?.sponsor && 
          <Sponsor
            details={performanceInfo?.sponsor}
          />
        }
      </div>

      {/* <div className={"contestants "+(numberPadShow && "pb-221")} style={{padding: "15px"}}>
        {numberPadShow && (
          <NumberPad
            numberPadShow={numberPadShow}
            handleClose={onclose}
            getValue={getVal}
            sendInputValue={sendInputValue}
            sendItemId={sendItemId}
            sendItemIndex={sendItemIndex}
            getPoint={getPoint}
            onchngFunc={onchngFunc}
          />
        )}
      </div> */}

      <div className={"contestants "+(numberPadShow && "pb-221")} style={{padding: "15px"}}>
        {numberPadShow && (
          <NumberPad
            show={numberPadShow}
            onClose={onNumpadClose}
            existingBetPoints={typeSelected=="player"?currentPlayer?.is_user_bet?.point:typeSelected=="bull"?currentBull?.is_user_bet?.point:0}
            setFinalPoints={setFinalPoints}
            totalAvailablePoints={availablePoints}
          />
        )}
      </div>
      <div className="footer-btm">
        <div className="player-normal-text">
          <p>
            Pick O/U of rider's score
            <p>or</p>
            O/U of bull's buck off time
          </p>
        </div>
      </div>

      {
        showStatusModal && statusModalMesssage &&
        <StatusModal
          shouldShow={showStatusModal}
          onClose={onStatusModalClose}
          message={statusModalMesssage}
          type={1}
        />
      }  
    </React.Fragment>
  );
}
