import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { EventGameDetails } from 'src/_common/interfaces/models/event';
import { StateExtended } from '../../interfaces/StateExtended'

export function useAppEvenListSelector() {
    const eventList = useSelector((state: StateExtended) => state.event.getEventList)
    return eventList;
}

export function useGameIdSelector() {
    const gameId = useSelector((state: StateExtended) => state.event.gameId)
    return gameId;
}

export function useTotalContestentDataSelector() {
    const total_data = useSelector((state: StateExtended) => state.event.total_data)
    return total_data;
}

export function useAppEventGameListSelector() {
    const eventGameList = useSelector((state: StateExtended) => state.event.getEventGameList)
    return eventGameList;
}

export function useAppEventGameDetailSelector() {
    const eventGameDetails = useSelector((state: StateExtended) => state.event.getEventGameDetails)
    return eventGameDetails;
}

export function useAppIrStatusMessageSelector() {
    const statusMessage = useSelector((state: StateExtended) => state.event.getIrStatusMessage)
    return statusMessage;
}

export function useAppEventStatusMessageSelector() {
    const statusMessage = useSelector((state: StateExtended) => state.event.getEventStatusMessage)
    return statusMessage;
}


export function useAppEventLeaderBoardSelector() {
    const leaderBoard = useSelector((state: StateExtended) => state.event.getLeaderBoard)
    return leaderBoard;
}

export function useAppTvImageSelector() {
    const tvImage = useSelector((state: StateExtended) => state.event.getTvImageDetails)
    return tvImage;
}

export function useAppGameWiningTruckDetailsSelector() {
    const winingTruck = useSelector((state: StateExtended) => state.event.getWiningTruckDetails)
    return winingTruck;
}

export function useAppWiningTruckModalOpen() {
    const winingModal = useSelector((state: StateExtended) => state.event.winingTruckModalShow)
    return winingModal;
}
export const useAppNextEventRouteSelector = () => {
    const activeRoute = useSelector((state: StateExtended) => state.event.fromRoute)
    return activeRoute;
}

export function usePlayersListSelector() {
    const getPlayersList = useSelector((state: StateExtended) => state.event.getPlayersList)
    return getPlayersList;
}

export function usePlayersDetailsSelector() {
    const getPlayersDetails = useSelector((state: StateExtended) => state.event.getPlayersDetails)
    return getPlayersDetails;
}
export const useAppBetIdsSelector = () => {
    const bet_id = useSelector((state: StateExtended) => state.event.bet_id)
    return bet_id;
}

export function useAppPlayersListSelector() {
    const playersList = useSelector((state: StateExtended) => state.event.playersList)
    return playersList;
}

export function useAppPerformanceInfoSelector() {
    const performanceInfo = useSelector((state: StateExtended) => state.event.performanceInfo)
    return performanceInfo;
}

export function useAppPerformanceListSelector() {
    const performanceList = useSelector((state: StateExtended) => state.event.performanceList)
    return performanceList;
}

export function useAppEventDetailsSelector() {
    const eventDetails = useSelector((state: StateExtended) => state.event.eventDetails)
    return eventDetails;
}

export function useAppAvailablePointsSelector() {
    const availablePoints = useSelector((state: StateExtended) => state.event.availablePoints)
    return availablePoints;
}

export function useAppRodeoEventSettingsSelector() {
    const rodeoEventSettings = useSelector((state: StateExtended) => state.event.rodeoEventSettings)
    return rodeoEventSettings;
}

export function useAppEarnedPointsSelector() {
    const earnedPoints = useSelector((state: StateExtended) => state.event.earnedPoints)
    return earnedPoints;
}

export function useAppGeoLocationListSelector() {
    const geoLocations = useSelector((state: StateExtended) => state.event.geoLocations)
    return geoLocations;
}

export function useAppCurrentLocationSelector() {
    const currentLocation = useSelector((state: StateExtended) => state.event.currentLocation)
    return currentLocation;
}

export function useAppLastBettedPlayerListSelector() {
    const lastBettedPlayerList = useSelector((state: StateExtended) => state.event.lastBettedPlayerList)
    return lastBettedPlayerList;
}
export const useAppGameDetailsSelector = () => {
    const gameDetails = useSelector((state: StateExtended) => state.event.gameDetails)
    return gameDetails;
}
export const useAppPerformanceEventsListSelector = () => {
    const performanceEventsList = useSelector((state: StateExtended) => state.event.performanceEventsList)
    return performanceEventsList;
}