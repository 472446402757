import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ConfettiExplosion from 'react-confetti-explosion';

interface WiningModalProps {
    shouldShow: boolean;
    onClose: ()=>void;
    data: any;
}

export default function WiningModal({ shouldShow, onClose, data }: WiningModalProps) {

    useEffect(()=>{
        // console.log(data)
    },[])

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                // onHide={() => onClose()}
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='custom-modal'
            >
                {/* <Modal.Header>
                    <h2>Winner</h2>
                    <button type="button" className="btn-close"
                        // onClick={closeModal}
                    />
                </Modal.Header> */}
                <Modal.Body bsPrefix={'main-container'}>
                    {data?.score_type==2 &&
                        <ConfettiExplosion
                            force={0.6}
                            duration={2500}
                            particleCount={200}
                            width={1000}
                        />
                    }
                    <div className="modal-body row">
                        <div className='col-3'>
                            <img src={"/images/score_" + (data?.score_type==2 ? "win" : data?.score_type==0 ? "lose" : "push") + ".png"} />
                        </div>
                        <div className='col-9' style={{marginTop:"10px"}}>
                            <h4 className="rider-name-content">You won {data?.score_type!=1? data?.score : data?.point} points</h4>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-wrap mb-3">
                        <button type="button" className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}