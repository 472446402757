import React from "react";

const LazyLogin = React.lazy(() => import(/* webpackChunkName: "Login" */ "./loginWithEmail"));

const LoginWithEmail = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyLogin {...props} />
  </React.Suspense>
);

export default LoginWithEmail;
