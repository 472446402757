import React from 'react'
import LandingPage from 'src/components/landing/landingPage'

export default function LandingPageContainer() {
  return (
    <React.Fragment>
        <LandingPage />
    </React.Fragment>

  )
}
