import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useAppPerformanceInfoSelector } from 'src/_common/hooks/selectors/eventSelector';
import { URLS } from "src/_config";
import DaysheetModal from '../events/modal/daysheetModal';

interface HeaderProps {
  type?: string;
  page?: string;
}

function Header({ type, page }: HeaderProps) {

  const [showDaysheetModal, setShowDaysheetModal] = useState<boolean>(false)
  const performanceInfo = useAppPerformanceInfoSelector()

  const openDaysheetModal = () => {
    setShowDaysheetModal(true)
  }

  const closeDaysheetModal = () => {
    setShowDaysheetModal(false)
  }

  return (
    <React.Fragment>
      {type && type == 'full'?
       <div className="event-logo">
         <Link to={URLS.RODEOS} className="back-history">
          <img src="/images/logo2.png" alt="#" />
        </Link>
        {
          page && page=="performance" && performanceInfo?.document_link &&
          <div className='event-btn' style={{display:"inline-flex"}}>
            <button className='btn' onClick={openDaysheetModal}>
              Daysheet
            </button>
          </div>
        }
     </div>:<div className="lite-logo-in">
                <Link to={URLS.RODEOS} className="back-history">
                <img src="/images/lite-logo.png" alt="#" />
                </Link>
            </div>
    }
    {
      showDaysheetModal && performanceInfo?.document_link &&
      <DaysheetModal
        shouldShow={showDaysheetModal}
        onClose={closeDaysheetModal}
        performanceInfo={performanceInfo}
      />
    }  
    </React.Fragment>
  )
}

export default Header
