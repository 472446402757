import { useDispatch } from 'react-redux'
import { ACTIONS } from 'src/_config'

export function useAppEventAction() {

  const dispatch = useDispatch()

  const pushGameStatusChangeSocketDataIntoGameList = (socketData: any, userId: number) => {
    var params = {
      socketData: socketData,
      userId: userId,
    }
    dispatch({
      type: ACTIONS.EVENT.GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST,
      payload: params
    })
  }

  const pushGameStatusChangeSocketDataIntoGameDetails = (socketData: any, userId: number) => {
    var params = {
      socketData: socketData,
      userId: userId,
    }
    dispatch({
      type: ACTIONS.EVENT.GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS,
      payload: params
    })
  }

  const pushGameStatusMessageSocketDataIntoStatusList = (socketData: any, userId: number) => {
    var params = {
      socketData: socketData,
      userId: userId,
    }
    dispatch({
      type: ACTIONS.EVENT.GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST,
      payload: params
    })
  }

  const pushRiderWithVoteDetailsSocketDataIntoRiderList = (socketData: any) => {
    var params = {
      socketData: socketData,
    }
    dispatch({
      type: ACTIONS.EVENT.GET_SOCKET_RIDER_WITH_VOTE_DETAILS,
      payload: params
    })
  }

  const pushWiningTruckDetailsSocketDataIntoWiningDetails = (socketData: any, userId: number) => {
    var params = {
      socketData: socketData,
      userId: userId,
    }
    dispatch({
      type: ACTIONS.EVENT.GET_WINING_SOCKET_DATA_AND_PUSH_TO_WINING_DETAILS,
      payload: params
    })
  }

  const showWiningTruckDetailsModal = (isOpen: boolean) => {
    dispatch({
      type: ACTIONS.EVENT.OPEN_WINING_MODAL,
      payload: isOpen,
    })
  }

  const emptyGameDetails = () => {
    dispatch({
      type: ACTIONS.EVENT.GET_EVENT_GAME_DETAILS,
      payload: null,
    })
  }

  const fromRouteHandler = (formRoute: any) => {
    dispatch({
      type: ACTIONS.EVENT.NEXT_EVENT_FROM_ROUTE,
      payload: formRoute,
    })
  }

  const updateEventGlowingStatus = (socketData: any) => {
    var params = { socketData }
    dispatch({
      type: ACTIONS.EVENT.GET_EVENT_LIST_GLOWING_SOCKET,
      payload: params
    })
  }

  const playerListStatusUpdate = (socketData: any) => {
    var params = { socketData }
    dispatch({
      type: ACTIONS.EVENT.GET_PLAYER_LIST_STATUS_UPDATE,
      payload: params
    })
  }

  const playerDetailsStatusUpdate = (socketData: any) => {
    var params = { socketData }
    dispatch({
      type: ACTIONS.EVENT.GET_PLAYER_DETAILS_STATUS_UPDATE,
      payload: params
    })
  }

  const playerListAllDataAndStatusChangesUpdate = (socketData: any) => {
    var params = { socketData }
    dispatch({
      type: ACTIONS.EVENT.GET_PLAYER_LIST_ALL_DATA_AND_STATUS_CHANGE_UPDATE,
      payload: params
    })
  }

  const playerListResetContestents = (socketData: any) => {
    var params = { socketData }
    dispatch({
      type: ACTIONS.EVENT.GET_PLAYER_RESET_CONTESTANTS,
      payload: params
    })
  }

  const playerListEmitScore = (socketData: any, score?:any) => {
    var params = { socketData }
    dispatch({
      type: ACTIONS.EVENT.GET_PLAYER_EMIT_SCORE,
      payload: params
    })
  }

  const setTotalDataAvailablePoints = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SET_TOTAL_DATA_AVAILABLE_POINTS,
      payload: data
    })
  }

  const resetBetIds = () => {
    dispatch({
      type: ACTIONS.EVENT.RESET_BET_ID,
    })
  }

  const getStatusMessage = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
      payload: data,
    })
  }

  const getIrStatusMessage = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.GET_IR_STATUS_MESSAGE,
      payload: data,
    })
  }

  const setPlayersList = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SET_PLAYERS_LIST,
      payload: data,
    })
  }

  const setPerformanceInfo = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SET_PERFORMANCE_INFO,
      payload: data,
    })
  }

  const setPerformanceList = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SET_PERFORMANCE_LIST,
      payload: data,
    })
  }

  const setEventDetails = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SET_EVENT_DETAILS,
      payload: data,
    })
  }

  const setAvailablePoints = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SET_AVAILABLE_POINTS,
      payload: data,
    })
  }

  const setEarnedPoints = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SET_EARNED_POINTS,
      payload: data,
    })
  }

  const setRodeoEventSettings = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.RODEO_EVENT_SETTINGS,
      payload: data,
    })
  }

  const pushEventStatusToTotalData = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SET_EVENT_STATUS,
      payload: data,
    })
  }

  const setLeaderboardData = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.LEADERBOARD,
      payload: data,
    })
  }

  const setEventList = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SET_EVENT_LIST,
      payload: data,
    })
  }

  const currentLoc = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.CURRENT_LOCATION,
      payload: data,
    })
  }

  const clearCurrentLoc = () => {
    dispatch({
      type: ACTIONS.EVENT.CURRENT_LOCATION,
      payload: null,
    })
  }

  const setLastBettedPlayer = (data: any) => {
    dispatch({
      type: ACTIONS.EVENT.SET_LAST_BETTED_PLAYER,
      payload: data,
    })
  }
  

  return {
    pushGameStatusChangeSocketDataIntoGameList,
    pushGameStatusChangeSocketDataIntoGameDetails,
    pushGameStatusMessageSocketDataIntoStatusList,
    pushRiderWithVoteDetailsSocketDataIntoRiderList,
    pushWiningTruckDetailsSocketDataIntoWiningDetails,
    fromRouteHandler,
    emptyGameDetails,
    updateEventGlowingStatus,
    showWiningTruckDetailsModal,
    playerListStatusUpdate,
    playerListAllDataAndStatusChangesUpdate,
    playerDetailsStatusUpdate,
    playerListResetContestents,
    playerListEmitScore,
    resetBetIds,
    getStatusMessage,
    getIrStatusMessage,
    setPlayersList,
    setPerformanceInfo,
    setPerformanceList,
    setEventDetails,
    setAvailablePoints,
    setEarnedPoints,
    setRodeoEventSettings,
    pushEventStatusToTotalData,
    setLeaderboardData,
    setTotalDataAvailablePoints,
    setEventList,
    currentLoc,
    clearCurrentLoc,
    setLastBettedPlayer,
  }
}