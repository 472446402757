import React, { useEffect } from "react";
import { useAppGameDetailsSelector } from "src/_common/hooks/selectors/eventSelector";

interface SponsorProps {
  details?: any;
}

export default function Sponsor({ details }: SponsorProps) {
  const gameDetails = useAppGameDetailsSelector();

  useEffect(() => {
    console.log({ details });
  }, []);

  return (
    <div className="sponsor-com-img-wrap">
      <span>Sponsored by:</span>
      <a>
        <img src={details?.original || "/images/sponsorLogo.png"} alt="Sponsor Image" />
      </a>
    </div>
  );
}
